import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// ACTIONS
import {
  updateOrganizationGroups,
  initEditingOrganizationGroup,
} from 'providers/organizationGroups/organizationGroups.actions';

// COMPONENTS
import { Spin, Row, Col, Button } from 'te-antd';

// ICONS
import { MdAdd } from 'react-icons/md';

// PAGES
import OrganizationGroupsListing from './Components/OrganizationGroupsListing';
import OrganizationGroupDetails from './Components/OrganizationGroupDetails';

// eslint-disable-next-line react/prefer-stateless-function
class OrganizationGroups extends React.Component {
  onCreateNewGroup = () => {
    const { initEditingOrganizationGroup } = this.props;
    initEditingOrganizationGroup(null);
  };

  render() {
    const { loading } = this.props;
    if (loading) return <Spin />;
    return (
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h1>Organization groups</h1>
          <Button
            onClick={this.onCreateNewGroup}
            type="primary"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <MdAdd style={{ fontSize: '18px' }} />
            <span>&nbsp;&nbsp;New</span>
          </Button>
        </div>
        <Row gutter={32}>
          <Col md={12}>
            <OrganizationGroupsListing />
          </Col>
          <Col md={12}>
            <OrganizationGroupDetails />
          </Col>
        </Row>
      </div>
    );
    // return (
    //   <Switch>
    //     <Route
    //       exact
    //       path={`${match.path}/new`}
    //       component={OrganizationGroupsNew}
    //     />
    //     <Route
    //       exact
    //       path={`${match.path}/:organizationGroupId`}
    //       component={OrganizationGroupsDetails}
    //     />
    //     <Route component={OrganizationGroupsListing} />
    //   </Switch>
    // );
  }
}
const mapStateToProps = state => ({
  loading: state.organizationGroups.loading,
});

const mapActionsToProps = {
  updateOrganizationGroups,
  initEditingOrganizationGroup,
};

OrganizationGroups.propTypes = {
  initEditingOrganizationGroup: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
OrganizationGroups.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(OrganizationGroups);
