import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'te-antd';

// CSS
import './ManageProductItem.scss';

// ACTIONS
import {
  addOrganizationProduct,
  removeOrganizationProduct,
} from 'providers/newOrganization/newOrganization.actions';

// CONSTANTS
const mapActionsToProps = {
  addOrganizationProduct,
  removeOrganizationProduct,
};

const mapStateToProps = (state, ownProps) => ({
  product: state.teApps.apps.map[ownProps.productKey],
  isActivated:
    state.newOrganization.PRODUCT_SETTINGS.productKeys.indexOf(
      ownProps.productKey
    ) > -1,
});

const ManageProductItem = props => {
  const {
    product,
    isActivated,
    addOrganizationProduct,
    removeOrganizationProduct,
  } = props;

  return (
    <div className="manage-product-item--wrapper">
      {!product || !product.urls ? null : (
        <img
          src={product.urls.imageUrl}
          className="manage-product-item--logo"
          alt={`${product.title} logo`}
          width="40"
          height="40"
        />
      )}
      <div className="manage-product-item_product-properties--wrapper">
        <div className="manage-product-item_product-properties--label">
          {product.title}
        </div>
        <div className="manage-product-item_product-properties--status">
          {isActivated ? 'Activated for customer' : 'Not activated'}
        </div>
      </div>
      {!isActivated ? (
        <Button
          className="manage-product-item--button"
          type="primary"
          onClick={() =>
            addOrganizationProduct({
              appId: product._id,
              appName: product.name,
            })
          }
        >
          Activate
        </Button>
      ) : (
        <Button
          className="manage-product-item--button"
          type="danger"
          onClick={() =>
            removeOrganizationProduct({
              appId: product._id,
              appName: product.name,
            })
          }
        >
          Deactivate
        </Button>
      )}
    </div>
  );
};

ManageProductItem.propTypes = {
  addOrganizationProduct: PropTypes.func.isRequired,
  removeOrganizationProduct: PropTypes.func.isRequired,
  product: PropTypes.object,
  isActivated: PropTypes.bool,
};
ManageProductItem.defaultProps = {
  product: {},
  isActivated: false,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ManageProductItem);
