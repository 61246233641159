import { asyncAction } from 'providers/utils/actionHelpers';
import { api } from 'providers/api';
import { alertOnError } from 'providers/api/api.model';
import * as types from './organizationGroups.actionTypes';

export const updateOrganizationGroups = updatedGroups => ({
  type: types.UPDATE_ORGANIZATION_GROUPS,
  updatedGroups,
});

export const fetchOrganizationGroups = asyncAction(
  {
    type: types.FETCH_ORGANIZATION_GROUPS_REQUEST,
    success: types.FETCH_ORGANIZATION_GROUPS_SUCCESS,
    failure: types.FETCH_ORGANIZATION_GROUPS_FAILURE,
    callback: alertOnError,
  },
  async payload => {
    const { organizationId } = payload;
    const json = await api.get(
      `/organizations/${organizationId}/organization-group`
    );
    return json;
  }
);

export const updateOrganizationGroup = (key, val) => ({
  type: types.UPDATE_ORGANIZATION_GROUP,
  key,
  val,
});

export const initEditingOrganizationGroup = groupId => ({
  type: types.INIT_EDITING_ORGANIZATION_GROUP,
  groupId,
});

export const cancelEditingOrganizationGroup = () => ({
  type: types.CANCEL_EDITING_ORGANIZATION_GROUP,
});

export const saveOrganizationGroup = asyncAction(
  {
    type: types.SAVE_ORGANIZATION_GROUP_REQUEST,
    success: types.SAVE_ORGANIZATION_GROUP_SUCCESS,
    failure: types.SAVE_ORGANIZATION_GROUP_FAILURE,
    callback: alertOnError,
  },
  async (payload, meta, dispatch, getState) => {
    const { organizationId } = getState().newOrganization;
    const organizationGroup =
      payload.organizationGroup || getState().organizationGroups.edit;
    const { _id, name, desciption, parentIds, tags } = organizationGroup;
    let json;
    if (_id) {
      json = await api.put(
        `/organizations/${organizationId}/organization-group/${_id}`,
        {
          name,
          desciption,
          tags,
          parentIds: parentIds || [],
        }
      );
    } else {
      json = await api.post(
        `/organizations/${organizationId}/organization-group`,
        {
          name,
          desciption,
          tags,
          parentIds: parentIds || [],
        }
      );
    }
    const { callback } = payload;
    if (typeof callback === 'function') {
      callback();
    }
    return json;
  }
);
