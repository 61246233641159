/* @flow */
/* eslint react/prop-types: "off", jsx-a11y/no-static-element-interactions: "off" */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { WithToken } from 'te-auth-app-lib';

// PROVIDERS
import { logout } from 'providers/authentication/authentication.actions';
import { api } from 'providers/api';
import {
  selectMenuItem,
  selectSubMenuItem,
  hideSecondarySidebar,
} from 'providers/globalUI/globalUI.actions';

// COMPONENTS
import { Popover } from 'te-antd';

// IMAGES
import TEExamLogo from 'resources/images/te-exam-letters.png';

// ICONS
import {
  MdArrowBack,
  MdHome,
  MdMoreHoriz,
  MdApps,
  MdGroup,
  MdDomain,
  // MdInsertDriveFile,
  // MdExposurePlus1,
  // MdFormatListBulleted,
} from 'react-icons/md';

// CSS
import './MainMenu.scss';

// CONSTANTS
const menuItems = [
  {
    label: 'Home',
    path: '/',
    icon: <MdHome />,
    subItems: [],
  },
  {
    label: 'Organizations',
    path: '/organizations',
    icon: <MdDomain />,
    subItems: [],
  },
  // {
  //   label: 'Template forms',
  //   path: '/template-forms',
  //   icon: <MdInsertDriveFile />,
  //   subItems: [
  //     {
  //       label: 'Template forms',
  //       path: '/template-forms',
  //       icon: <MdFormatListBulleted />,
  //     },
  //     {
  //       label: 'New',
  //       path: '/template-forms/new',
  //       icon: <MdExposurePlus1 />,
  //     },
  //   ],
  // },
  {
    label: 'Applications',
    path: '/applications',
    icon: <MdApps />,
    subItems: [],
  },
  {
    label: 'TimeEdit Admins',
    path: '/users',
    icon: <MdGroup />,
    subItems: [],
  },
];

const mapStateToProps = state => ({
  menuItems,
  selectedMenuItemIndex: state.globalUI.selectedMenuItemIndex,
  selectedSubMenuItemIndex: state.globalUI.selectedSubMenuItemIndex,
  shouldShowSecondSidebar: state.globalUI.shouldShowSecondSidebar,
});

const mapActionsToProp = {
  logout,
  selectMenuItem,
  selectSubMenuItem,
  hideSecondarySidebar,
};

class MainMenu extends Component {
  state = {
    showContextMenu: false,
  };

  /** ***************************************************************************
   * COMPONENT LIFECYCLE
   *************************************************************************** */

  componentDidMount() {
    // Find out which tab should be active
    const { location, menuItems, selectMenuItem } = this.props;
    const { pathname } = location;
    const pathsArray = pathname.split('/');
    const activeItemIndex = menuItems.findIndex(
      item => `/${pathsArray[1]}` === item.path || pathname === item.path
    );
    if (activeItemIndex > -1)
      selectMenuItem({
        selectedMenuItemIndex: activeItemIndex,
        shouldShowSecondSidebar: menuItems[activeItemIndex].subItems.length > 0,
      });
  }

  /** ***************************************************************************
   * EVENT HANDLERS
   *************************************************************************** */

  handleMenuItemClick = (item, index) => {
    // If the object has subitems
    const {
      menuItems,
      selectSubMenuItem,
      selectMenuItem,
      history,
    } = this.props;
    if (menuItems[index].subItems.length > 0) {
      selectSubMenuItem({
        selectedMenuItemIndex: index,
        selectedSubMenuItemIndex: 0,
      });
      history.push(menuItems[index].subItems[0].path);
    } else {
      // If not
      selectMenuItem({
        selectedMenuItemIndex: index,
        shouldShowSecondSidebar: menuItems[index].subItems.length > 0,
      });
      history.push(menuItems[index].path);
    }
  };

  handleSubMenuItemClick = (subMenuItem, subIndex) => {
    const {
      selectedMenuItemIndex,
      selectSubMenuItem,
      history,
      menuItems,
    } = this.props;
    selectSubMenuItem({
      selectedMenuItemIndex,
      selectedSubMenuItemIndex: subIndex,
    });
    history.push(menuItems[selectedMenuItemIndex].subItems[subIndex].path);
  };

  handleLogout = async () => {
    const { logout, history } = this.props;
    localStorage.removeItem('api_token');
    api.setToken(null);
    logout();
    await Promise.resolve();
    history.push('/');
  };

  /** ***************************************************************************
   * RENDERING
   *************************************************************************** */

  render() {
    const {
      shouldShowSecondSidebar,
      selectedMenuItemIndex,
      selectedSubMenuItemIndex,
      hideSecondarySidebar,
      menuItems,
    } = this.props;
    const { showContextMenu } = this.state;

    // Build the menu dynamically
    const items = menuItems.map((item, index) => (
      <li
        key={`item-${index}`}
        className={classnames({ active: index === selectedMenuItemIndex })}
        onClick={() => this.handleMenuItemClick(item, index)}
      >
        <Popover placement="right" content={item.label}>
          {item.icon}
        </Popover>
      </li>
    ));

    // Build the sub menu if needed
    const subItems = menuItems[selectedMenuItemIndex].subItems.map(
      (item, index) => (
        <li
          key={`sub-item-${index}`}
          className={classnames({ active: index === selectedSubMenuItemIndex })}
          onClick={() => this.handleSubMenuItemClick(item, index)}
        >
          <div className="container-sub-menu-icon">{item.icon}</div>
          <span>{item.label}</span>
        </li>
      )
    );

    const popoverContent = (
      <WithToken.Consumer>
        {({ authClient }) => (
          <a
            onClick={async () => {
              await authClient.logout();
              this.handleLogout();
            }}
          >
            Log out
          </a>
        )}
      </WithToken.Consumer>
    );

    return (
      <nav
        className={classnames('menu-main', {
          expanded: shouldShowSecondSidebar,
        })}
      >
        <div className="container-primary-items">
          <div className="container-app-logo">
            <img src={TEExamLogo} width="32" alt="TimeEdit Exam logo" />
          </div>
          <ul>{items}</ul>
          <Popover
            content={popoverContent}
            trigger="click"
            visible={showContextMenu}
            onVisibleChange={() =>
              this.setState({ showContextMenu: !showContextMenu })
            }
            placement="right"
          >
            <div
              className="container-account-settings"
              role="button"
              tabIndex={0}
            >
              <MdMoreHoriz />
            </div>
          </Popover>
        </div>
        {subItems.length > 0 && shouldShowSecondSidebar && (
          <div className="container-secondary-items">
            <div className="container-secondary-sidebar-header">
              <h3>{menuItems[selectedMenuItemIndex].label}</h3>
              <div
                className="container-minimize-button"
                onClick={hideSecondarySidebar}
              >
                <MdArrowBack />
              </div>
            </div>
            <ul>{subItems}</ul>
          </div>
        )}
      </nav>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapActionsToProp
  )(MainMenu)
);
