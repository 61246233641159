export const VALIDATION_RULES_NAME = new RegExp(
  '^[a-zA-Z0-9]+(?:(-|_)+[a-zA-Z0-9]+)*$'
);

export const LANGUAGES = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'nb',
    label: 'Norwegian',
  },
  {
    value: 'sv',
    label: 'Swedish',
  },
];
