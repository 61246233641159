import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash';
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types';
import { Switch } from 'te-antd'
import { PRODUCT_SETTINGS } from 'constants/organization.constants';
import { TE_REPORTING } from 'constants/products.constants';
import {
  updateDataSourceTypes
} from '../../../../../providers/newOrganization/newOrganization.actions';


const ConfigurationItem = ({
  field,
  title,
  placeholder,
  value,
  appId,
  organizationId,
  updateDataSourceTypesAction
}) => {
  const handleToggle = (value) => {
    updateDataSourceTypesAction({
      appId,
      organizationId,
      dataSourceTypes: { [field]: value }
    })
  }

  return (
    <div className="configuration-item">
      <span className="item-control">
        <Switch defaultChecked={value} onChange={handleToggle} />
      </span>
      <span className="item-description">
        <span className="title">{title}</span>
        <span className="placeholder">{placeholder}</span>
      </span>
    </div>
);
}

const mapStateToProps = ({ newOrganization }, { field }) => ({
  value: _.get(newOrganization, `[${PRODUCT_SETTINGS}].productSettings[${TE_REPORTING}].enabledSourceTypes[${field}]`),
  appId: _.get(newOrganization, `[${PRODUCT_SETTINGS}].productSettings[${TE_REPORTING}].appId`),
  organizationId: _.get(newOrganization, `[${PRODUCT_SETTINGS}].productSettings[${TE_REPORTING}].organizationId`),
  isLoading: _.get(newOrganization, `[${PRODUCT_SETTINGS}].loading`)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateDataSourceTypesAction: updateDataSourceTypes,
}, dispatch);

ConfigurationItem.propTypes = {
  field: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  updateDataSourceTypesAction: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationItem);
