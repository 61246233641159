import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'te-antd';

// CSS
import './PageContainer.scss';

const PageContainer = ({ children, sidebar }) => (
  <Row className="container-page flex-fill">
    {sidebar && (
      <Col className="sidebar-wrapper" md={8}>
        {sidebar}
      </Col>
    )}
    <Col className="main-wrapper" md={sidebar ? 16 : 24}>
      {children}
    </Col>
  </Row>
);

PageContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.array,
  ]).isRequired,
  sidebar: PropTypes.node,
};
PageContainer.defaultProps = {
  sidebar: null,
};

export default PageContainer;
