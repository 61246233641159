import { TE_REPORTING } from '../constants/products.constants';

const enabledSourceTypeDefault = (initial) => ({
  SCHEDULE_GENERATOR: false,
  RESERVATIONS: false,
  ORDERS: false,
  EXAMS: false,
  FORMS: false,
  ACTIVITIES: false,
  ...initial
})

export default class AppSettings {
  _id;

  backgroundColor;

  logo;

  viewLinkSubmitter;

  isActive;

  appId;

  organizationId;

  appName;

  sysAdminEmail;

  viewerEntry;

  assistedScheduling;

  enabledSourceTypes;

  examInCore;

  constructor({
    _id,
    backgroundColor,
    logo,
    viewLinkSubmitter,
    isActive,
    appId,
    organizationId,
    appName,
    sysAdminEmail,
    viewerEntry,
    assistedScheduling,
    enabledSourceTypes,
    examInCore,
  }) {
    this._id = _id;
    this.backgroundColor = backgroundColor;
    this.logo = logo;
    this.viewLinkSubmitter = viewLinkSubmitter;
    this.isActive = isActive;
    this.appId = appId;
    this.organizationId = organizationId;
    this.appName = appName;
    this.sysAdminEmail = sysAdminEmail;
    this.viewerEntry = viewerEntry;
    this.assistedScheduling = assistedScheduling;
    this.enabledSourceTypes = appName === TE_REPORTING && enabledSourceTypeDefault(enabledSourceTypes);
    this.examInCore = {
      isEnabled: false,
      configureFields: {},
      ...examInCore
    };
  }
}