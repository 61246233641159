export const initialState = {
  apps: {
    list: [],
    map: {},
    edit: {},
    loading: false,
  },
  APPLICATIONS: {
    list: [],
    map: {},
  },
  rolesLoading: false,
  roles: {},
  roleCreating: false,
  roleSaving: false,
  permissions: {},
  permissionCreating: false,
  accessTokenApp: {
    isFetched: false,
    apps: []
  }
};
