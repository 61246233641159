/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

// CONSTANTS
import { LANGUAGES } from 'constants/general.constants';

// COMPONENTS
import { Row, Col, Form, Input, Select, Popover, Icon } from 'te-antd';

// ACTIONS
import {
  findUserByEmail,
  cancelFindUser,
} from 'providers/newOrganization/editingUser/editingUser.actions';

class UserBio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpened: false,
    };
  }

  componentDidMount() {
    const { form, user } = this.props;
    const { setFieldsValue } = form;
    if (user) {
      const { email, firstName, lastName, language } = user;
      setFieldsValue({
        email,
        firstName,
        lastName,
        language: language || 'en',
      });
    }
  }

  onEmailChange = debounce(() => {
    this.onPopoverClose();
    const { userType } = this.props;
    if (userType === 'share') return;
    const { form, cancelFindUser, organizationId } = this.props;
    const { validateFields } = form;
    validateFields(['email'], async (errors, values) => {
      if (!errors || !errors.email) {
        // CHECK USER BY EMAIl
        const { findUserByEmail } = this.props;
        await findUserByEmail({ organizationId, email: values.email });
        const { foundUser } = this.props;
        if (foundUser) {
          this.onPopoverOpen();
        } else {
          cancelFindUser();
        }
      } else {
        cancelFindUser();
      }
    });
  }, 300);

  onPopoverOpen = () => {
    this.setState(() => ({ popoverOpened: true }));
  };

  onPopoverClose = () => {
    this.setState(() => ({ popoverOpened: false }));
  };

  onSelectUser = () => {
    const { onSelect, foundUser } = this.props;
    if (typeof onSelect === 'function') {
      onSelect(foundUser);
    }
    // const { setFieldsValue } = form;
    // setFieldsValue({
    //   firstName: foundUser.firstName,
    //   lastName: foundUser.lastName,
    // });
    // this.onPopoverClose();
  };

  render() {
    const { popoverOpened } = this.state;
    const { form, userType, user, foundUser, findingUser } = this.props;
    const { isEditing } = user;
    const { getFieldDecorator } = form;

    const popoverContent = foundUser ? (
      <div>
        <p style={{ marginBottom: '2rem' }}>
          User with email {foundUser.email} already exists.
        </p>
        <div style={{ textAlign: 'right' }}>
          <a
            onClick={() => {
              this.onPopoverClose();
              cancelFindUser();
            }}
          >
            <u>Cancel</u>
          </a>
          &nbsp;&nbsp;&nbsp;
          <a onClick={this.onSelectUser}>
            <u>Show existing user</u>
          </a>
        </div>
      </div>
    ) : null;

    return (
      <Row gutter={16} style={{ maxWidth: '400px' }}>
        <Col md={24}>
          <Form.Item label="Email">
            <Popover
              title="User Already Exists"
              trigger="click"
              content={popoverContent}
              visible={popoverOpened}
              placement="topRight"
            >
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: 'Please input Email',
                  },
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                ],
              })(
                <Input
                  disabled={isEditing}
                  onChange={this.onEmailChange}
                  suffix={findingUser ? <Icon type="loading" /> : null}
                />
              )}
            </Popover>
          </Form.Item>
        </Col>
        <Col md={24}>
          <Form.Item label="First name">
            {getFieldDecorator('firstName', {
              rules: [
                {
                  required: true,
                  message: 'Please input First name',
                },
              ],
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col md={24}>
          <Form.Item label="Last Name">
            {getFieldDecorator('lastName', {
              rules: [
                {
                  required: true,
                  message: 'Please input Last name',
                },
              ],
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col md={24}>
          <Form.Item label="Language">
            {getFieldDecorator('language', {
              initialValue: 'en',
            })(
              <Select>
                {LANGUAGES.map(item => (
                  <Select.Option value={item.value}>{item.label}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        {userType !== 'share' ? null : (
          <React.Fragment>
            <Col md={24}>
              <Form.Item label="Password">
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input Password',
                    },
                    {
                      min: 8,
                      message: 'Password must be at least 8 characters',
                    },
                  ],
                })(<Input type="password" />)}
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item label="Confirm password">
                {getFieldDecorator('confirmPassword', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input Confirm password',
                    },
                    {
                      validator: this.handleConfirmPassword,
                    },
                  ],
                })(<Input type="password" />)}
              </Form.Item>
            </Col>
          </React.Fragment>
        )}
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  foundUser: state.editingUser.foundUser,
  findingUser: state.editingUser.findingUser,
  organizationId: state.newOrganization.organizationId,
});

const mapActionsToProps = {
  cancelFindUser,
  findUserByEmail,
};

export default Form.create({ name: 'userBioForm' })(
  connect(
    mapStateToProps,
    mapActionsToProps
  )(UserBio)
);
