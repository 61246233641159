import _ from 'lodash';

export const isRoot = item =>
  !item.parentIds ||
  !item.parentIds.length ||
  item.parentIds.indexOf('root') > -1;

export const organizationGroupsConverter = groupsInput => {
  const groupsList = [...groupsInput];
  const groupsMap = _.keyBy(groupsList, '_id');
  const parents = [];
  const mappedGroups = {};
  _.forEach(groupsList, item => {
    if (isRoot(item)) {
      parents.push(item);
    } else {
      _.forEach(item.parentIds, parentId => {
        mappedGroups[parentId] = mappedGroups[parentId] || [];
        mappedGroups[parentId].push(item._id);
      });
    }
  });

  const setTree = parent => {
    if (mappedGroups[parent._id]) {
      // eslint-disable-next-line no-param-reassign
      parent.children = _.map(mappedGroups[parent._id], itemId =>
        _.cloneDeep(setTree(groupsMap[itemId]))
      );
    } else {
      // eslint-disable-next-line no-param-reassign
      parent.children = null;
    }
    return parent;
  };
  const tree = [];
  _.forEach(parents, item => {
    tree.push(_.cloneDeep(setTree(item)));
  });
  return tree;
};
