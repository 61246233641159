import React, { useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Modal, Tree, Typography, Spin } from 'te-antd';
import { restore } from 'providers/backup/backup.actions';

const { TreeNode } = Tree;

const treeApplications = [
  {
    title: 'All Applications',
    key: 'all',
    children: [
      {
        title: 'TE Preferences',
        key: 'tePref',
      },
      {
        title: 'TE Exam',
        key: 'teExam',
      },
      {
        title: 'TE Admin',
        key: 'teAdmin',
      },
      {
        title: 'TE Integration',
        key: 'teIntegration',
      },
      {
        title: 'HootDesk',
        key: 'teHootdesk',
      },
    ],
  },
];

const BackupModal = ({ handleClose, open, backupDetail, restore, saving }) => {
  const [check, setCheck] = useState([]);
  const [validateError, setError] = useState(null);

  const renderTreeNodes = data =>
    data.map(item => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} />;
    });

  const handleCheck = checked => {
    setCheck(checked);
  };

  const handleSubmit = () => {
    setError(null);
    if (check.length === 0) {
      setError('Please select at least an App');
    } else {
      const { backupId, organizationId } = backupDetail;
      restore({
        backupId,
        organizationId,
        apps: check.filter(app => app !== 'all'),
        cb: () => {
          handleClose();
        },
      });
    }
  };

  return (
    backupDetail && (
      <Modal
        width={350}
        title="Restore Backup"
        visible={open}
        onCancel={handleClose}
        onOk={handleSubmit}
        confirmLoading={saving}
        destroyOnClose
      >
        <Spin spinning={saving} tip="Restoring...">
          <h5>Restore {get(backupDetail, 'date')}</h5>
          <Tree
            onCheck={handleCheck}
            checkable
            expandedKeys={['all']}
            checkedKeys={check}
          >
            {renderTreeNodes(treeApplications)}
          </Tree>
          {validateError && (
            <Typography.Text type="danger">{validateError}</Typography.Text>
          )}
        </Spin>
      </Modal>
    )
  );
};

const mapStateToProps = ({ backup }) => ({
  saving: backup.saving,
});

const mapActionsToProps = {
  restore,
};

BackupModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  backupDetail: PropTypes.objectOf(PropTypes.any),
  restore: PropTypes.func.isRequired,
};

BackupModal.defaultProps = {
  open: false,
  backupDetail: null,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(BackupModal);
