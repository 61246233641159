import React from 'react';

import { Tabs } from 'te-antd';
import SharedUsersList from './SharedUsersList';
import SharedUsersConfig from './SharedUsersConfig';

const { TabPane } = Tabs;

const OragnizationSharedUsers = () => {
  return (
    <Tabs defaultActiveKey="usersList">
      <TabPane tab="Shared users" key="usersList">
        <SharedUsersList />
      </TabPane>
      <TabPane tab="Configurations" key="configurations">
        <SharedUsersConfig />
      </TabPane>
    </Tabs>
  );
};

export default OragnizationSharedUsers;
