import React from 'react';
import PropTypes from 'prop-types';

// CSS
import './SectionHeader.scss';

const SectionHeader = ({ sectionTitle, buttonBar, secondRow }) => (
  <div className="section-header-wrapper">
    <div className="container-section-header">
      <div className="container-title d-flex flex-fill mr-4">
        {typeof sectionTitle === 'string' ? (
          <h1>{sectionTitle}</h1>
        ) : (
          <div className="w-100">{sectionTitle}</div>
        )}
      </div>
      <div className="container-button-bar">{buttonBar}</div>
    </div>
    {secondRow && (
      <div className="container-header-second-row">{secondRow}</div>
    )}
  </div>
);

SectionHeader.propTypes = {
  sectionTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  buttonBar: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array,
    PropTypes.string,
  ]),
  secondRow: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array,
    PropTypes.string,
  ]),
};

SectionHeader.defaultProps = {
  buttonBar: null,
  secondRow: null,
  sectionTitle: null,
};

export default SectionHeader;
