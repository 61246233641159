import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// COMPONENTS
import ManageProductItem from 'components/ManageProductItem/ManageProductItem';

// CONSTANTS
const mapStateToProps = state => ({
  products: state.teApps.apps.list || [],
});

const ManageProducts = ({ products }) => (
  <React.Fragment>
    <h1>Manage Products</h1>
    {/* TODO: Filter products by name, should use another field from schema */}
    {products
      .filter(item => item.name !== 'teAdmin')
      .map(product => (
        <ManageProductItem productKey={product.name} />
      ))}
  </React.Fragment>
);

ManageProducts.propTypes = {
  products: PropTypes.array,
};
ManageProducts.defaultProps = {
  products: [],
};

export default connect(
  mapStateToProps,
  null
)(ManageProducts);
