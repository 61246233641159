// MODELS
import _ from 'lodash';
import User from 'models/User.model';

import * as types from './users.actionTypes';
import * as orgTypes from '../newOrganization/newOrganization.actionTypes';
import { initialState } from './users.initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ADMINS_REQUEST:
      return { ...state, loading: true };

    case types.FETCH_ADMINS_SUCCESS: {
      const admins = [];
      const usersList = [];
      (action.payload.data.users || []).forEach(usr => {
        if (
          !usr.organizationId &&
          (!Array.isArray(usr.organizationIds) || !usr.organizationIds.length)
        ) {
          admins.push(new User(usr));
        } else {
          usersList.push(new User(usr));
        }
      });

      return {
        ...state,
        admins,
        users: usersList,
        loading: false,
      };
    }

    case types.FETCH_ADMINS_FAILURE:
      return { ...state, loading: false };

    case types.UPDATE_ADMIN_REQUEST:
      return {
        ...state,
        saving: true,
      };
    case types.UPDATE_ADMIN_SUCCESS: {
      const { user } = action.payload.data;
      const { admins } = state;
      const userIndex = admins.findIndex(item => item._id === user._id);
      if (userIndex > -1) {
        admins.splice(userIndex, 1, new User(user));
      } else {
        admins.push(new User(user));
      }
      return {
        ...state,
        saving: false,
        admins,
      };
    }

    case types.UPDATE_ADMIN_FAILURE:
      return {
        ...state,
        saving: false,
      };

    // UPDATE USER LIST AFTER CREATE NEW ORG USER
    case orgTypes.CREATE_USER_SUCCESS: {
      const { user } = action.payload.data;
      const usersList = [...state.users];
      usersList.unshift(new User(user));
      return {
        ...state,
        users: usersList,
      };
    }

    // UPDATE USER LIST AFTER EDIT ORG USER
    case orgTypes.UPDATE_USER_SUCCESS: {
      const { user } = action.payload.data;
      const usersList = state.users;
      const userIndex = usersList.findIndex(item => item._id === user._id);
      if (userIndex > -1) {
        usersList.splice(userIndex, 1, new User(user));
      }

      return {
        ...state,
        users: usersList,
      };
    }

    case types.RESEND_INVITATION_CODE_ADMIN_SUCCESS: {
      const { user } = action.payload;
      const usersList = state.admins;
      const userIndex = usersList.findIndex(item => item._id === user._id);
      if (userIndex > -1) {
        usersList[userIndex].invitations = [_.get(user, 'invitation')];
        usersList[userIndex] = new User(usersList[userIndex]);
      }

      return {
        ...state,
        admins: [...usersList],
      };
    }

    case types.UPDATE_ADMIN_STATUS_SUCCESS: {
      const { user } = action.payload;
      const usersList = state.admins;
      const userIndex = usersList.findIndex(item => item._id === user._id);
      if (userIndex > -1) {
        usersList[userIndex].isActive = _.get(user, 'isActive');
        usersList[userIndex] = new User(usersList[userIndex]);
      }

      return {
        ...state,
        admins: [...usersList],
      };
    }

    default:
      return { ...state };
  }
};
