import React from 'react';
import './Dots.scss';

const Dots = () => (
  <div className="loading-dots">
    <span className="dot one">.</span>
    <span className="dot two">.</span>
    <span className="dot three">.</span>
  </div>
);

export default Dots;
