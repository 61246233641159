import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Form, Row, Col, Input, Button } from 'antd';

// ACTIONS
import {
  saveGeneralSettings,
} from 'providers/newOrganization/newOrganization.actions';

// STYLES
import './WeekNames.scss';


// CONSTANTS
import {
  GENERAL_SETTINGS,
} from 'constants/organization.constants';

const formItemLayout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 20,
  },
};

const WeekRow = ({ weekIndex, form }) => {
  const { getFieldDecorator } = form;
  return (
    <Form.Item label={`Week ${weekIndex + 1}`}>
      {getFieldDecorator(`weekNames[${weekIndex}]`, {
        rules: [{ required: true, message: ' ' }],
      })(<Input />)}
    </Form.Item>
  );
};

WeekRow.propTypes = {
  weekIndex: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
};

const WeekNames = ({ form, weekNames, saveGeneralSettings }) => {
  useEffect(() => {
    form.setFieldsValue({
      weekNames,
    });
  }, [weekNames]);

  const isEdited = useMemo(() => {
    return form.isFieldsTouched(weekNames.map((weekName, weekIndex) => `weekNames[${weekIndex}]`));
  }, [form])

  const onSubmit = (e) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { weekNames } = values;
        saveGeneralSettings({ weekNames });
      }
    });
  };

  return (
    <div className="organization__week-names">
      <Row gutter={16} type="flex">
        <Col span={4}>
          <b>Calendar week</b>
        </Col>
        <Col span={20}>
          <b>Week name</b>
        </Col>
      </Row>
      <Form {...formItemLayout} onSubmit={onSubmit}>
        {weekNames.map((weekName, weekIndex) => (
          <WeekRow form={form} weekName={weekName} weekIndex={weekIndex} />
        ))}
        <br />
        <Button disabled={!isEdited} htmlType="submit" type="primary">Update</Button>
      </Form>
    </div>
  );
};
WeekNames.propTypes = {
  form: PropTypes.object.isRequired,
  weekNames: PropTypes.array.isRequired,
  saveGeneralSettings: PropTypes.func.isRequired,
};

const mapActionsToProps = {
  saveGeneralSettings
};

const mapStateToProps = state => {
  return {
    weekNames: state.newOrganization[GENERAL_SETTINGS].weekNames,
  };
};

export default connect(mapStateToProps, mapActionsToProps)(Form.create()(WeekNames));
