/* eslint react/no-did-update-set-state: "off" */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { WithToken } from 'te-auth-app-lib';
import { includes } from 'lodash';

// COMPONENTS
import AnimationLoading from 'components/AnimationLoading/AnimationLoading';

// ACTIONS
import { getUser } from 'providers/authentication/authentication.actions';
import { fetchRoles, fetchTEApps, fetchConfigAccessTokenApps } from 'providers/teApps/teApps.actions';
import { fetchAdmins } from 'providers/users/users.actions';

// CONSTANT
import { ROLE_ADMIN_ROOT } from 'constants/roles.constants';

// CSS
import './AuthenticatedRoute.css';

// CONSTANTS
const mapStateToProps = state => {
  return {
    user: state.authentication.user,
    userLoading: state.authentication.userLoading,
    userToken: localStorage.getItem('api_token'),
    rolesLoading: state.teApps.rolesLoading,
    appsLoading: state.teApps.apps.loading,
  };
};

const mapActionsToProps = {
  fetchRoles,
  getUser,
  fetchTEApps,
  fetchAdmins,
  fetchConfigAccessTokenApps,
};

const renderLogin = ({ location }) => (
  <WithToken.Consumer>
    {({ authClient }) => {
      const loginUrl = authClient.loginUrl({
        callbackUrl: window.location.href,
      });

      if (loginUrl) {
        window.location.replace(loginUrl);
        return null;
      }
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      );
    }}
  </WithToken.Consumer>
);

renderLogin.propTypes = {
  location: PropTypes.object.isRequired,
};

class AuthenticatedRoute extends React.Component {
  async componentDidMount() {
    const { fetchRoles, getUser, fetchTEApps, fetchAdmins, fetchConfigAccessTokenApps } = this.props;
    await getUser();
    fetchTEApps();
    fetchConfigAccessTokenApps();
    fetchRoles();
    fetchAdmins();
  }

  render() {
    const { userLoading, appsLoading, rolesLoading } = this.props;
    if (userLoading || appsLoading || rolesLoading) return <AnimationLoading />;
    const { children, user } = this.props;
    return (
      <WithToken.Consumer>
        {({ authClient }) => (
          <Route
            render={props => {
              if (!user || !user.id) {
                return renderLogin(props);
              }
              if (!includes(user.permissions, ROLE_ADMIN_ROOT)) {
                return authClient.redirect({
                  reason: 'permission_required',
                  source: 'teAdmin',
                });
              }

              return children;
            }}
          />
        )}
      </WithToken.Consumer>
    );
  }
}

AuthenticatedRoute.propTypes = {
  getUser: PropTypes.func.isRequired,
  fetchTEApps: PropTypes.func.isRequired,
  fetchConfigAccessTokenApps: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  userLoading: PropTypes.bool,
  appsLoading: PropTypes.bool,
  rolesLoading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.array,
  ]).isRequired,
  user: PropTypes.object,
};
AuthenticatedRoute.defaultProps = {
  userLoading: false,
  appsLoading: false,
  rolesLoading: false,
  user: {},
};

export default withRouter(
  connect(
    mapStateToProps,
    mapActionsToProps
  )(AuthenticatedRoute)
);
