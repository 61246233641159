import { api } from 'providers/api';
import { initialState } from './authentication.initialState';
import * as types from './authentication.actionTypes';

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST: {
      return {
        ...state,
        signingIn: true,
      };
    }

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.data.user,
        signingIn: false,
      };

    case types.LOGIN_FAILURE:
      return {
        ...state,
        signingIn: false,
      };

    case types.GET_USER_REQUEST:
      return {
        ...state,
        userLoading: true,
      };
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.data.user,
        userLoading: false,
      };

    case types.GET_USER_FAILURE:
      localStorage.removeItem('api_token');
      api.setToken(null);
      // window.location.replace('/login');
      return {
        ...state,
        user: null,
        userLoading: false,
      };

    case types.LOGOUT: {
      localStorage.removeItem('api_token');
      api.setToken(null);
      window.location.replace('/login');
      return {
        ...state,
        user: null,
      };
    }

    default:
      return state;
  }
};
