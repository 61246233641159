import React from 'react';

const OrganizationStatus = () => (
  <div>
    <p>- Are general settings configured?</p>
    <p>
      - Are integration settings configured? (Show warning if not configured,
      show error if not configured and a product relying on them is installed)
    </p>
    <p>- Are the installed products configured?</p>
    <p>- Do we have at least 1 user?</p>
  </div>
);

export default OrganizationStatus;
