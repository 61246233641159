import React from 'react';
import { Form, Input } from 'te-antd';

const IntegrationSetting = ({ form, formItemLayout }) => {
  const { getFieldDecorator } = form;
  return (
    <>
      <Form.Item
        label="URL"
        name="baseUrl"
        {...formItemLayout}
      >
        {getFieldDecorator('baseUrl', {
            rules: [{ required: true, message: 'Please input url!' }],
          })(<Input />)}
      </Form.Item>

      <Form.Item
        label="User name"
        name="username"
        {...formItemLayout}
      >
        {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Please input username!' }],
          })(<Input />)}
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        {...formItemLayout}
      >
        {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input password!' }],
          })(<Input.Password />)}
      </Form.Item>

      <Form.Item
        label="Certificate Key"
        name="certificateKey"
        {...formItemLayout}
      >
        {getFieldDecorator('certificateKey', {
            rules: [{ required: true, message: 'Please input certificate key!' }],
          })(<Input />)}
      </Form.Item>
    </>
  )
}

export default IntegrationSetting;