import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'te-antd';
import withIntegrationService from './ExamInCoreConfigurationDropdownHOC';

const { Option } = Select;

const ExamInCoreConfigurationDropdown = ({
  value,
  options,
  onChangeDropdown,
  config,
  type,
  dropdownData,
  isDisabled
}) => {
  const handleOnChange = (value) => {
    onChangeDropdown({ config, type, value })
  }

  return (
    <Select
      loading={dropdownData.isLoading}
      value={value}
      placeholder="Please select"
      style={{ width: 120 }}
      onChange={handleOnChange}
      disabled={isDisabled}
    >
      {[...options, ...dropdownData.options].map((option, index) => (
        <Option key={index} value={option.value}>{option.label}</Option>
      ))}
    </Select>
  )
}

ExamInCoreConfigurationDropdown.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })),
  onChangeDropdown: PropTypes.func.isRequired,
  config: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  dropdownData: PropTypes.shape({
    isLoading: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    }))
  }),
  isDisabled: PropTypes.bool
}

ExamInCoreConfigurationDropdown.defaultProps = {
  value: undefined,
  options: [],
  dropdownData: {
    isLoading: false,
    options: []
  },
  isDisabled: false,
}

export default withIntegrationService(ExamInCoreConfigurationDropdown);