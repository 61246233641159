export const FETCH_TE_APPS_REQUEST = 'FETCH_TE_APPS_REQUEST';
export const FETCH_TE_APPS_SUCCESS = 'FETCH_TE_APPS_SUCCESS';
export const FETCH_TE_APPS_FAILURE = 'FETCH_TE_APPS_FAILURE';

export const START_EDITING_APP = 'START_EDITING_APP';

export const SAVE_ROLE_REQUEST = 'SAVE_ROLE_REQUEST';
export const SAVE_ROLE_SUCCESS = 'SAVE_ROLE_SUCCESS';
export const SAVE_ROLE_FAILURE = 'SAVE_ROLE_FAILURE';

export const SAVE_APP_REQUEST = 'SAVE_APP_REQUEST';
export const SAVE_APP_SUCCESS = 'SAVE_APP_SUCCESS';
export const SAVE_APP_FAILURE = 'SAVE_APP_FAILURE';

export const CREATE_NEW_ROLE_REQUEST = 'CREATE_NEW_ROLE_REQUEST';
export const CREATE_NEW_ROLE_SUCCESS = 'CREATE_NEW_ROLE_SUCCESS';
export const CREATE_NEW_ROLE_FAILURE = 'CREATE_NEW_ROLE_FAILURE';

export const REMOVE_ROLE_REQUEST = 'REMOVE_ROLE_REQUEST';
export const REMOVE_ROLE_SUCCESS = 'REMOVE_ROLE_SUCCESS';
export const REMOVE_ROLE_FAILURE = 'REMOVE_ROLE_FAILURE';

export const FETCH_ROLES_REQUEST = 'FETCH_ROLES_REQUEST';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE';

export const CREATE_PERMISSION_REQUEST = 'CREATE_PERMISSION_REQUEST';
export const CREATE_PERMISSION_SUCCESS = 'CREATE_PERMISSION_SUCCESS';
export const CREATE_PERMISSION_FAILURE = 'CREATE_PERMISSION_FAILURE';

export const UPDATE_PERMISSION_REQUEST = 'UPDATE_PERMISSION_REQUEST';
export const UPDATE_PERMISSION_SUCCESS = 'UPDATE_PERMISSION_SUCCESS';
export const UPDATE_PERMISSION_FAILURE = 'UPDATE_PERMISSION_FAILURE';

export const DELETE_PERMISSION_REQUEST = 'DELETE_PERMISSION_REQUEST';
export const DELETE_PERMISSION_SUCCESS = 'DELETE_PERMISSION_SUCCESS';
export const DELETE_PERMISSION_FAILURE = 'DELETE_PERMISSION_FAILURE';

export const CREATE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAILURE = 'CREATE_ROLE_FAILURE';

export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';

export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';

export const FETCH_CONFIG_ACCESS_TOKEN_APP_REQUEST = 'FETCH_CONFIG_ACCESS_TOKEN_APP_REQUEST';
export const FETCH_CONFIG_ACCESS_TOKEN_APP_SUCCESS = 'FETCH_CONFIG_ACCESS_TOKEN_APP_SUCCESS';
export const FETCH_CONFIG_ACCESS_TOKEN_APP_FAILURE = 'FETCH_CONFIG_ACCESS_TOKEN_APP_FAILURE';
