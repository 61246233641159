import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux';
import { fetchIntegrationForConfigurationField } from '../../../../../providers/newOrganization/newOrganization.actions'
import { examConfigurationFieldSelector } from '../../../../../providers/newOrganization/newOrganization.selector'
import { EXAM_CONFIG_FIELD_DROPDOWN_TYPE } from './helpers'

const withIntegrationService = (WrappedComponent) => (props) => {
  const {
    config,
    type,
    source,
    dependValue,
    organizationId,
    fieldData,
    fetchIntegrationForConfigurationFieldAction
  } = props
  
  useEffect(() => {
    if (source && dependValue) {
      fetchIntegrationForConfigurationFieldAction({
        config,
        source,
        field: type,
        options: {
          organizationId,
          value: dependValue
        }
      })
    }
  }, [dependValue, source])

  const isDisabled = useMemo(() => {
    return type !== EXAM_CONFIG_FIELD_DROPDOWN_TYPE.SOURCE && !dependValue
  }, [type, dependValue])

  return <WrappedComponent {...props} dropdownData={fieldData} isDisabled={isDisabled} />
}

withIntegrationService.propTypes = {
  fetchIntegrationForConfigurationFieldAction: PropTypes.func.isRequired,
  config: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  dependValue: PropTypes.string
}

const mapStateToProps = (state, prevProps) => ({
  fieldData: examConfigurationFieldSelector(state, prevProps)
})

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchIntegrationForConfigurationFieldAction: fetchIntegrationForConfigurationField,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withIntegrationService
)