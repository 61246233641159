import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { WithToken } from 'te-auth-app-lib';
import _ from 'lodash';

const mapStateToProps = state => ({
  isLoginRequired:
    _.get(state, 'apiStatus.errors.API_REQUEST') === 'login_required',
});

const ValidateTokenEffect = ({ isLoginRequired }) => (
  <WithToken.Consumer>
    {/* eslint-disable-next-line consistent-return */}
    {({ authClient }) => {
      if (isLoginRequired) {
        const loginUrl = authClient.loginUrl({
          callbackUrl: window.location.href,
        });
        if (loginUrl) {
          window.location.replace(loginUrl);
          return null;
        }
      }
    }}
  </WithToken.Consumer>
);

ValidateTokenEffect.propTypes = {
  isLoginRequired: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  null
)(ValidateTokenEffect);
