import * as types from './teRoomObjects.actionTypes';

export default (state = [], action) => {
  switch (action.type) {
    case types.FETCH_TE_ROOM_OBJECTS_SUCCESS:
      return [...action.payload.data];

    default:
      return state;
  }
};
