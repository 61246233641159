import * as types from './teObjectTypes.actionTypes';
import { initialState } from './teObjectTypes.initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    // ---------------------------------
    // FETCH_TE_OBJECT_TYPES
    // ---------------------------------
    case types.FETCH_TE_OBJECT_TYPES_REQUEST:
      return {
        ...state,
        teObjectTypesLoading: true,
      };

    case types.FETCH_TE_OBJECT_TYPES_SUCCESS:
      return {
        ...state,
        list: [...action.payload.data],
        teObjectTypesLoading: false,
      };

    case types.FETCH_TE_OBJECT_TYPES_FAILURE:
      return {
        ...state,
        teObjectTypesLoading: false,
      };

    default:
      return state;
  }
};
