/* eslint-disable */
/* @flow */
import type { RestAPI } from 'providers/api/api.model';
import type { Dispatch } from 'redux';

type ApiAction<P, M, T> = {
  type: string,
  payload?: P | T,
  error?: Object,
  meta?: any,
};

export type GetState = () => Object;

export type ThunkAction<P, M> = (
  dispatch: Dispatch<P, M>,
  getState: GetState
) => any;

export type PromiseAction = Promise<any>;

type _ExtractReturn<B, F: (...args: any[]) => B> = B;
export type ExtractReturn<F> = _ExtractReturn<*, F>;

export type AsyncActionCreator<P, M> = (payload: P, meta?: M) => any;

export function asyncActionCreator(
  fetchAction: string,
  successAction: string,
  failedAction: string
) {
  return function asyncApi<P, M, T>(
    asyncFunc: RestAPI<P, M, T>
  ): AsyncActionCreator<P, M> {
    return function actionCreator(payload: P, meta?: M) {
      return async function thunk(
        dispatch: Dispatch<ApiAction<P, M, T>>,
        getState: GetState
      ) {
        if (fetchAction) {
          dispatch({
            type: fetchAction,
            payload,
            meta,
          });
        }
        try {
          const successPayload = await asyncFunc(payload, meta);
          if (successAction) {
            dispatch({
              type: successAction,
              payload: successPayload,
              meta: {
                actionPayload: payload,
                actionMeta: meta,
              },
            });
          }
        } catch (e) {
          if (failedAction) {
            return dispatch({
              type: failedAction,
              error: e,
              meta: {
                actionPayload: payload,
                actionMeta: meta,
              },
            });
          }
          return console.error(`Action ${fetchAction || ''} failed: ${e}`);
        }
      };
    };
  };
}

export function action(type) {
  return function actionCreator(payload = {}, meta = {}) {
    return {
      type,
      payload,
      meta,
    };
  };
}

type AsyncActionCallerType = (
  payload: Object,
  meta: Object,
  dispatch: Function,
  getState: Function
) => void;

export function asyncAction(
  { type, success, failure, defaultPayload, defaultMeta, callback } = {},
  call: AsyncActionCallerType
) {
  return function actionCreator(payload = {}, meta = {}) {
    return async function thunk(dispatch, getState) {
      const _payload = defaultPayload
        ? defaultPayload(getState, payload, meta)
        : {};
      const _meta = defaultMeta ? defaultMeta(getState, payload, meta) : {};
      payload = { ..._payload, ...payload };
      meta = { ..._meta, ...meta };
      if (type) {
        dispatch({
          type,
          payload,
          meta,
        });
      }

      try {
        const successPayload = await call(payload, meta, dispatch, getState);
        if (callback) {
          callback(null, successPayload);
        }
        if (success) {
          dispatch({
            type: success,
            payload: successPayload,
            meta: {
              actionPayload: payload,
              actionMeta: meta,
            },
          });
        }
      } catch (e) {
        if (callback) {
          callback(e);
        }
        if (failure) {
          if (process.env.NODE_ENV !== 'production') {
            console.error(`Action failure with error: ${e}`);
          }
          dispatch({
            type: failure,
            payload: {
              error: e,
            },
            meta: {
              actionPayload: payload,
              actionMeta: meta,
            },
          });
        } else {
          console.error(`Action ${type || ''} failed: ${e}`);
        }
      }
    };
  };
}
/* eslint-enable */
