import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tabs } from 'te-antd';
import { find } from 'lodash';
import { API_URL } from 'configs';
// ACTIONS
import { setBreadcrumbs } from 'providers/globalUI/globalUI.actions';

// CONSTANTS
import {
  ORGANIZATION_STATUS,
  GENERAL_SETTINGS,
  INTEGRATION_SETTINGS,
  MANAGE_PRODUCTS,
  PRODUCT_SETTINGS,
  USERS,
  SHARED_USERS,
  ORGANIZATION_GROUPS,
  NEW_ORGANIZATION_STEP_STATUS_INCOMPLETE,
  BACKUP,
  ACCESS_TOKENS,
} from 'constants/organization.constants';
import {
  ALL_PRODUCTS,
  TE_EXAM,
  TE_PREFERENCES,
  TE_HOOTDESK,
  TE_PRICING,
  TE_REPORTING,
} from 'constants/products.constants';

// CONFIG PAGES
// import TEExamConfigPage from 'components/ProductConfig/TEExam';
// import TEPreferencesConfigPage from 'components/ProductConfig/TEPreferences';
import { IntegrationContainer } from 'te-integration-lib';

// CONTENT PAGES
import OrganizationStatusPage from './Pages/OrganizationStatus';
import GeneralSettingsPage from './Pages/GeneralSettings/GeneralSettings';
import IntegrationSettingsPage from './Pages/IntegrationSettings';
import OrganizationUsersPage from './Pages/OrganizationUsers';
import OrganizationSharedUsersPage from './Pages/SharedUsers/SharedUsers';
// import ProductSettingsPage from './Pages/ProductSettings/ProductSettings';
import ManageProductsPage from './Pages/ManageProducts';
import ProductTEExam from './Pages/ProductSettings/Products/ProductTEExam';
import ProductTEHootdesk from './Pages/ProductSettings/Products/ProductTEHootdesk';
import ProductTEPreferences from './Pages/ProductSettings/Products/ProductTEPreferences';
import ProductTEPricing from './Pages/ProductSettings/Products/ProductTEPricing';
import ProductTEReporting from './Pages/ProductSettings/Products/ProductTEReporting';
import OrganizationGroupsPage from './Pages/OrganizationGroups/OrganizationGroups';
import Backup from './Pages/Backup';
import ManageTokensPage from './Pages/ManageTokens';

// CSS
import './OrganizationDetails.scss';

const mapStateToProps = (state) => ({
  currentPage: state.newOrganization.activeStep,
  status: state.newOrganization.status || {},
  organizationId: state.newOrganization.organizationId,
  products: state.newOrganization[PRODUCT_SETTINGS].productKeys || [],
  region: state.organizations.region,
});

const mapActionsToProps = {
  setBreadcrumbs,
};

class OrganizationDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: GENERAL_SETTINGS,
    };
    this.tabRules = {
      MANAGE_PRODUCTS: [INTEGRATION_SETTINGS],
      USERS: [INTEGRATION_SETTINGS],
      SHARED_USERS: [INTEGRATION_SETTINGS],
    };
  }

  componentWillMount() {
    const { region, setBreadcrumbs, match } = this.props;
    const tokenRegion = region || 'Undefined region';
    const { params } = match;
    if (params.organizationId) {
      setBreadcrumbs([
        {
          path: './',
          label: `Organizations ${tokenRegion}`,
        },
        {
          path: '/',
          label: 'Loading...',
        },
      ]);
    } else {
      setBreadcrumbs([
        {
          path: './',
          label: `Organizations ${tokenRegion}`,
        },
        {
          path: '/',
          label: 'New organization',
        },
      ]);
    }
  }

  // //////////////////////////////////////////////////////////////////
  // RENDERING
  // //////////////////////////////////////////////////////////////////
  tabValidation = (tab) => {
    const { status } = this.props;
    const dependedKeys = this.tabRules[tab];
    if (!dependedKeys) return true;
    return !find(
      dependedKeys,
      (key) => status[key] === NEW_ORGANIZATION_STEP_STATUS_INCOMPLETE
    );
  };

  renderConfigPageForProduct = (productKey) => {
    const { activeTab } = this.state;
    if (activeTab !== productKey) return null;
    switch (productKey) {
      case TE_EXAM:
        return <ProductTEExam />;
      case TE_PREFERENCES:
        return <ProductTEPreferences />;
      case TE_HOOTDESK:
        return <ProductTEHootdesk />;
      case TE_PRICING:
        return <ProductTEPricing />;
      case TE_REPORTING:
        return <ProductTEReporting />;
      default:
        return null;
    }
  };

  renderProductConfigPages = () => {
    const { products } = this.props;
    return products.map((productKey) =>
      ALL_PRODUCTS[productKey] ? (
        <Tabs.TabPane tab={ALL_PRODUCTS[productKey].title} key={productKey}>
          {this.renderConfigPageForProduct(productKey)}
        </Tabs.TabPane>
      ) : null
    );
  };

  render() {
    const { organizationId } = this.props;
    const { activeTab } = this.state;
    const apiToken = localStorage.getItem('api_token');
    return (
      <IntegrationContainer.Provider
        organizationId={organizationId}
        accessToken={apiToken}
        baseUrl={`${API_URL}v1/integration-service/`}
      >
        <div className="organization-details--wrapper flex-fill d-flex flex-column">
          <Tabs
            className="vertical-tabs-menu--wrapper flex-fill"
            defaultActiveKey={GENERAL_SETTINGS}
            tabPosition="left"
            onChange={(tab) => this.setState({ activeTab: tab })}
          >
            <Tabs.TabPane tab="Status" key={ORGANIZATION_STATUS}>
              <OrganizationStatusPage />
            </Tabs.TabPane>
            <Tabs.TabPane tab="General settings" key={GENERAL_SETTINGS}>
              <GeneralSettingsPage />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Organization groups" key={ORGANIZATION_GROUPS}>
              <OrganizationGroupsPage />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Integration settings" key={INTEGRATION_SETTINGS}>
              <IntegrationSettingsPage />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Manage products" key={MANAGE_PRODUCTS}>
              <ManageProductsPage />
            </Tabs.TabPane>
            {this.renderProductConfigPages()}
            <Tabs.TabPane tab="Users" key={USERS}>
              {activeTab === USERS ? <OrganizationUsersPage /> : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Public SSO User" key={SHARED_USERS}>
              {activeTab === SHARED_USERS ? (
                <OrganizationSharedUsersPage />
              ) : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Access tokens" key={ACCESS_TOKENS}>
              <ManageTokensPage />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Backup" key={BACKUP}>
              <Backup />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </IntegrationContainer.Provider>
    );
  }
}

OrganizationDetailsPage.propTypes = {
  products: PropTypes.array,
  setBreadcrumbs: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  status: PropTypes.object,
  organizationId: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
};
OrganizationDetailsPage.defaultProps = {
  products: [],
  status: {},
};

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(OrganizationDetailsPage)
);
