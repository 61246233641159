import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { groupBy } from 'lodash';

import { USERS } from 'constants/organization.constants';

// ACTIONS
import {
  startEditingUser,
  updateEditingUserRoles,
} from 'providers/newOrganization/editingUser/editingUser.actions';

// COMPONENTS
import { Form, Radio, Select } from 'te-antd';
import EditableTableButton from 'components/Tables/EditableTable/EditableTableButton';
import UserRoles from './UserRoles';
import UserBio from './UserBio';
import SaveRoleButton from './SaveRoleButton';

// STYLES
import './NewUserDetails.scss';

// eslint-disable-next-line react/prefer-stateless-function
class NewUserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'newUser',
    };
    this.userBioForm = null;
  }

  // EVENT HANDLERS
  onSubmit = e => {
    if (e) e.preventDefault();
    const { onSubmit, editingUser } = this.props;
    if (this.userBioForm) {
      this.userBioForm.validateFieldsAndScroll((err, values) => {
        if (!err) {
          if (typeof onSubmit === 'function') {
            onSubmit({ ...values, _id: editingUser ? editingUser._id : null });
          }
        }
      });
    } else {
      const { form } = this.props;
      const { validateFieldsAndScroll } = form;
      validateFieldsAndScroll((err, values) => {
        if (!err) {
          if (typeof onSubmit === 'function') {
            onSubmit({ selectedUser: values.selectedUser });
          }
        }
      });
    }
  };

  handleConfirmPassword = (rule, value, callback) => {
    const { form } = this.props;
    const { getFieldValue } = form;
    const password = getFieldValue('password');
    if (password && value !== password) {
      callback('Password and confirm password does not match');
    } else {
      callback();
    }
  };

  onTabChange = e => {
    this.setState(() => ({ activeTab: e.target.value }));
  };

  onSelectUser = user => {
    const { form } = this.props;
    const { setFieldsValue } = form;
    this.onTabChange({
      target: {
        value: 'existingUser',
      },
    });
    const { orgUsers } = this.props;
    const isExistedInOrg = orgUsers.find(item => item === user._id);
    if (isExistedInOrg) {
      const { startEditingUser, updateEditingUserRoles } = this.props;
      startEditingUser({ editingUser: { ...user, isEditing: true } });
      const { organizationGroups } = user;
      if (organizationGroups) {
        updateEditingUserRoles({
          userRoles: groupBy(organizationGroups, 'app'),
        });
      }
    } else {
      setTimeout(() => {
        setFieldsValue({
          selectedUser: user._id,
        });
      }, 150);
    }
  };

  // RENDERING

  renderEditingUser = () => {
    const { userType, editingUser } = this.props;
    return (
      <UserBio
        userType={userType}
        user={editingUser}
        ref={el => {
          this.userBioForm = el;
        }}
      />
    );
  };

  renderCreateNewUser = () => {
    const { userType } = this.props;
    const { activeTab } = this.state;
    return (
      <div>
        <Radio.Group
          onChange={this.onTabChange}
          value={activeTab}
          style={{ marginBottom: 8 }}
        >
          <Radio.Button value="newUser">Create New User</Radio.Button>
          <Radio.Button disabled={userType === 'share'} value="existingUser">
            Add Existing User
          </Radio.Button>
        </Radio.Group>
        <br />
        <br />

        {activeTab === 'newUser'
          ? this.renderNewUser()
          : this.renderExistingUser()}
      </div>
    );
  };

  renderNewUser = () => {
    const { userType, editingUser } = this.props;
    return (
      <div>
        <h3>Create new User</h3>
        <p>Create a brand new user and add them to this organization.</p>
        <UserBio
          userType={userType}
          user={editingUser}
          ref={el => {
            this.userBioForm = el;
          }}
          onSelect={this.onSelectUser}
        />
      </div>
    );
  };

  renderExistingUser = () => {
    const { users, form, orgUsers } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div>
        <h3>Add Existing User</h3>
        <p>Find and select an existing user to add them to this organization</p>
        <Form.Item label="Find User">
          {getFieldDecorator('selectedUser', {
            rules: [
              {
                required: true,
                message: 'Please select User',
              },
            ],
          })(
            <Select
              showSearch
              filterOption={(input, option) => {
                return (
                  (option.props.children || [])
                    .concat([option.key])
                    .join('')
                    .indexOf(input) > -1
                );
              }}
            >
              {users
                .filter(usr => orgUsers.indexOf(usr._id) === -1)
                .map(item => (
                  <Select.Option key={item._id} value={item._id}>
                    {item.firstName} {item.lastName} ({item.email})
                  </Select.Option>
                ))}
            </Select>
          )}
        </Form.Item>
      </div>
    );
  };

  render() {
    const { editingUser } = this.props;
    const { isEditing } = editingUser;
    return (
      <Form onSubmit={this.onSubmit} className="form--new-user">
        {isEditing ? this.renderEditingUser() : this.renderCreateNewUser()}
        <div>
          <h3>Roles</h3>
          <p>Set roles and permissions for the user.</p>
          <UserRoles />
          <br />
          <div style={{ textAlign: 'right' }}>
            <EditableTableButton
              onConfirm={() => this.onSubmit()}
              tableId="userRolesTable"
            >
              <SaveRoleButton
                onConfirm={this.onSubmit}
                id="addNewOrgUserBtn"
                style={{ marginLeft: '8px' }}
                type="primary"
              >
                {isEditing ? 'Save changes' : 'Create new user'}
              </SaveRoleButton>
            </EditableTableButton>
          </div>
        </div>
      </Form>
    );
  }
}
NewUserDetails.propTypes = {
  form: PropTypes.object.isRequired,
  startEditingUser: PropTypes.func.isRequired,
  updateEditingUserRoles: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  userType: PropTypes.string,
  users: PropTypes.array,
  orgUsers: PropTypes.array,
  editingUser: PropTypes.object,
};
NewUserDetails.defaultProps = {
  onSubmit: null,
  userType: 'normal',
  users: [],
  orgUsers: [],
  editingUser: {},
};

const mapActionsToProps = {
  startEditingUser,
  updateEditingUserRoles,
};

const mapStateToProps = state => ({
  users: state.users.users,
  orgUsers: (state.newOrganization[USERS].list || []).map(usr => usr._id),
  editingUser: state.editingUser.editingUser,
});
export default connect(
  mapStateToProps,
  mapActionsToProps
)(Form.create({ name: 'newOrganizationUserForm' })(NewUserDetails));
