export const UPDATE_ORGANIZATION_GROUPS = 'UPDATE_ORGANIZATION_GROUPS';

export const FETCH_ORGANIZATION_GROUPS_REQUEST =
  'FETCH_ORGANIZATION_GROUPS_REQUEST';
export const FETCH_ORGANIZATION_GROUPS_SUCCESS =
  'FETCH_ORGANIZATION_GROUPS_SUCCESS';
export const FETCH_ORGANIZATION_GROUPS_FAILURE =
  'FETCH_ORGANIZATION_GROUPS_FAILURE';

export const UPDATE_ORGANIZATION_GROUP = 'UPDATE_ORGANIZATION_GROUP';
export const SAVE_ORGANIZATION_GROUP_REQUEST =
  'SAVE_ORGANIZATION_GROUP_REQUEST';
export const SAVE_ORGANIZATION_GROUP_SUCCESS =
  'SAVE_ORGANIZATION_GROUP_SUCCESS';
export const SAVE_ORGANIZATION_GROUP_FAILURE =
  'SAVE_ORGANIZATION_GROUP_FAILURE';

export const INIT_EDITING_ORGANIZATION_GROUP =
  'INIT_EDITING_ORGANIZATION_GROUP';

export const CANCEL_EDITING_ORGANIZATION_GROUP =
  'CANCEL_EDITING_ORGANIZATION_GROUP';
