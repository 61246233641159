import moment from 'moment';

// CONSTANTS
import { PLACEHOLDER_ROLE } from 'constants/organization.constants';

const EXPIRATION_TIME = 30;
/* eslint lines-between-class-members: "off", prettier/prettier: "off" */
export default class User {
  _id;
  authStrategy;
  username;
  email;
  firstName;
  lastName;
  invitation;
  isActive;
  language;
  password;
  organizationGroups;
  permissions;
  type;
  createdAt;
  updatedAt;


  getInvitation = invitation => {
    const expiryDate = moment(invitation.iat).add(EXPIRATION_TIME, 'days');
    return {
      ...invitation,
      isExpired: (!moment().isBefore(expiryDate)) && invitation.status === 'PENDING',
      expiryDate,
    };
  }

  constructor({
    _id,
    id,
    authStrategy,
    username,
    email,
    firstName,
    lastName,
    invitations,
    isActive,
    language,
    password,
    organizationGroups,
    permissions,
    type,
    createdAt,
    updatedAt,
  }) {
    this._id = _id || id;
    this.authStrategy = authStrategy;
    this.username = username;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.invitations = invitations;
    this.invitation = this.getInvitation(Array.isArray(invitations) && invitations.length ? invitations[0] : {});
    this.isActive = isActive;
    this.language = language;
    this.password = password;
    // should remove placeholder role
    this.organizationGroups = (organizationGroups || []).filter(item => item.app !== PLACEHOLDER_ROLE.app);
    this.permissions = permissions || [];
    this.type = type;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
};
