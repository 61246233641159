import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

// ACTIONS
import { startEditingApp } from 'providers/teApps/teApps.actions';

// COMPONENTS
import PageApplicationDetailsHome from './PageApplicationDetailsHome';
import PageRoleDetails from './PageRoleDetails';
import PageCreateNewRole from './PageCreateNewRole';

// eslint-disable-next-line react/prefer-stateless-function
class PageApplicationDetails extends React.Component {
  componentWillMount() {
    const { startEditingApp, match } = this.props;
    const { params } = match;
    startEditingApp(params.applicationId);
  }

  render() {
    const { application, match } = this.props;
    if (!application || !Object.keys(application).length) return null;
    return (
      <div id="pageApplicationDetails">
        <Switch>
          <Route
            exact
            path={`${match.path}/roles/new`}
            component={PageCreateNewRole}
          />
          <Route
            exact
            path={`${match.path}/roles/:roleId`}
            component={PageRoleDetails}
          />
          <Route component={PageApplicationDetailsHome} />
        </Switch>
      </div>
    );
  }
}

const mapActionsToProps = {
  startEditingApp,
};
const mapStateToProps = state => ({
  application: { ...state.teApps.apps.edit },
});

PageApplicationDetails.propTypes = {
  startEditingApp: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  application: PropTypes.object,
};
PageApplicationDetails.defaultProps = {
  application: null,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(PageApplicationDetails);
