/* eslint lines-between-class-members: "off", prettier/prettier: "off" */
export default class AccessToken {
  _id;
  organizationId;
  appName;
  createdAt;
  updatedAt;
  token;

  constructor({
    _id,
    organizationId,
    appName,
    createdAt,
    updatedAt,
    token,
  }) {
    this._id = _id;
    this.organizationId = organizationId;
    this.appName = appName;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.token = token;
  }
};
