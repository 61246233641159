/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { Switch, Input, Form } from 'te-antd';
import { PRODUCT_SETTINGS } from 'constants/organization.constants';
import {
  updateAssistedScheduling,
  generateTokenForAssistedScheduling
} from '../../../../../providers/newOrganization/newOrganization.actions';
import CreateTokenModal from "../../../../ManageProductToken/CreateTokenModal";

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 2,
      offset: 2,
    },
    sm: {
      span: 2,
      offset: 2,
    },
  },
};

const appName = 'tePref';

const AssistedScheduling = ({
  isLoading,
  updateAssistedSchedulingAction,
  generateTokenForAssistedSchedulingAction,
  appSettings,
  form: { getFieldDecorator, validateFields }
}) => {
  const { assistedScheduling } = appSettings;

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        updateAssistedSchedulingAction({
          appId: appSettings.appId,
          assistedScheduling: values,
        })
      }
    })
  };

  const onCreateNewToken = name => {
    const { appId, organizationId } = appSettings
    generateTokenForAssistedSchedulingAction({
      appId: appSettings.appId,
      name
    });
  };

  return (
    <div className="assisted-scheduling-container">
      <Form {...formItemLayout} onSubmit={handleSubmit}>
        <Form.Item label="Enable">
          {getFieldDecorator('isEnabled', {
            valuePropName: 'checked',
            initialValue: _.get(assistedScheduling, 'isEnabled', false),
          })(<Switch />)}
        </Form.Item>
        <Form.Item label="Token">
          {getFieldDecorator('token', {
            initialValue: _.get(assistedScheduling, 'token'),
          })(<Input />)}
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <CreateTokenModal onSubmit={onCreateNewToken} />
        </Form.Item>
      </Form>
    </div>
  )
}

AssistedScheduling.propTypes = {
  appSettings: PropTypes.shape({
    assistedScheduling: PropTypes.shape({
      isEnabled: PropTypes.bool,
      token: PropTypes.string,
    })
  }),
  updateAssistedSchedulingAction: PropTypes.func.isRequired,
  generateTokenForAssistedSchedulingAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

AssistedScheduling.defaultProps = {
  appSettings: {
    assistedScheduling: {
      isEnabled: false,
      token: null
    }
  },
  isLoading: false
}

const mapStateToProps = ({ newOrganization }) => ({
  appSettings: _.get(newOrganization, `[${PRODUCT_SETTINGS}].productSettings[${appName}]`),
  isLoading: _.get(newOrganization, `[${PRODUCT_SETTINGS}].loading`)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateAssistedSchedulingAction: updateAssistedScheduling,
  generateTokenForAssistedSchedulingAction: generateTokenForAssistedScheduling,
}, dispatch);

const WrappedForm = Form.create({ name: 'assistedScheduling' })(AssistedScheduling);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedForm);