import { forEach, groupBy, remove, chain } from 'lodash';
import { asyncAction } from 'providers/utils/actionHelpers';
import { api } from 'providers/api';
import { alertOnError } from 'providers/api/api.model';

import * as types from './teApps.actionTypes';

const roleConverter = role => {
  const splittedName = role.name.split(':');
  const { children } = role;
  // eslint-disable-next-line no-param-reassign
  delete role.children;
  return {
    ...role,
    permissions: children,
    app: splittedName.shift(),
    name: splittedName.join(':'),
  };
};

const rolesConverter = roles => {
  return chain(roles)
    .filter(item => item.isActive)
    .map(item => {
      return roleConverter(item);
    })
    .value();
};

// /////////////////////////////////////////////////////////////
//
// @funcs:
// fetchTEApps
// /////////////////////////////////////////////////////////////
export const fetchTEApps = asyncAction(
  {
    type: types.FETCH_TE_APPS_REQUEST,
    success: types.FETCH_TE_APPS_SUCCESS,
    failure: types.FETCH_TE_APPS_FAILURE,
    callback: alertOnError,
  },
  async () => {
    const json = await api.get(
      `/apps?visible=${JSON.stringify(['selectApp'])}`
    );
    return json;
  }
);

export function startEditingApp(appId) {
  return {
    type: types.START_EDITING_APP,
    appId,
  };
}

export const saveRole = asyncAction(
  {
    type: types.SAVE_ROLE_REQUEST,
    success: types.SAVE_ROLE_SUCCESS,
    failure: types.SAVE_ROLE_FAILURE,
    callback: alertOnError,
  },
  async payload => {
    // eslint-disable-next-line no-unused-vars
    const { applicationId, roleId, applicationName, capabilities } = payload;
    const actions = [];
    forEach(capabilities, (capabilityActions, permission) => {
      forEach(capabilityActions, (item, action) => {
        if (item) {
          actions.push(`${applicationName}:${permission}:${action}`);
        }
      });
    });
    const json = await api.post(
      `/app-settings/${applicationId}/auth0/roles/${roleId}/actions`,
      { actions }
    );
    return json;
  }
);

export const fetchRoles = asyncAction(
  {
    type: types.FETCH_ROLES_REQUEST,
    success: types.FETCH_ROLES_SUCCESS,
    failure: types.FETCH_ROLES_FAILURE,
  },
  async () => {
    const json = await api.get('/permission-group');
    const allRoles = rolesConverter(json.data.permissionGroups);
    const roles = remove(allRoles, item => Array.isArray(item.permissions));
    return {
      roles: groupBy(roles, 'app'),
      permissions: groupBy(allRoles, 'app'),
    };
  }
);

export const addNewPermission = asyncAction(
  {
    type: types.CREATE_PERMISSION_REQUEST,
    success: types.CREATE_PERMISSION_SUCCESS,
    failure: types.CREATE_PERMISSION_FAILURE,
    callback: alertOnError,
  },
  async payload => {
    const { permission } = payload;
    const { description } = permission;
    const name = `${permission.app}:${permission.name}`;
    const json = await api.post('/permission-group', {
      name,
      description,
      title: name,
      children: null,
    });
    return { permission: roleConverter(json.data.permissionGroup) };
  }
);

export const updatePermission = asyncAction(
  {
    type: types.UPDATE_PERMISSION_REQUEST,
    success: types.UPDATE_PERMISSION_SUCCESS,
    failure: types.UPDATE_PERMISSION_FAILURE,
  },
  async payload => {
    const { permission } = payload;
    const { description } = permission;
    const name = `${permission.app}:${permission.name}`;
    const json = await api.put(`/permission-group/${permission._id}`, {
      name,
      description,
      title: name,
      children: null,
    });
    return { permission: roleConverter(json.data.permissionGroups) };
  }
);

export const deletePermission = asyncAction(
  {
    type: types.DELETE_PERMISSION_REQUEST,
    success: types.DELETE_PERMISSION_SUCCESS,
    failure: types.DELETE_PERMISSION_FAILURE,
  },
  async payload => {
    const { permission } = payload;
    await api.delete(`/permission-group/${permission._id}`);
    return { permission };
  }
);

export const createNewRole = asyncAction(
  {
    type: types.CREATE_ROLE_REQUEST,
    success: types.CREATE_ROLE_SUCCESS,
    failure: types.CREATE_ROLE_FAILURE,
    callback: alertOnError,
  },
  async payload => {
    const { role } = payload;
    const { title, description, permissions } = role;
    const name = `${role.app}:${role.name}`;
    const json = await api.post('/permission-group', {
      name,
      description,
      title,
      children: permissions,
    });

    const newRole = roleConverter(json.data.permissionGroup);
    const { callback } = payload;
    if (typeof callback === 'function') {
      callback({ role: newRole });
    }
    return { role: newRole };
  }
);

export const updateRole = asyncAction(
  {
    type: types.UPDATE_ROLE_REQUEST,
    success: types.UPDATE_ROLE_SUCCESS,
    failure: types.UPDATE_ROLE_FAILURE,
    callback: alertOnError,
  },
  async payload => {
    const { role } = payload;
    const { title, description, permissions } = role;
    const name = `${role.app}:${role.name}`;
    const json = await api.put(`/permission-group/${role._id}`, {
      name,
      description,
      title,
      children: permissions,
    });
    return { role: roleConverter(json.data.permissionGroup) };
  }
);

export const deleteRole = asyncAction(
  {
    type: types.DELETE_ROLE_REQUEST,
    success: types.DELETE_ROLE_SUCCESS,
    failure: types.DELETE_ROLE_FAILURE,
  },
  async payload => {
    const { role } = payload;
    await api.delete(`/permission-group/${role._id}`);
    return { role };
  }
);

export const fetchConfigAccessTokenApps = asyncAction(
  {
    type: types.FETCH_CONFIG_ACCESS_TOKEN_APP_REQUEST,
    success: types.FETCH_CONFIG_ACCESS_TOKEN_APP_SUCCESS,
    failure: types.FETCH_CONFIG_ACCESS_TOKEN_APP_FAILURE,
  },
  async () => {
    const json = await api.get(
      `/apps/visible?value[]=configAccessToken`
    );
    return json;
  })

