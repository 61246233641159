import moment from 'moment';

const BACKUP_STATUS = {
  pending: 'purple',
  done: 'green',
  fail: 'volcano',
  initialized: 'geekblue',
};

const BACKUP_TYPES = {
  automated: 'Automated',
  on_demand: 'On Demand',
  before_restore: 'Before Restore',
};

/* eslint lines-between-class-members: "off", prettier/prettier: "off" */
export default class Backup {
  _id;
  type;
  status;
  backupId;
  files;
  backupName;
  location;
  description;
  createdAt;
  updatedAt;

  constructor({
    _id,
    type,
    status,
    backupId,
    files,
    backupName,
    location,
    description,
    createdAt,
    updatedAt,
  }) {
    this._id = _id;
    this.key = _id;
    this.type = type;
    this.status = status;
    this.backupName = backupName;
    this.location = location;
    this.date = moment(createdAt).format('MMM DD, YYYY h:mm A');
    this.tagColor = BACKUP_STATUS[status];
    this.description = description;
    this.updatedAt = updatedAt;
    this.typeDisplay = BACKUP_TYPES[type];
    this.files = files;
    this.backupId = backupId;
  }
};
