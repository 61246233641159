export const ORGANIZATION_STATUS = 'ORGANIZATION_STATUS';
export const GENERAL_SETTINGS = 'GENERAL_SETTINGS';
export const INTEGRATION_SETTINGS = 'INTEGRATION_SETTINGS';
export const MANAGE_PRODUCTS = 'MANAGE_PRODUCTS';
export const PRODUCT_SETTINGS = 'PRODUCT_SETTINGS';
export const USERS = 'USERS';
export const SHARED_USERS = 'SHARED_USERS';
export const ORGANIZATION_GROUPS = 'ORGANIZATION_GROUPS';
export const BACKUP = 'BACKUP';
export const ACCESS_TOKENS = 'ACCESS_TOKENS';
export const RESTORE_LOGS = 'RESTORE_LOGS';
export const ORGANIZATION_APP_SETTINGS = 'ORGANIZATION_APP_SETTINGS';

export const AUTHENTICATION_STRATEGY = [
  {
    key: 'SAML2',
    label: 'SAML2',
    value: 'SAML2',
  },
  {
    key: 'local',
    label: 'Local',
    value: 'local',
  },
];

export const LANGUAGE_CONSTANT = {
  english: 'en',
  svenska: 'sv',
  norsk: 'nb',
}

export const LANGUAGE_LIST = [
  {
    key: 'en',
    label: 'English',
    value: 'en',
  },
  {
    key: 'sv',
    label: 'Svenska',
    value: 'sv',
  },
  {
    key: 'nb',
    label: 'Norsk',
    value: 'nb',
  },
];

export const SIGNATUREALGORITHMS = ['sha1', 'sha256', 'sha512'];

// DEPRECATED
export const TEX_OBJECT_TYPE_MAPPING = 'TEX_OBJECT_TYPE_MAPPING';
export const TEX_ROOM_DATA_MODEL = 'TEX_ROOM_DATA_MODEL';
export const TEX_ROOMS = 'TEX_ROOMS';
export const TEX_COURSE_EVENT_DATA_MODEL = 'TEX_COURSE_EVENT_DATA_MODEL';
export const TEX_USERS = 'TEX_USERS';
export const REVIEW_AND_FINALIZE = 'REVIEW_AND_FINALIZE';

export const TE_PREF_OBJECT_TYPE = 'TE_PREF_OBJECT_TYPE';

export const NEW_ORGANIZATION_STEP_STATUS_COMPLETED =
  'NEW_ORGANIZATION_STEP_STATUS_COMPLETED';
export const NEW_ORGANIZATION_STEP_STATUS_INCOMPLETE =
  'NEW_ORGANIZATION_STEP_STATUS_INCOMPLETE';

export const PLACEHOLDER_ROLE = {
  isActive: false,
  app: 'teAdmin',
  organizationGroup: 'root',
  permissionGroup: 'teAdmin:Anonymous',
};

export const ORGANIZATION_TYPE = {
  PRODUCTION: 'Production',
  TESTING: 'Testing',
  OTHER: 'Other'
};

export const EU_EES = 'EU_EES';
export const ANZ = 'ANZ';
export const SGP = 'SGP';
export const REGIONS = {
  [EU_EES]: 'EU & EES',
  [ANZ]: 'Australia & New Zealand',
  [SGP]: 'Singapore',
};

export const RESTRICTION_OPTIONS = {
  NO: 'No restriction',
  ...REGIONS
}

export const EXAM_CONFIGURATION_FIELD_MODE = {
  OBJECT_TYPES: 'OBJECT_TYPES',
  RESERVATION_MODE: 'RESERVATION_MODE'
}

export const EXAM_CONFIGURATION_FIELD_INTEGRATION_OPTIONS = 'EXAM_CONFIGURATION_FIELD_INTEGRATION_OPTIONS'