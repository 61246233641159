/* eslint-disable */
import React from 'react';
import { forEach, filter, findIndex } from 'lodash';
import uuidv4 from 'uuid/v4';
import { Table, Checkbox, Button, Popconfirm } from 'te-antd';

const ACTIONS = ['read', 'create', 'edit', 'delete'];
// eslint-disable-next-line react/prefer-stateless-function
class PagePlayground extends React.Component {
  render() {
    return (
      <div>
        <h1>PagePlayground</h1>
      </div>
    );
  }
}

export default PagePlayground;
