import React from 'react';

// CONSTANTS
import { TE_HOOTDESK } from 'constants/products.constants';

// COMPONENTS
import { Tabs } from 'te-antd';
import { Mapping, ReservationSettings } from 'te-integration-lib';

const { TabPane } = Tabs;

class ProductTEHootdesk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 'TEXObjectTypeMapping',
    };
  }

  onActiveItemChange = activeItem => () => {
    const { activeItem: stateActiveItem } = this.state;
    if (stateActiveItem === activeItem) {
      this.setState(() => ({ activeItem: null }));
    } else {
      this.setState(() => ({ activeItem }));
    }
  };

  render() {
    return (
      <Tabs>
        <TabPane tab="Object Types" key="objectTypes">
          <Mapping appName={TE_HOOTDESK} />
        </TabPane>
        <TabPane tab="Reservation Settings" key="paramsMapping">
          <ReservationSettings appName={TE_HOOTDESK} />
        </TabPane>
      </Tabs>
    );
  }
}

export default ProductTEHootdesk;
