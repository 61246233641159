import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { WithToken } from 'te-auth-app-lib';

// COMPONENTS
import { PageContainer } from 'components/PageLayout/PageLayout';
import MainMenu from './components/MainMenu/MainMenu';
import Breadcrumb from './components/Breadcrumb/Breadcrumb';
import AuthenticatedRoute from './components/AuthenticatedRoute/AuthenticatedRoute';
import { AUTH_DOMAIN } from './configs';

// PAGES
/* PUBLIC PAGES */
import LoginPage from './pages/LoginPage/LoginPage';
import ValidateTokenEffect from './pages/Authentication/ValidateTokenEffect';

/* AUTHENTICATED PAGES */
import HomePage from './pages/HomePage/HomePage';
import OrganizationOverviewPage from './pages/OrganizationOverviewPage/OrganizationOverviewPage';
import NewOrganizationPage from './pages/NewOrganizationPage/NewOrganizationPage';
import PagePlayground from './pages/Playground';
import PageApplications from './pages/PageApplications/PageApplications';
import PageUsers from './pages/PageUsers/PageUsers';

// CSS
import './App.scss';
import { parseJwt } from './providers/utils/jwtHelpers';
import { addRegionToOrganization } from './providers/organizations/organizations.actions';

// eslint-disable-next-line react/prefer-stateless-function
class App extends Component {
  render() {
    const { breadcrumbs } = this.props;
    return (
      <WithToken.Provider
        options={{
          // effect to update localStorage Token (should treat as app cache)
          onToken: (token) => {
            localStorage.setItem('api_token', token);
            let { region } = parseJwt(token);

            // JUST FOR CASE WHEN TOKEN NOT INCLUDE ANY REGIONS
            if (!region) region = 'EU_EES';
            
            const { doAddRegion } = this.props;
            doAddRegion(region);
          },
          domain: AUTH_DOMAIN,
        }}
      >
        <Router>
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/playground" component={PagePlayground} />
            <AuthenticatedRoute>
              <div className="App container-fluid">
                <MainMenu />
                <div className="page-content-area">
                  <Breadcrumb routes={breadcrumbs} />
                  <PageContainer>
                    <Switch>
                      <Route exact path="/" component={HomePage} />
                      <Route
                        exact
                        path="/organizations"
                        component={OrganizationOverviewPage}
                      />
                      <Route
                        exact
                        path="/organizations/new"
                        component={NewOrganizationPage}
                      />
                      <Route
                        exact
                        path="/organizations/:organizationId"
                        component={NewOrganizationPage}
                      />

                      {/* {APPLICATIONS} */}
                      <Route
                        path="/applications"
                        component={PageApplications}
                      />
                      <Route exact path="/users" component={PageUsers} />
                    </Switch>
                  </PageContainer>
                </div>
              </div>
            </AuthenticatedRoute>
          </Switch>
        </Router>
        <ValidateTokenEffect />
      </WithToken.Provider>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  doAddRegion: (region) => dispatch(addRegionToOrganization(region)),
});

const mapStateToProps = (state) => ({
  breadcrumbs: state.globalUI.breadcrumbs,
});

App.propTypes = {
  breadcrumbs: PropTypes.array,
  doAddRegion: PropTypes.func.isRequired,
};
App.defaultProps = {
  breadcrumbs: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
