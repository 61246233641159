import React from 'react';
import { Form, Radio } from 'te-antd';

const COPY_USER = {
  NOT_COPY: "Don't copy",
  COPY_WITHOUT_ROLE: 'Copy but set to no active role',
  COPY: 'Copy as-is'
}

const UserManagement = ({ form, formItemLayout }) => {
  const { getFieldDecorator } = form;

  return (
    <Form.Item
      label="User Management"
      name="teAdmin.users"
      {...formItemLayout}
    >
      {getFieldDecorator('teAdmin.users', {
        initialValue: 'NOT_COPY'
      })(
        <Radio.Group>
          {Object.keys(COPY_USER).map((key) => (
            <Radio key={key} value={key}>{COPY_USER[key]}</Radio>
          ))}
        </Radio.Group>
      )}
    </Form.Item>
  );
}

export default UserManagement;
