import _ from 'lodash';
import { initialState } from './organizations.initialState';
import * as types from './organizations.actionTypes';

export default (state = initialState, action) => {
  switch (action.type) {
    // //////////////////////////////////////////////
    // FETCH ORGANIZATIONS
    // //////////////////////////////////////////////
    case types.FETCH_ORGANIZATIONS:
      return {
        ...state,
        organizationsLoading: true,
        list: {},
      };

    case types.FETCH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizationsLoading: false,
        list: _.keyBy(action.payload.data.organizations, '_id'),
      };

    case types.FETCH_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        organizationsLoading: false,
        list: [],
      };

    case types.SEARCH_ORGANIZATIONS:
      return {
        ...state,
        orgSearch: {
          ...state.orgSearch,
          [action.filterKey]: action.searchText
        }
      }
    case types.DEACTIVATE_ORGANIZATIONS_SUCCESS:
    case types.ACTIVATE_ORGANIZATIONS_SUCCESS: {
      const organization = _.get(action, 'payload.data.organization');
      return {
        ...state,
        list: {
          ...state.list,
          [organization._id]: organization
        }
      }
    }
    case types.FLUSH_COPY_ORGANIZATION: {
      return {
        ...state,
        orgSelected: null,
        copyOrgSuccess: false,
        copyOrgError: null
      }
    }
    case types.SELECT_COPY_ORGANIZATION: {
      const { organizationId } = action.payload;
      return {
        ...state,
        orgSelected: state.list[organizationId]
      }
    }
    case types.FETCH_ORGANIZATION_DETAIL_CONNECTION: {
      return {
        ...state,
        connectSettingLoad: true,
      }
    }
    case types.FETCH_ORGANIZATION_DETAIL_CONNECTION_SUCCESS: {
      const connectionSetting = _.get(action, ['payload', 'data', 'connectionSetting'])

      return {
        ...state,
        connectSettingLoad: false,
        connectionSettingData: {
          ...state.connectionSettingData,
          [connectionSetting.organizationId]: connectionSetting
        }
      }
    }
    case types.FETCH_APP_SETTING_COPY: {
      return {
        ...state,
        isFetchAppSetting: true
      }
    }
    case types.FETCH_APP_SETTING_COPY_FAILURE: {
      return {
        ...state,
        isFetchAppSetting: false,
      }
    }
    case types.FETCH_APP_SETTING_COPY_SUCCESS: {
      const appSettings = _.get(action, ['payload', 'data', 'appSettings'])

      return {
        ...state,
        isFetchAppSetting: false,
        orgSelected: {
          ...state.orgSelected,
          appSettings
        },
      }
    }
    case types.FETCH_ORGANIZATION_DETAIL_CONNECTION_FAILURE: {
      return {
        ...state,
        connectSettingLoad: false
      }
    }
    case types.COPY_ORGANIZATION: {
      return {
        ...state,
        isCopying: true,
      }
    }
    case types.COPY_ORGANIZATION_SUCCESS: {
      const organization = _.get(action, ['payload', 'data', 'organization']);
      return {
        ...state,
        isCopying: false,
        copyOrgSuccess: true,
        list: {
          [organization._id]: organization,
          ...state.list,
        }
      }
    }
    case types.COPY_ORGANIZATION_FAILURE: {
      return {
        ...state,
        isCopying: false,
        copyOrgError: _.get(action, ['payload', 'error', 'message'], 'Unknown')
      }
    }
    
    case types.ADD_REGION_TO_ORGANIZATION: {
      return {
        ...state,
        region: _.get(action, ['payload', 'region'])
      }
      }

    default:
      return {
        ...state,
      };
  }
};
