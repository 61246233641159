// ACTION TYPES
import * as types from './globalUI.actionTypes';
import initialState from './globalUI.initialState';

export const globalUI = (state = initialState, action) => {
  switch (action.type) {
    case types.SELECT_MENU_ITEM:
      return {
        ...state,
        ...action.payload,
      };

    case types.SELECT_SUB_MENU_ITEM:
      return {
        ...state,
        ...action.payload,
      };

    case types.HIDE_SECONDARY_SIDEBAR:
      return {
        ...state,
        shouldShowSecondSidebar: false,
      };
    case types.SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.breadcrumbs,
      };

    case types.START_EDITING_TABLE:
      return {
        ...state,
        editingTable: {
          ...state.editingTable,
          [action.tableId]: true,
        },
      };

    case types.STOP_EDITING_TABLE:
      return {
        ...state,
        editingTable: {
          ...state.editingTable,
          [action.tableId]: false,
        },
      };

    default:
      return state;
  }
};
