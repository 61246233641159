import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { omit, forEach } from 'lodash';

// HELPERS
import { usersConverter } from 'providers/utils/general';

// ACTIONS
import {
  updateUser,
  updateUserPassword,
} from 'providers/newOrganization/newOrganization.actions';
import { startEditingUser } from 'providers/newOrganization/editingUser/editingUser.actions';

// CONSTANTS
import {
  USERS,
  SHARED_USERS,
  PRODUCT_SETTINGS,
} from 'constants/organization.constants';
import { TE_ADMIN } from 'constants/products.constants';
import { LANGUAGES } from 'constants/general.constants';

// ICONS
import { MdCheck, MdClear } from 'react-icons/md';

// COMPONENT
import AnimationLoading from 'components/AnimationLoading/AnimationLoading';
import EditableTable from 'components/Tables/EditableTable/EditableTable';
import { Switch, Button } from 'te-antd';
import PopoverUserPassword from '../../Components/PopoverUserPassword';
import EditingUserModals from '../../Components/EditingUserModals/EditingUserModals';

const mapStateToProps = state => {
  const appRoles = omit(state.teApps.roles, [TE_ADMIN]);
  const { productKeys, products } = state.newOrganization[PRODUCT_SETTINGS];

  return {
    organizationId: state.newOrganization.organizationId,
    users: usersConverter({
      users: state.newOrganization[SHARED_USERS].list,
      productKeys,
      appRoles,
    }),
    productKeys,
    products,
    saving: state.newOrganization.saving[USERS],
    loading: state.newOrganization[SHARED_USERS].loading,
    teApps: omit(state.teApps.apps.map, [TE_ADMIN]),
    appRoles,
    sharedUserRole: state.teApps.sharedUserRole,
    editingUser: state.editingUser.userRoles,
  };
};

const mapActionsToProps = {
  updateUser,
  updateUserPassword,
  startEditingUser,
  // deleteUser,
};

class TEXUsers extends React.Component {
  constructor(props) {
    super(props);

    const { appRoles, organizationId, updateUser } = props;

    const cols = {
      firstName: {
        title: 'First Name',
        dataIndex: 'firstName',
        width: '15%',
        editable: true,
        inputType: 'text',
        inputRules: [
          {
            required: true,
            message: 'Please input First Name!',
          },
        ],
      },
      lastName: {
        title: 'Last Name',
        dataIndex: 'lastName',
        width: '15%',
        editable: true,
        inputType: 'text',
        inputRules: [
          {
            required: true,
            message: 'Please input Last Name!',
          },
        ],
      },
      email: {
        title: 'Email',
        dataIndex: 'email',
        width: '15%',
        editable: false,
        inputType: 'text',
        inputRules: [
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input Email!',
          },
        ],
      },
      language: {
        editable: true,
        title: 'Language',
        dataIndex: 'language',
        width: '15%',
        inputType: 'select',
        inputOptions: {
          placeholder: 'Unselected',
          style: { width: '100%' },
          options: LANGUAGES,
        },
      },
      roles: {
        render: user => (
          <Button onClick={this.onModalOpen(user)} type="link">
            View role
          </Button>
        ),
      },
    };

    this.usersCols = [
      cols.firstName,
      cols.lastName,
      cols.email,
      cols.language,
      cols.roles,
      {
        title: 'Status',
        render: user => (
          <Switch
            checkedChildren={<MdCheck />}
            unCheckedChildren={<MdClear />}
            defaultChecked={user.isActive}
            onChange={this.onUserStatusToggle(user)}
          />
        ),
      },
      {
        title: '',
        render: user => {
          return (
            <PopoverUserPassword
              onSubmit={newPassword => this.onPasswordChange(user, newPassword)}
            />
          );
        },
      },
      {
        title: 'Actions',
        width: '150px',
        editableButtons: {
          editButton: {
            onClick: user => updateUser({ organizationId, user, appRoles }),
          },
        },
      },
    ];
  }

  onUserUpdate = data => {
    const { organizationId, user, appRoles } = data;
    const { updateUser } = this.props;
    updateUser({
      organizationId,
      user: {
        ...user,
        type: 'share',
      },
      appRoles,
    });
  };

  onUserStatusToggle = user => () => {
    const { updateUser, organizationId } = this.props;
    updateUser({
      organizationId,
      user: {
        ...user,
        _id: user._id,
        isActive: !user.isActive,
      },
    });
  };

  onPasswordChange = (user, newPassword) => {
    const { updateUserPassword, organizationId } = this.props;
    updateUserPassword({
      organizationId,
      user: {
        ...user,
        _id: user._id,
        password: newPassword,
      },
    });
  };

  userOrganizationGroupsConverter = userRoles => {
    const organizationGroups = [];
    forEach(userRoles, (roles, appName) => {
      forEach(roles, role => {
        organizationGroups.push({
          app: appName,
          ...role,
        });
      });
    });
    return organizationGroups;
  };

  onModalOpen = user => () => {
    const { startEditingUser } = this.props;
    startEditingUser({ editingUser: user });
  };

  onCreateNewUserModalOpen = () => {
    this.setState(() => ({
      addNewUserModalOpened: true,
    }));
  };

  onModalClose = () => {
    this.setState(() => ({
      modalOpened: false,
      editingUser: null,
    }));
  };

  // ----------------------------------------------
  // RENDERING
  // ----------------------------------------------
  render() {
    const { loading, users } = this.props;
    if (loading) return <AnimationLoading />;
    const { saving } = this.props;

    return (
      <div className="shared-users">
        <EditableTable
          className="table--editable"
          wrappedComponentRef={el => {
            this.editableTable = el ? el.props.form : null;
          }}
          columns={this.usersCols}
          dataSource={users}
          loading={saving}
          footer={() => (
            <div style={{ textAlign: 'right' }}>
              <Button
                onClick={this.onModalOpen({
                  userRoles: {},
                  organizationGroups: {},
                })}
              >
                Add new user
              </Button>
            </div>
          )}
        />
        <EditingUserModals type="share" />
      </div>
    );
  }
}

TEXUsers.propTypes = {
  organizationId: PropTypes.string.isRequired,
  users: PropTypes.array,
  saving: PropTypes.bool,
  loading: PropTypes.bool,
  appRoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateUser: PropTypes.func.isRequired,
  updateUserPassword: PropTypes.func.isRequired,
  startEditingUser: PropTypes.func.isRequired,
};

TEXUsers.defaultProps = {
  users: [],
  saving: false,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(TEXUsers);
