import React from 'react';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Table, Tag } from 'te-antd';
import { generateBackup } from 'providers/backup/backup.actions';

const BackupList = ({
  saving,
  generateBackup,
  handleOpen,
  setBackup,
  list,
  loadingGenerateBackup,
  restoreLogs,
  match,
}) => {
  const selectBackup = backup => {
    const organizationId = get(match, 'params.organizationId');
    setBackup({ ...backup, organizationId });
    handleOpen();
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Type',
      dataIndex: 'typeDisplay',
      key: 'type',
    },
    {
      title: 'Status',
      render: backup => {
        let { tagColor, status } = backup;
        if (restoreLogs[backup.backupId]) {
          tagColor = 'green';
          status = 'RESTORED'
        }
        return (
          <Tag color={tagColor}>{status.toUpperCase()}</Tag>
        )
      },
    },
    {
      title: 'Action',
      render: backup => (
        <Button onClick={() => selectBackup(backup)}>Restore</Button>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={list}
      loading={saving}
      footer={() => (
        <div style={{ textAlign: 'right' }}>
          {!loadingGenerateBackup ? (
            <Button onClick={generateBackup}>Generate Backup</Button>
          ) : (
            <Button loading>Generating</Button>
          )}
        </div>
      )}
    />
  );
};

BackupList.propTypes = {
  saving: PropTypes.bool,
  generateBackup: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  setBackup: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.any),
  restoreLogs: PropTypes.objectOf(PropTypes.any),
  loadingGenerateBackup: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

BackupList.defaultProps = {
  saving: false,
  list: [],
  restoreLogs: {},
};

const mapStateToProps = ({ backup, newOrganization }) => ({
  list: backup.list,
  restoreLogs: get(newOrganization, ['RESTORE_LOGS', 'mapped']),
  loadingGenerateBackup: backup.loadingGenerateBackup,
});

const mapActionsToProps = {
  generateBackup,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapActionsToProps
  )(BackupList)
);
