import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// CONSTANTS
import { GENERAL_SETTINGS } from 'constants/organization.constants';

// COMPONENTS
import { Form, Switch, Input, Button } from 'te-antd';

// ACTIONS
import { saveSharedUsersConfig } from 'providers/newOrganization/newOrganization.actions';

// eslint-disable-next-line react/prefer-stateless-function
const SharedUsersConfig = props => {
  const { form, shareUserConfig, saveSharedUsersConfig, saving } = props;
  const { getFieldDecorator } = form;
  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            saveSharedUsersConfig({ shareUserConfig: values });
          }
        });
      }}
      style={{ maxWidth: '500px' }}
      layout="horizontal"
    >
      <Form.Item label="Is First Login Create?" style={{ display: 'flex' }}>
        {getFieldDecorator('isFirstLoginCreate')(
          <Switch defaultChecked={shareUserConfig.isFirstLoginCreate} />
        )}
      </Form.Item>
      <Form.Item label="Email Domain">
        {getFieldDecorator('emailDomain', {
          initialValue: shareUserConfig.emailDomain,
        })(<Input />)}
      </Form.Item>
      <Form.Item>
        <Button loading={saving} type="primary" htmlType="submit">
          Save changes
        </Button>
      </Form.Item>
    </Form>
  );
};

SharedUsersConfig.propTypes = {
  form: PropTypes.object.isRequired,
  saveSharedUsersConfig: PropTypes.func.isRequired,
  shareUserConfig: PropTypes.object,
  saving: PropTypes.bool,
};
SharedUsersConfig.defaultProps = {
  shareUserConfig: {},
  saving: false,
};

const mapActionsToProps = {
  saveSharedUsersConfig,
};

const mapStateToProps = state => ({
  shareUserConfig: state.newOrganization[GENERAL_SETTINGS].shareUserConfig,
  saving: state.newOrganization.saving[GENERAL_SETTINGS],
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Form.create({ name: 'SharedUsersConfig' })(SharedUsersConfig));
