import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forEach, remove } from 'lodash';

// ACTIONS
import {
  updateOrganizationGroups,
  initEditingOrganizationGroup,
  saveOrganizationGroup,
} from 'providers/organizationGroups/organizationGroups.actions';

// COMPONENTS
import { Tree, TEInput, Icon } from 'te-antd';

const { TreeNode } = Tree;

class OrganizationGroupsListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
    };
  }

  onGroupClick = item => () => {
    const { initEditingOrganizationGroup } = this.props;
    initEditingOrganizationGroup(item._id);
  };

  onDrop = (info, dataInput) => {
    let closetParent = null;
    const { dropToGap, node, dragNode } = info;
    const {
      props: { eventKey },
    } = node;
    const data = [...dataInput];
    if (dropToGap) {
      const sameLevelNode = dataInput.find(item => item._id === eventKey);
      if (sameLevelNode && Array.isArray(sameLevelNode.parentIds)) {
        if (sameLevelNode.parentIds.length === 1) {
          closetParent = { _id: sameLevelNode.parentIds[0] };
        } else {
          closetParent = this.findClosestParent(node, dataInput);
        }
      }
    } else {
      closetParent = { _id: eventKey };
    }
    let updatedGroup = null;
    if (closetParent && closetParent._id) {
      data.forEach((item, itemIndex) => {
        if (item._id === dragNode.props.eventKey) {
          if (data[itemIndex].parentIds.length === 1) {
            data[itemIndex].parentIds = [closetParent._id];
          } else if (
            data[itemIndex].parentIds.indexOf(closetParent._id) === -1
          ) {
            if (Array.isArray(data[itemIndex].parentIds)) {
              data[itemIndex].parentIds.push(closetParent._id);
              const curParent = this.findClosestParent(
                info.dragNode,
                dataInput
              );
              remove(
                data[itemIndex].parentIds,
                parentId => parentId === curParent._id
              );
            } else {
              data[itemIndex].parentIds = [closetParent._id];
            }
          }
          updatedGroup = data[itemIndex];
        }
      });
    }
    return {
      updatedGroup,
      updatedList: data,
    };
  };

  findClosestParent = (node, groups) => {
    let result = null;
    const parentNode = node.selectHandle.closest('ul').closest('li');
    if (parentNode) {
      forEach(parentNode.children, item => {
        if (item.classList.contains('ant-tree-node-content-wrapper')) {
          result = groups.find(group => {
            return group.name === item.innerText;
          });
        }
      });
    }
    return result;
  };

  onGroupsUpdate = info => {
    const {
      organizationGroupsList,
      updateOrganizationGroups,
      saveOrganizationGroup,
    } = this.props;
    const { updatedList, updatedGroup } = this.onDrop(
      info,
      organizationGroupsList
    );
    updateOrganizationGroups(updatedList);
    saveOrganizationGroup({ organizationGroup: updatedGroup });
  };

  onQueryChange = query => {
    this.setState(() => ({
      query,
    }));
  };

  render() {
    const { organizationGroups, organizationGroup } = this.props;
    const { query } = this.state;
    const loop = data =>
      data.map(item => {
        const index = query
          ? item.name.toLowerCase().indexOf(query.toLowerCase())
          : -1;
        const beforeStr = item.name.substr(0, index);
        const afterStr = item.name.substr(index + query.length);
        const name =
          index > -1 ? (
            <div onClick={this.onGroupClick(item)}>
              {beforeStr}
              <span style={{ color: '#f50' }}>
                {item.name.slice(index, index + query.length)}
              </span>
              {afterStr}
            </div>
          ) : (
            <div onClick={this.onGroupClick(item)}>{item.name}</div>
          );
        if (item.children) {
          return (
            <TreeNode selectable={false} key={item._id} title={name}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={item._id} title={name} />;
      });

    return (
      <div id="pageOrganizationGroups">
        <TEInput
          value={query}
          onChange={this.onQueryChange}
          suffix={<Icon type="search" />}
        />
        <Tree
          selectedKeys={[organizationGroup ? organizationGroup._id : null]}
          className="draggable-tree"
          draggable
          blockNode
          autoExpandParent
          defaultExpandParent
          defaultExpandAll
          onDrop={this.onGroupsUpdate}
        >
          {loop(organizationGroups)}
        </Tree>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  organizationGroups: state.organizationGroups.formatedList,
  organizationGroupsList: state.organizationGroups.list,
  organizationGroup: state.organizationGroups.edit,
});

const mapActionsToProps = {
  updateOrganizationGroups,
  initEditingOrganizationGroup,
  saveOrganizationGroup,
};

OrganizationGroupsListing.propTypes = {
  updateOrganizationGroups: PropTypes.func.isRequired,
  initEditingOrganizationGroup: PropTypes.func.isRequired,
  organizationGroups: PropTypes.array,
  organizationGroupsList: PropTypes.array,
  saveOrganizationGroup: PropTypes.func.isRequired,
  organizationGroup: PropTypes.object,
};
OrganizationGroupsListing.defaultProps = {
  organizationGroups: [],
  organizationGroupsList: [],
  organizationGroup: null,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(OrganizationGroupsListing);
