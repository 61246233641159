import React from 'react';
import ConfigurationItem from './ConfigurationItem';
import './DataSourceTypes.scss';

const CONFIGURATIONS = [
  {
    title: 'Schedule generator',
    placeholder: 'No other product requirements',
    field: 'SCHEDULE_GENERATOR'
  },
  {
    title: 'Reservations',
    placeholder: 'No other product requirements',
    field: 'RESERVATIONS'
  },
  {
    title: 'Orders',
    placeholder: 'Requires TE Plan',
    field: 'ORDERS'
  },
  {
    title: 'Exams',
    placeholder: 'Requires TE Exam',
    field: 'EXAMS'
  },
  {
    title: 'Forms',
    placeholder: 'Requires TE Preferences',
    field: 'FORMS'
  },
  {
    title: 'Activities',
    placeholder: 'Requires TE Preferences',
    field: 'ACTIVITIES'
  },
]

const DataSourceTypes = () => {
  return (
    <div className="report-configuration">
      { CONFIGURATIONS.map((item) => (
        <ConfigurationItem key={item.field} field={item.field} placeholder={item.placeholder} title={item.title} />
      ))}
    </div>
  )
}

export default DataSourceTypes;