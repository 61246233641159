import {
  GENERAL_SETTINGS,
  INTEGRATION_SETTINGS,
  PRODUCT_SETTINGS,
  TEX_OBJECT_TYPE_MAPPING,
  TEX_ROOM_DATA_MODEL,
  TEX_ROOMS,
  TEX_COURSE_EVENT_DATA_MODEL,
  TEX_USERS,
  REVIEW_AND_FINALIZE,
  NEW_ORGANIZATION_STEP_STATUS_INCOMPLETE,
  USERS,
  SHARED_USERS,
  ACCESS_TOKENS,
  EXAM_CONFIGURATION_FIELD_INTEGRATION_OPTIONS,
} from 'constants/organization.constants';

export const initialState = {
  activeStep: GENERAL_SETTINGS,
  organizationId: null,
  saving: {
    [USERS]: false,
    [GENERAL_SETTINGS]: false,
    [INTEGRATION_SETTINGS]: false,
  },
  status: {
    [GENERAL_SETTINGS]: NEW_ORGANIZATION_STEP_STATUS_INCOMPLETE,
    [INTEGRATION_SETTINGS]: NEW_ORGANIZATION_STEP_STATUS_INCOMPLETE,
    [PRODUCT_SETTINGS]: NEW_ORGANIZATION_STEP_STATUS_INCOMPLETE,
    [TEX_OBJECT_TYPE_MAPPING]: NEW_ORGANIZATION_STEP_STATUS_INCOMPLETE,
    [TEX_ROOM_DATA_MODEL]: NEW_ORGANIZATION_STEP_STATUS_INCOMPLETE,
    [TEX_ROOMS]: NEW_ORGANIZATION_STEP_STATUS_INCOMPLETE,
    [TEX_COURSE_EVENT_DATA_MODEL]: NEW_ORGANIZATION_STEP_STATUS_INCOMPLETE,
    [TEX_USERS]: NEW_ORGANIZATION_STEP_STATUS_INCOMPLETE,
    [REVIEW_AND_FINALIZE]: NEW_ORGANIZATION_STEP_STATUS_INCOMPLETE,
    [USERS]: NEW_ORGANIZATION_STEP_STATUS_INCOMPLETE,
  },
  [GENERAL_SETTINGS]: {
    name: '',
    loading: false,
    hasEdits: true,
    authConfig: {},
    shareUserConfig: {},
    backgroundColor: '#ffffff',
    logo:
      'https://storage.googleapis.com/te-admin-public/images/default-logo.png',
    authStrategy: 'local',
    customerSignature: '',
  },
  [INTEGRATION_SETTINGS]: {
    baseUrl: '',
    username: '',
    password: '',
    certificateKey: '',
    loading: false,
  },
  [TEX_OBJECT_TYPE_MAPPING]: {
    roomId: '',
    courseEventId: '',
    professorId: '',
    loading: false,
  },
  [TEX_ROOM_DATA_MODEL]: {},
  [TEX_ROOMS]: [],
  [TEX_COURSE_EVENT_DATA_MODEL]: {},
  [TEX_USERS]: [
    {
      firstName: 'Chuong',
      lastName: 'Tran 1',
      email: 'chuong+1@timeedit.om',
      role: 1,
      userId: '355255a1-18d1-49d7-8d88-d9bc2cdadcae',
    },
    {
      firstName: 'Chuong',
      lastName: 'Tran 2',
      email: 'chuong+2@timeedit.om',
      role: 1,
      userId: '47a4ca5a-14ac-4834-bd5d-56250994e41a',
    },
    {
      firstName: 'Chuong',
      lastName: 'Tran 3',
      email: 'chuong+3@timeedit.om',
      role: 1,
      userId: 'aafcce2b-f9ab-45c2-9494-7e9ffa2d1de9',
    },
  ],
  [REVIEW_AND_FINALIZE]: {},

  [PRODUCT_SETTINGS]: {
    productKeys: [],
    products: {},
    loading: false,
    applying: false,
  },

  [USERS]: {
    list: [],
    loading: false,
  },
  [SHARED_USERS]: {
    list: [],
    loading: false,
  },
  [ACCESS_TOKENS]: {
    tokens: {},
    loading: false,
  },
  organizationLoading: false,
  [EXAM_CONFIGURATION_FIELD_INTEGRATION_OPTIONS]: {
    examTitle: {
      mode: {
        isLoading: false,
        options: []
      },
      field: {
        isLoading: false,
        options: []
      },
    },
    student: {
      mode: {
        isLoading: false,
        options: []
      },
      field: {
        isLoading: false,
        options: []
      },
    }
  }
};
