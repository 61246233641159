import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Tag } from 'te-antd';

const BackupList = ({
  saving,
  list,
}) => {
  const columns = [
    {
      title: 'Backup Date',
      dataIndex: 'backupDate',
      key: 'backupDate',
    },
    {
      title: 'Restored By',
      dataIndex: 'restoredBy',
      key: 'restoredBy',
    },
    {
      title: 'Status',
      render: (item) => (
        <Tag color={item.tagColor}>{item.status}</Tag>
      ),
    },
    {
      title: 'Restore date',
      dataIndex: 'restoreDate',
      key: 'restoreDate',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={list}
      loading={saving}
    />
  );
};

BackupList.propTypes = {
  saving: PropTypes.bool,
  list: PropTypes.objectOf(PropTypes.any),
};

BackupList.defaultProps = {
  saving: false,
  list: {},
};

const mapStateToProps = ({ newOrganization }) => ({
  list: _.get(newOrganization, ['RESTORE_LOGS', 'list']),
});

export default withRouter(
  connect(
    mapStateToProps
  )(BackupList)
);
