import React from 'react';
import PropTypes from 'prop-types';
import { TreeSelect } from 'te-antd';
import { keyBy } from 'lodash';

const { TreeNode } = TreeSelect;

const TETreeSelect = props => {
  const { options, ...rest } = props;
  const allOptions = keyBy(options, '_id');

  const loop = data =>
    data.map(item => {
      if (item.children) {
        const curItem = allOptions[item._id];
        return (
          <TreeNode
            value={curItem._id}
            key={curItem._id}
            title={curItem.name}
            disabled={curItem.disabled}
          >
            {loop(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          value={item._id}
          key={item._id}
          title={item.name}
          disabled={item.disabled}
        />
      );
    });
  const filteredOptions = options.filter(item => item.isRoot);
  return <TreeSelect {...rest}>{loop(filteredOptions)}</TreeSelect>;
};

TETreeSelect.propTypes = {
  options: PropTypes.array,
};
TETreeSelect.defaultProps = {
  options: [],
};

export default TETreeSelect;
