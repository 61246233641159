/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { Button, Popconfirm } from 'te-antd';

// eslint-disable-next-line react/prefer-stateless-function
class SaveRoleButton extends React.Component {
  render() {
    const { editingUser, children, onConfirm } = this.props;
    if (!editingUser) return null;
    const { userRoles } = editingUser;
    const hasActiveRole = find(userRoles, app =>
      (app || []).find(item => item.isActive)
    );
    if (hasActiveRole) return <Button onClick={() => onConfirm()}>{children}</Button>;
    return (
      <Popconfirm
        title={(
          <div>
            <p><b style={{ fontSize: '16px' }}>Confirmation</b></p>
            <span>
              This user has no role and will no longer be able to login.
              <br />
              Are you sure you want to continue?
            </span>
          </div>
        )}
        onConfirm={e => {
          e.stopPropagation();
          onConfirm();
        }}
        onCancel={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
      >
        <Button type="primary" onClick={e => e.stopPropagation()}>{children}</Button>
      </Popconfirm>
    );
  }
}

const mapStateToProps = state => ({
  editingUser: state.editingUser.editingUser,
});

SaveRoleButton.propTypes = {
  editingUser: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  onConfirm: PropTypes.func.isRequired,
};
SaveRoleButton.defaultProps = {
  children: 'Save changes',
};
export default connect(mapStateToProps)(SaveRoleButton);
