/* eslint-disable */
// @flow

import moment from 'moment';
import ApiConstructor from './api.constructor';

export const api = new ApiConstructor();

const defaultHeaders = {
  'Content-Type': 'application/json',
};
api.get = ApiConstructor.methodFactory.call(api, (path, body = {}, headers) => {
  const params = body;
  let url = path;

  const search = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  if (search.length > 0) {
    url = `${url}?${search}`;
  }
  return [
    url,
    {
      method: 'GET',
      headers: headers | defaultHeaders,
    },
  ];
});

api.post = ApiConstructor.methodFactory.call(api, (path, body, headers) => [
  path,
  { method: 'POST', body: body instanceof FormData ? body : JSON.stringify(body), headers: headers || defaultHeaders },
  { rawBody: body },
]);

api.put = ApiConstructor.methodFactory.call(api, (path, body, headers) => [
  path,
  { method: 'PUT', body: body instanceof FormData ? body : JSON.stringify(body), headers: headers || defaultHeaders },
  { rawBody: body },
]);

api.patch = ApiConstructor.methodFactory.call(api, (path, body, headers) => [
  path,
  { method: 'PATCH', body: body instanceof FormData ? body : JSON.stringify(body), headers: headers || defaultHeaders },
  { rawBody: body },
]);

api.delete = ApiConstructor.methodFactory.call(api, (path, body = {}, headers) => [
  path,
  { method: 'DELETE', body: JSON.stringify(body), headers: headers || defaultHeaders },
  { rawBody: body },
]);

export const enhanceBodyWithFilter = (tableConfiguration, body) => {
  const isNonFilter =
    !tableConfiguration ||
    !tableConfiguration.isFiltered ||
    tableConfiguration.filters.list.length === 0;

  if (!isNonFilter) {
    const additionalFilters = {
      '@op': tableConfiguration.filters.allCombination ? 'AND' : 'OR',
      filters: tableConfiguration.filters.list.map(filter => {
        let { value: filterValue, valueEnd: filterValueEnd } = filter;

        const {
          op: { value: opValue, valueTemplate },
        } = filter;

        if (filterValue instanceof moment || filterValue instanceof Date) {
          filterValue = Math.ceil(filterValue.valueOf() / 1000);
        }
        if (
          filterValueEnd instanceof moment ||
          filterValueEnd instanceof Date
        ) {
          filterValueEnd = Math.ceil(filterValueEnd.valueOf() / 1000);
        }

        if (valueTemplate) {
          filterValue = valueTemplate(filterValue);
          filterValueEnd = valueTemplate(filterValueEnd);
        }
        if (Array.isArray(opValue)) {
          return {
            '@op': 'AND',
            filters: [
              {
                field: filter.template.apiFilterKey || filter.template.field,
                '@op': opValue[0],
                value: filterValue,
              },
              {
                field: filter.template.apiFilterKey || filter.template.field,
                '@op': opValue[1],
                value: filterValueEnd,
              },
            ],
          };
        }
        return {
          field: filter.template.apiFilterKey || filter.template.field,
          '@op': opValue,
          value: filterValue,
        };
      }),
    };

    if (body['@havingFilters'] || body['@filters']) {
      let currentFilters = body['@havingFilters'] || body['@filters'];

      if (!Array.isArray(currentFilters)) currentFilters = [currentFilters];

      // eslint-disable-next-line no-param-reassign
      body['@havingFilters'] = {
        '@op': 'AND',
        filters: currentFilters.concat(additionalFilters),
      };
    } else {
      const haveCalculatedFilter = tableConfiguration.filters.list.some(
        filter => filter.template.calculatedField
      );
      // eslint-disable-next-line no-param-reassign
      body[
        haveCalculatedFilter ? '@havingFilters' : '@filters'
      ] = additionalFilters;
    }
  }

  return body;
};
