import * as types from './FreeTextFilter.actionTypes';
import { initialState } from './FreeTextFilter.initialState';

export default function objects(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case types.EDIT_FREE_TEXT_FILTER: {
      const { path, freeTextFilterString } = payload;
      if (!path) return state;
      if (!freeTextFilterString)
        return {
          ...state,
          [path]: {
            ...state[path],
            freeTextFilterString: '',
          },
        };

      return {
        ...state,
        [path]: {
          ...state[path],
          freeTextFilterString,
        },
      };
    }

    default:
      return { ...state };
  }
}
