import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// ACTIONS
import {
  addNewPermission,
  deletePermission,
  updatePermission,
  deleteRole,
} from 'providers/teApps/teApps.actions';
import { setBreadcrumbs } from 'providers/globalUI/globalUI.actions';

// COMPONENST
import { Tabs } from 'te-antd';
import { SectionHeader } from 'components/PageLayout/PageLayout';
import Permissions from './Components/Permissions/Permissions';
import Roles from './Components/Roles/Roles';

const { TabPane } = Tabs;
// eslint-disable-next-line react/prefer-stateless-function
class PageApplicationDetails extends React.Component {
  componentWillMount() {
    const { setBreadcrumbs, application } = this.props;
    setBreadcrumbs([
      {
        path: './',
        label: 'Applications',
      },
      {
        path: './:applicationId',
        label: application.name,
      },
    ]);
  }

  componentDidUpdate(nextProps) {
    const { setBreadcrumbs, application } = this.props;
    if (nextProps.application._id !== application._id) {
      setBreadcrumbs([
        {
          path: './',
          label: 'Applications',
        },
        {
          path: './:applicationId',
          label: application.name,
        },
      ]);
    }
  }

  // ----------------------------------------------------------------
  // EVENT HANDLER
  // ----------------------------------------------------------------
  onRoleEdit = item => () => {
    const { match, history } = this.props;
    const { params } = match;
    history.push(`/applications/${params.applicationId}/roles/${item._id}`);
  };

  onRoleAdd = () => {
    const { match, history } = this.props;
    const { params } = match;
    history.push(`/applications/${params.applicationId}/roles/new`);
  };

  onRoleDelete = role => {
    const { deleteRole } = this.props;
    deleteRole({ role });
  };

  onPermissionDelete = permission => {
    const { deletePermission } = this.props;
    deletePermission({ permission });
  };

  onPermissionCreate = permission => {
    const { addNewPermission, application } = this.props;
    addNewPermission({
      permission: {
        ...permission,
        app: application.name,
      },
    });
  };

  onPermissionUpdate = permission => {
    const { updatePermission } = this.props;
    updatePermission({ permission });
  };

  // ----------------------------------------------------------------
  // RENDERING
  // ----------------------------------------------------------------
  renderObjectTypes = () => {
    return (
      <div>
        <h3>Object types</h3>
        <div>aaa</div>
      </div>
    );
  };

  renderPermission = () => {
    const { permissions, permissionCreating } = this.props;
    return (
      <div>
        <Permissions
          onCreate={this.onPermissionCreate}
          onDelete={this.onPermissionDelete}
          creating={permissionCreating}
          permissions={permissions}
          onUpdate={this.onPermissionUpdate}
        />
      </div>
    );
  };

  renderRoles = () => {
    const { roles } = this.props;
    return (
      <Roles
        onDelete={this.onRoleDelete}
        onGoToCreateNewRole={this.onRoleAdd}
        onGoToRoleDetails={this.onRoleEdit}
        roles={roles}
      />
    );
  };

  render() {
    const { application } = this.props;
    return (
      <div>
        <SectionHeader sectionTitle={application.name} />
        <Tabs defaultActiveKey="1">
          {/* <TabPane tab="Object Types" key="1">
            Content of Tab Pane 1
          </TabPane> */}
          <TabPane tab="Roles" key="roles">
            {this.renderRoles()}
          </TabPane>
          <TabPane tab="Permissions" key="permissions">
            {this.renderPermission()}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapActionsToProps = {
  setBreadcrumbs,
  addNewPermission,
  deletePermission,
  updatePermission,
  deleteRole,
};
const mapStateToProps = state => ({
  application: state.teApps.apps.edit,
  // rolesLoading: state.teApps.applicationDetails.rolesLoading,
  permissions: state.teApps.permissions[state.teApps.apps.edit.name],
  roles: state.teApps.roles[state.teApps.apps.edit.name],
  permissionCreating: state.teApps.permissionCreating,
});

PageApplicationDetails.propTypes = {
  setBreadcrumbs: PropTypes.func.isRequired,
  updatePermission: PropTypes.func.isRequired,
  addNewPermission: PropTypes.func.isRequired,
  deletePermission: PropTypes.func.isRequired,
  deleteRole: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  application: PropTypes.object,
  history: PropTypes.object.isRequired,
  permissionCreating: PropTypes.bool,
  // rolesLoading: PropTypes.bool,
  permissions: PropTypes.array,
  roles: PropTypes.array,
};
PageApplicationDetails.defaultProps = {
  application: null,
  permissionCreating: false,
  // rolesLoading: false,
  permissions: [],
  roles: [],
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(PageApplicationDetails);
