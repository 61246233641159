import { map, find } from 'lodash';

export function usersConverter({ users, productKeys, appRoles }) {
  return (users || []).map(user => ({
    ...user,
    allRoleIds: map(productKeys, key => {
      // eslint-disable-next-line no-restricted-syntax
      for (const id of map(user.roleIds, 'roleId')) {
        if (find(appRoles[key], { _id: id })) {
          return id;
        }
      }
      return undefined;
    }),
  }));
}

export const runSerial = tasks => {
  let result = Promise.resolve();
  tasks.forEach(task => {
    result = result.then(() => task());
  });
  return result;
}