import React from 'react';
import { Form, Input, Select } from 'te-antd';

import { ORGANIZATION_TYPE } from 'constants/organization.constants';

const GeneralInformation = ({ form, formItemLayout }) => {
  const { getFieldDecorator } = form;
  return (
    <>
      <Form.Item
        label="Name"
        name="name"
        {...formItemLayout}
      >
        {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please input organization name!' }],
          })(<Input />)}
      </Form.Item>

      <Form.Item
        label="Customer Signature"
        name="customerSignature"
        {...formItemLayout}
      >
        {getFieldDecorator('customerSignature')(<Input />)}
      </Form.Item>

      <Form.Item
        label="Organization Type"
        name="type"
        {...formItemLayout}
      >
        {getFieldDecorator('organizationTypes', {
            rules: [{ required: true, message: 'Please select organization type' }],
          })(
            <Select placeholder="select...">
              {Object.keys(ORGANIZATION_TYPE).map((key) => (
                <Select.Option key={key} value={key}>{ORGANIZATION_TYPE[key]}</Select.Option>
              ))}
            </Select>
          )}
      </Form.Item>
    </>
  )
}

export default GeneralInformation;