/* eslint-disable */
/* @flow */
import { API_URL } from 'configs';

export const API_REQUEST_START = 'API_REQUEST_START';
export const API_REQUEST_FAILURE = 'API_REQUEST_FAILURE';
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS';

const API_ERROR_LOG = process.env.REACT_APP_API_ERROR_LOG === 'true';

function ApiConstructor({ token } = {}) {
  this._token = token;
}

ApiConstructor.prototype.setToken = function setToken(token) {
  this._token = token;
};

ApiConstructor.methodFactory = function methodFactory(
  builder: (
    path: string,
    body?: Object,
    header?: Object,
    { withToken?: boolean }
  ) => [string, Object] | [string, Object, Object]
) {
  return async (
    path: string,
    body?: Object,
    headers?: Object,
    {
      withToken = true,
      logError = false,
      logErrorTitle = 'API response error:',
      connectionUri,
    }: { withToken?: boolean, logError?: boolean, logErrorTitle?: string, connectionUri: string } = {}
  ) => {
    
    const [url = path, requestOpts = {}, opts = {}] = builder(
      path,
      body,
      headers,
      {
        withToken,
      }
    ); // eslint-disable-line prefer-rest-params

    const { store } = window;
    const uri =
      connectionUri ||
      `${requestOpts.method}!${url}${
        opts.rawBody && Object.keys(opts.rawBody).length > 0
          ? `(${requestOpts.body})`
          : ''
      }`;
    const actionStartPayload = {
      uri,
      url,
      options: requestOpts,
    };
    if (store) {
      store.dispatch({
        type: API_REQUEST_START,
        payload: actionStartPayload,
      });
    }
    const headersObject = {
      // 'Content-Type': 'application/json',
      ...(headers || {}),
      ...(withToken && this._token
        ? {
            Authorization: `Bearer ${this._token}`,
          }
        : {}),
    };
    const apiUrl = (url.includes('http://') || url.includes('https://')) ? url : `${API_URL}v1${url}`;
    const response = await fetch(apiUrl, {
      ...requestOpts,
      headers: Object.assign(headersObject, requestOpts.headers || {})
    });

    if (response.status >= 200 && response.status < 300) {
      let resp;
      if (response.status === 204) {
        resp = await response.text();
      } else {
        resp = response.json();
      }
      if (store) {
        store.dispatch({
          type: API_REQUEST_SUCCESS,
          payload: resp,
          meta: {
            actionPayload: actionStartPayload,
          },
        });
      }

      return resp;
    }

    let message;
    if (response.bodyUsed || response.body) {
      const responseBody = await response.json();
      message = responseBody.message; // eslint-disable-line prefer-destructuring
    }

    if (!message) {
      message = response.statusText;
    }

    if (API_ERROR_LOG && logError) {
      console.error(logErrorTitle, message);
    }

    const error = new Error(message);
    error.response = response;

    store.dispatch({
      type: API_REQUEST_FAILURE,
      payload: error,
      meta: {
        actionPayload: actionStartPayload,
      },
    });

    throw error;
  };
};

export default ApiConstructor;
