import React from 'react';
import PropTypes from 'prop-types';
import { FormItem, TEInput, Row, Col, Button } from 'te-antd';

// eslint-disable-next-line react/prefer-stateless-function
class CreateNewPermisson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      permission: {
        name: '',
        description: '',
      },
    };
  }

  // ---------------------------------------------
  // VALIDATIONS
  // ---------------------------------------------
  validatePermission = permission => {
    const { name } = permission;
    return !!name;
  };

  // ---------------------------------------------
  // EVENT HANDLER
  // ---------------------------------------------
  onStateChange = (key, val) => {
    const { permission } = this.state;
    this.setState(() => ({
      permission: {
        ...permission,
        [key]: val,
      },
      showError: false,
    }));
  };

  onSubmit = () => {
    this.setState(() => ({
      showError: true,
    }));
    const { permission } = this.state;
    const invalid = !this.validatePermission(permission);
    if (invalid) return;
    const { onSubmit } = this.props;
    if (typeof onSubmit === 'function') {
      onSubmit(permission);
      this.setState(() => ({
        permission: {
          name: '',
          description: '',
        },
        showError: false,
      }));
    }
  };

  // ---------------------------------------------
  // RENDERING
  // ---------------------------------------------
  render() {
    const { loading } = this.props;
    const { permission, showError } = this.state;
    const { name, description } = permission;
    return (
      <div>
        <h5>Create new permission</h5>
        <Row gutter={16}>
          <Col md={8}>
            <FormItem
              errorMessage={showError && !name ? 'Name is required' : ''}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TEInput
                  placeholder="Name"
                  value={name}
                  onChange={val => this.onStateChange('name', val)}
                />
              </div>
            </FormItem>
          </Col>
          <Col md={12}>
            <FormItem>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TEInput
                  placeholder="Description"
                  value={description}
                  onChange={val => this.onStateChange('description', val)}
                />
                <Button
                  loading={loading}
                  style={{ marginLeft: '16px' }}
                  onClick={this.onSubmit}
                >
                  Create new permission
                </Button>
              </div>
            </FormItem>
          </Col>
        </Row>
      </div>
    );
  }
}

CreateNewPermisson.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};
CreateNewPermisson.defaultProps = {
  onSubmit: null,
  loading: false,
};

export default CreateNewPermisson;
