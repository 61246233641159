import { asyncAction } from 'providers/utils/actionHelpers';
import { api } from 'providers/api';
import { alertOnError } from 'providers/api/api.model';
import * as types from './editingUser.actionTypes';

export const startEditingUser = ({ editingUser }) => ({
  type: types.START_EDITING_USER,
  editingUser,
});

export const stopEditingUser = () => ({
  type: types.STOP_EDITING_USER,
});

export const updateEditingUserRoles = ({ userRoles }) => ({
  type: types.EDIT_USER_ROLES,
  userRoles,
});

export const findUserByEmail = asyncAction(
  {
    type: types.FIND_USER_REQUEST,
    success: types.FIND_USER_SUCCESS,
    failure: types.FIND_USER_FAILURE,
    callback: alertOnError,
  },
  async payload => {
    const { email } = payload;
    const json = await api.get(`/users/search`, {
      emails: email,
      fields: [
        'organizationPermissions',
        'email',
        'firstName',
        'lastName',
      ].join(','),
      organizationPermissions: true,
    });
    return json;
  }
);

export const cancelFindUser = () => ({
  type: types.FIND_USER_FAILURE,
});
