// MODELS
import moment from 'moment';
import { User, AccessToken, RestoreLog, AppSettings } from 'models';
import {
  GENERAL_SETTINGS,
  USERS,
  INTEGRATION_SETTINGS,
  TEX_OBJECT_TYPE_MAPPING,
  TEX_ROOM_DATA_MODEL,
  TEX_ROOMS,
  TEX_COURSE_EVENT_DATA_MODEL,
  TEX_USERS,
  REVIEW_AND_FINALIZE,
  NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
  PRODUCT_SETTINGS,
  SHARED_USERS,
  ACCESS_TOKENS,
  RESTORE_LOGS,
  EXAM_CONFIGURATION_FIELD_INTEGRATION_OPTIONS
  // TE_PREF_OBJECT_TYPE,
} from 'constants/organization.constants';
import {
  filter,
  forEach,
  findIndex,
  remove,
  isEmpty,
  get,
  keyBy,
  groupBy
} from 'lodash';
import * as types from './newOrganization.actionTypes';
import { initialState } from './newOrganization.initialState';
import { TRANSFORM_EXAM_CONFIG_FIELD } from './newOrganization.helper';

export default (state = initialState, action) => {
  // const objectTypeFields = ['cost_center', 'course', 'course_event', 'module', 'professor', 'room'];
  // const objectTypeFields = ['course_event', 'professor', 'room'];
  switch (action.type) {
    // //////////////////////////////////////////////
    // STEP MANAGEMENT
    // //////////////////////////////////////////////
    case types.CHANGE_STEP:
      return {
        ...state,
        activeStep: action.payload.step,
      };

    // //////////////////////////////////////////////
    // INIT NEW ORGANIZATION
    // //////////////////////////////////////////////
    case types.INIT_NEW_ORGANIZATION:
      return {
        ...initialState,
        [GENERAL_SETTINGS]: initialState[GENERAL_SETTINGS],
      };

    // //////////////////////////////////////////////
    // GET ORGANIZATION DETAILS
    // //////////////////////////////////////////////
    case types.GET_ORGANIZATION_REQUEST:
      return {
        ...initialState,
        organizationLoading: true,
        [GENERAL_SETTINGS]: {
          ...state[GENERAL_SETTINGS],
          loading: true,
        },
      };

    case types.GET_ORGANIZATION_SUCCESS: {
      const { organization } = action.payload.data;
      return {
        ...state,
        organizationLoading: false,
        organizationId: action.payload.data.organization._id,
        status: {
          ...state.status,
          [GENERAL_SETTINGS]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
        },
        [GENERAL_SETTINGS]: {
          ...state[GENERAL_SETTINGS],
          ...organization,
          loading: false,
        },
      };
    }

    case types.GET_ORGANIZATION_FAILURE:
      return {
        ...initialState,
        organizationLoading: false,
        organizationId: null,
        [GENERAL_SETTINGS]: {
          ...state[GENERAL_SETTINGS],
          loading: false,
        },
      };

    // //////////////////////////////////////////////
    // START EDITING ORGANIZATION
    // //////////////////////////////////////////////
    case types.START_EDIT_ORGANIZATION:
      return {
        ...initialState,
        organizationId: action.organizationId,
      };

    // //////////////////////////////////////////////
    // GENERAL SETTINGS
    // //////////////////////////////////////////////
    case types.EDIT_GENERAL_SETTINGS:
      return {
        ...state,
        [GENERAL_SETTINGS]: {
          ...state[GENERAL_SETTINGS],
          [action.payload.key]: action.payload.value,
          hasEdits: true,
        },
      };

    case types.SAVE_GENERAL_SETTINGS_REQUEST:
      return {
        ...state,
        saving: {
          ...state.saving,
          [GENERAL_SETTINGS]: true,
        },
        [GENERAL_SETTINGS]: {
          ...state[GENERAL_SETTINGS],
          hasEdits: false,
        },
      };

    case types.SAVE_GENERAL_SETTINGS_SUCCESS:
      return {
        ...state,
        [GENERAL_SETTINGS]: {
          ...state[GENERAL_SETTINGS],
          ...action.payload.data.organization,
        },
        status: {
          ...state.status,
          [GENERAL_SETTINGS]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
        },
        saving: {
          ...state.saving,
          [GENERAL_SETTINGS]: false,
        },
        organizationId: action.payload.data.organization._id,
      };

    case types.SAVE_GENERAL_SETTINGS_FAILURE:
      return {
        ...state,
        saving: {
          ...state.saving,
          [GENERAL_SETTINGS]: false,
        },
      };

    // //////////////////////////////////////////////
    // INTEGRATION SETTINGS
    // //////////////////////////////////////////////
    case types.FETCH_INTEGRATION_SETTINGS_REQUEST:
      return {
        ...state,
        [INTEGRATION_SETTINGS]: {
          ...state[INTEGRATION_SETTINGS],
          loading: true,
        },
      };

    case types.FETCH_INTEGRATION_SETTINGS_SUCCESS: {
      const { integrationSetting } = action.payload.data;
      const { certificateSettings } = integrationSetting;
      if (!certificateSettings)
        return {
          ...state,
          [INTEGRATION_SETTINGS]: {
            ...state[INTEGRATION_SETTINGS],
            baseUrl: '',
            username: '',
            password: '',
            certificateKey: '',
            loading: false,
          },
        };

      const {
        baseUrl,
        certificateKey,
        username,
        password,
      } = certificateSettings;
      return {
        ...state,
        [INTEGRATION_SETTINGS]: {
          ...state[INTEGRATION_SETTINGS],
          baseUrl,
          username,
          password,
          certificateKey,
          loading: false,
        },
        status: {
          ...state.status,
          [INTEGRATION_SETTINGS]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
        },
      };
    }

    case types.FETCH_INTEGRATION_SETTINGS_FAILURE:
      return {
        ...state,
        [INTEGRATION_SETTINGS]: {
          ...state[INTEGRATION_SETTINGS],
          loading: false,
        },
      };

    case types.EDIT_INTEGRATION_SETTINGS:
      return {
        ...state,
        [INTEGRATION_SETTINGS]: {
          ...state[INTEGRATION_SETTINGS],
          [action.payload.key]: action.payload.value,
        },
      };

    case types.SAVE_INTEGRATION_SETTINGS_REQUEST: {
      return {
        ...state,
        saving: {
          ...state.saving,
          [INTEGRATION_SETTINGS]: true,
        },
      };
    }

    case types.SAVE_INTEGRATION_SETTINGS_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          [INTEGRATION_SETTINGS]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
        },
        saving: {
          ...state.saving,
          [INTEGRATION_SETTINGS]: false,
        },
      };

    case types.SAVE_INTEGRATION_SETTINGS_FAILURE: {
      return {
        ...state,
        saving: {
          ...state.saving,
          [INTEGRATION_SETTINGS]: false,
        },
      };
    }

    // //////////////////////////////////////////////
    // TEX OBJECT TYPE MAPPING
    // //////////////////////////////////////////////
    case types.FETCH_ORGANIZATION_OBJECT_TYPES_REQUEST:
      return {
        ...state,
        [TEX_OBJECT_TYPE_MAPPING]: {
          ...state[TEX_OBJECT_TYPE_MAPPING],
          loading: true,
        },
      };

    case types.FETCH_ORGANIZATION_OBJECT_TYPES_SUCCESS: {
      const {
        room: roomId,
        course_event: courseEventId,
        professor: professorId,
      } = action.payload.data;
      return {
        ...state,
        [TEX_OBJECT_TYPE_MAPPING]: {
          ...state[TEX_OBJECT_TYPE_MAPPING],
          roomId,
          courseEventId,
          professorId,
          loading: false,
        },
        status: {
          ...state.status,
          [TEX_OBJECT_TYPE_MAPPING]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
        },
      };
    }

    case types.FETCH_ORGANIZATION_OBJECT_TYPES_FAILURE:
      return {
        ...state,
        [TEX_OBJECT_TYPE_MAPPING]: {
          ...state[TEX_OBJECT_TYPE_MAPPING],
          loading: false,
        },
      };

    case types.EDIT_TEX_OBJECT_TYPE_MAPPING:
      return {
        ...state,
        [TEX_OBJECT_TYPE_MAPPING]: {
          ...state[TEX_OBJECT_TYPE_MAPPING],
          [action.payload.key]: action.payload.value,
        },
      };

    case types.SAVE_TEX_OBJECT_TYPE_MAPPING_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          [TEX_OBJECT_TYPE_MAPPING]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
        },
      };

    // //////////////////////////////////////////////
    // TEX ROOM DATA MODEL
    // //////////////////////////////////////////////
    case types.EDIT_TEX_ROOM_DATA_MODEL:
      return {
        ...state,
        [TEX_ROOM_DATA_MODEL]: {
          ...state[TEX_ROOM_DATA_MODEL],
          [action.payload.key]: action.payload.value,
        },
      };

    case types.SAVE_TEX_ROOM_DATA_MODEL_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          [TEX_ROOM_DATA_MODEL]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
        },
      };

    // //////////////////////////////////////////////
    // TEX ROOMS
    // //////////////////////////////////////////////
    case types.TOGGLE_ROOM_OBJECT: {
      const selIdx = state[TEX_ROOMS].findIndex(
        el => el === action.payload.teExtId
      );
      if (selIdx > -1) {
        return {
          ...state,
          [TEX_ROOMS]: [
            ...state[TEX_ROOMS].slice(0, selIdx),
            ...state[TEX_ROOMS].slice(selIdx + 1),
          ],
        };
      }
      return {
        ...state,
        [TEX_ROOMS]: [...state[TEX_ROOMS], action.payload.teExtId],
      };
    }

    case types.SELECT_ALL_ROOM_OBJECTS:
      return {
        ...state,
        [TEX_ROOMS]: [...action.payload.roomArray],
      };

    case types.DESELECT_ALL_ROOM_OBJECTS:
      return {
        ...state,
        [TEX_ROOMS]: [],
      };

    case types.SAVE_TEX_ROOMS_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          [TEX_ROOMS]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
        },
      };

    // //////////////////////////////////////////////
    // TEX COURSE EVENT DATA MODEL
    // //////////////////////////////////////////////
    case types.EDIT_TEX_COURSE_EVENT_DATA_MODEL:
      return {
        ...state,
        [TEX_COURSE_EVENT_DATA_MODEL]: {
          ...state[TEX_COURSE_EVENT_DATA_MODEL],
          [action.payload.key]: action.payload.value,
        },
      };

    case types.SAVE_TEX_COURSE_EVENT_DATA_MODEL_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          [TEX_COURSE_EVENT_DATA_MODEL]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
        },
      };

    // //////////////////////////////////////////////
    // TEX USERS
    // //////////////////////////////////////////////
    case types.ADD_TEX_USER:
      return {
        ...state,
        [TEX_USERS]: [...state[TEX_USERS], { ...action.payload }],
      };

    case types.REMOVE_TEX_USER:
      return {
        ...state,
        [TEX_USERS]: filter(
          state[TEX_USERS],
          usr => usr.userId !== action.payload.user.userId
        ),
      };

    case types.SAVE_TEX_USERS_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          [TEX_USERS]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
        },
      };

    // //////////////////////////////////////////////
    // REVIEW AND FINALIZE
    // //////////////////////////////////////////////
    case types.ACTIVATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          [REVIEW_AND_FINALIZE]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
        },
      };

    // //////////////////////////////////////////////
    // PRODUCTS SETTINGS
    // //////////////////////////////////////////////
    case types.EDIT_PRODUCTS: {
      const products = {};
      forEach(action.payload.productKeys, key => {
        products[key] = state[PRODUCT_SETTINGS].products[key] || {};
      });

      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          productKeys: action.payload.productKeys,
          products,
        },
      };
    }

    case types.EDIT_PRODUCT_SETTINGS: {
      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          products: {
            ...state[PRODUCT_SETTINGS].products,
            [action.payload.productKey]: {
              settings: {
                ...state[PRODUCT_SETTINGS].products[action.payload.productKey]
                  .settings,
                [action.payload.key]: action.payload.value,
              },
            },
          },
        },
      };
    }

    // //////////////////////////////////////////////
    // USERS
    // //////////////////////////////////////////////
    case types.UPDATE_PRODUCT_USER: {
      const updatingProduct =
        state[PRODUCT_SETTINGS].products[action.payload.productKey];
      const productUsers = updatingProduct.users || [];
      const userIndex = findIndex(
        productUsers,
        usr => usr.userId === action.payload.user.userId
      );
      if (userIndex === -1) {
        productUsers.push(action.payload.user);
      } else {
        remove(productUsers, usr => usr.userId === action.payload.user.userId);
      }

      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          products: {
            ...state[PRODUCT_SETTINGS].products,
            [action.payload.productKey]: {
              users: productUsers,
            },
          },
        },
      };
    }

    case types.UPDATE_USERS:
      return {
        ...state,
        [USERS]: {
          ...state[USERS],
          list: action.payload.users,
        },
      };

    case types.CREATE_USER_REQUEST:
    case types.UPDATE_USER_REQUEST:
    case types.DELETE_USER_REQUEST:
    case types.SAVE_USERS_REQUEST:
    case types.RESEND_INVITATION_CODE_REQUEST:
      return {
        ...state,
        saving: {
          ...state.saving,
          [USERS]: true,
        },
      };

    case types.CREATE_USER_SUCCESS: {
      const user = get(action.payload, 'data.user');
      const usersList = get(state, [USERS, 'list'], []);
      const sharedUsersList = get(state, [SHARED_USERS, 'list'], []);
      if (user.type === 'share') {
        sharedUsersList.push(user);
      } else {
        usersList.push(new User(user));
      }
      return {
        ...state,
        saving: {
          ...state.saving,
          [USERS]: false,
        },
        status: {
          ...state.status,
          [USERS]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
        },
        [USERS]: {
          ...state[USERS],
          list: [...usersList],
        },
        [SHARED_USERS]: {
          ...state[USERS],
          list: [...sharedUsersList],
        },
      };
    }

    case types.UPDATE_USER_SUCCESS: {
      let userIndex;
      const user = get(action.payload, 'data.user');
      const usersList = get(state, [USERS, 'list'], []);
      const sharedUsersList = get(state, [SHARED_USERS, 'list'], []);
      if (user.type === 'share') {
        userIndex = sharedUsersList.findIndex(item => item._id === user._id);
        sharedUsersList.splice(userIndex, 1, user);
      } else {
        userIndex = usersList.findIndex(item => item._id === user._id);
        usersList.splice(
          userIndex,
          1,
          new User({ ...user, invitations: usersList[userIndex].invitations })
        );
      }

      return {
        ...state,
        saving: {
          ...state.saving,
          [USERS]: false,
        },
        status: {
          ...state.status,
          [USERS]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
        },
        [USERS]: {
          ...state[USERS],
          list: [...usersList],
        },
        [SHARED_USERS]: {
          ...state[SHARED_USERS],
          list: [...sharedUsersList],
        },
      };
    }

    case types.DELETE_USER_SUCCESS: {
      const user = get(action.payload, 'data.user');
      const newList = get(state, [USERS, 'list']);
      const userIndex = newList.findIndex(item => item._id === user._id);
      newList.splice(userIndex, 1);
      return {
        ...state,
        saving: {
          ...state.saving,
          [USERS]: false,
        },
        status: {
          ...state.status,
          [USERS]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
        },
        [USERS]: {
          ...state[USERS],
          list: [...newList],
        },
      };
    }

    case types.SAVE_USERS_SUCCESS: {
      const { payload } = action;
      const { users } = payload;
      return {
        ...state,
        saving: {
          ...state.saving,
          [USERS]: false,
        },
        status: {
          ...state.status,
          [USERS]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
        },
        [USERS]: {
          ...state[USERS],
          list: users,
        },
      };
    }

    case types.RESEND_INVITATION_CODE_SUCCESS: {
      const { payload } = action;
      const { user } = payload;
      const foundUserIndex = findIndex(
        state[USERS].list,
        usr => usr._id === user._id
      );
      const updatedUser = new User({
        ...state[USERS].list[foundUserIndex],
        invitations: [user.invitation],
      });
      // const updatedUser = state
      const newList = state[USERS].list;
      newList[foundUserIndex] = updatedUser;
      return {
        ...state,
        saving: {
          ...state.saving,
          [USERS]: false,
        },
        [USERS]: {
          ...state[USERS],
          list: newList,
        },
      };
    }

    case types.CREATE_USER_FAILURE:
    case types.UPDATE_USER_FAILURE:
    case types.DELETE_USER_FAILURE:
    case types.SAVE_USERS_FAILURE:
    case types.RESEND_INVITATION_CODE_FAILURE:
      return {
        ...state,
        saving: {
          ...state.saving,
          [USERS]: false,
        },
      };

    case types.FETCH_USERS_REQUEST:
      return {
        ...state,
        [USERS]: {
          list: [],
          loading: true,
        },
      };

    case types.FETCH_USERS_SUCCESS: {
      const { users } = action.payload.data;

      if (!isEmpty(users)) {
        return {
          ...state,
          [USERS]: {
            list: users.map(usr => new User(usr)),
            loading: false,
          },
          status: {
            ...state.status,
            [USERS]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
            // [PRODUCT_SETTINGS]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED, // CHEAT!!!
          },
        };
      }

      return {
        ...state,
        [USERS]: {
          list: [],
          loading: false,
        },
      };
    }

    case types.FETCH_USERS_FAILURE:
      return {
        ...state,
        [USERS]: {
          list: [],
          loading: false,
        },
      };

    case types.FETCH_SHARED_USERS_REQUEST:
      return {
        ...state,
        [SHARED_USERS]: {
          ...state[SHARED_USERS],
          loading: true,
        },
      };

    case types.FETCH_SHARED_USERS_SUCCESS:
      return {
        ...state,
        [SHARED_USERS]: {
          ...state[SHARED_USERS],
          loading: false,
          list: action.payload.users,
        },
      };

    case types.FETCH_SHARED_USERS_FAILURE:
      return {
        ...state,
        [SHARED_USERS]: {
          ...state[SHARED_USERS],
          loading: false,
        },
      };

    case types.SAVE_SHARED_USERS_CONFIG_REQUEST:
      return {
        ...state,
        saving: {
          ...state.saving,
          [GENERAL_SETTINGS]: true,
        },
      };

    case types.SAVE_SHARED_USERS_CONFIG_SUCCESS:
      return {
        ...state,
        saving: {
          ...state.saving,
          [GENERAL_SETTINGS]: false,
        },
        [GENERAL_SETTINGS]: {
          ...state[GENERAL_SETTINGS],
          shareUserConfig: {
            ...action.payload.organization.shareUserConfig,
          },
        },
      };

    case types.SAVE_SHARED_USERS_CONFIG_FAILURE:
      return {
        ...state,
        saving: {
          ...state.saving,
          [GENERAL_SETTINGS]: false,
        },
      };

    // //////////////////////////////////////////////
    // PRODUCTS SETTINGS
    // //////////////////////////////////////////////

    case types.FETCH_ORGANIZATION_PRODUCTS_REQUEST:
      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          loading: true,
        },
      };

    case types.FETCH_ORGANIZATION_PRODUCTS_SUCCESS:
      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          productKeys: (action.payload.data.appSettings || [])
            .filter(item => item.isActive)
            .map(item => item.appName)
            .filter(item => item),
          loading: false,
          productSettings: (action.payload.data.appSettings || []).reduce((result, item) => {
            return {
              ...result,
              [item.appName]: new AppSettings(item)
            }
          }, {})
        },
      };

    case types.UPDATE_ORGANIZATION_ASSISTED_SCHEDULING_REQUEST:
    case types.GENERATE_TOKEN_ORGANIZATION_ASSISTED_SCHEDULING_REQUEST:
      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          loading: true,
        },
      };

    case types.UPDATE_ORGANIZATION_ASSISTED_SCHEDULING_SUCCESS:
    case types.GENERATE_TOKEN_ORGANIZATION_ASSISTED_SCHEDULING_SUCCESS:
      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          loading: false,
          productSettings: {
            ...state.PRODUCT_SETTINGS.productSettings,
            [action.payload.data.appSetting.appName]: { ...action.payload.data.appSetting }
          }
        },
      };

    case types.UPDATE_DATA_SOURCE_TYPES_SUCCESS:
      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          loading: false,
          productSettings: {
            ...state.PRODUCT_SETTINGS.productSettings,
            [action.payload.data.appSetting.appName]: new AppSettings(action.payload.data.appSetting)
          }
        },
      };

    case types.REMOVE_ORGANIZATION_PRODUCT_SUCCESS: {
      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          productKeys: state[PRODUCT_SETTINGS].productKeys.filter(
            item => item !== action.payload.appName
          ),
          loading: false,
        },
      };
    }

    case types.FETCH_ORGANIZATION_PRODUCTS_FAILURE:
      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          loading: false,
        },
      };

    case types.ADD_ORGANIZATION_PRODUCT_SUCCESS:
      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          productKeys: (state[PRODUCT_SETTINGS].productKeys || []).concat(
            action.payload.appName
          ),
          productSettings: {
            ...state.PRODUCT_SETTINGS.productSettings,
            [action.payload.appName]: new AppSettings(action.payload)
          }
        },
      };

    case types.FETCH_PRODUCTS_SETTINGS_REQUEST:
      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          loading: true,
        },
      };

    case types.FETCH_ORGANIZATION_TOKEN_REQUEST:
      return {
        ...state,
        [ACCESS_TOKENS]: {
          ...state[ACCESS_TOKENS],
          loading: true,
        },
      };

    case types.FETCH_ORGANIZATION_TOKEN_FAILURE:
      return {
        ...state,
        [ACCESS_TOKENS]: {
          ...state[ACCESS_TOKENS],
          loading: false,
        },
      };

    case types.FETCH_ORGANIZATION_TOKEN_SUCCESS:
      return {
        ...state,
        [ACCESS_TOKENS]: {
          ...state[ACCESS_TOKENS],
          tokens: keyBy(
            action.payload.data.permanentTokens
              .filter(item => item.isActive)
              .map(item => new AccessToken(item)),
            'appName'
          ),
          loading: false,
        },
      };

    case types.ADD_ORGANIZATION_TOKEN_SUCCESS: {
      const { appName } = action.payload.data.permanentToken;
      return {
        ...state,
        [ACCESS_TOKENS]: {
          ...state[ACCESS_TOKENS],
          tokens: {
            ...state[ACCESS_TOKENS].tokens,
            [appName]: new AccessToken({
              ...action.payload.data.permanentToken,
              createdAt: moment(),
            }),
          },
        },
      };
    }

    case types.UPDATE_ORGANIZATION_TOKEN_SUCCESS: {
      const { appName } = action.payload.data;
      return {
        ...state,
        [ACCESS_TOKENS]: {
          ...state[ACCESS_TOKENS],
          tokens: {
            ...state[ACCESS_TOKENS].tokens,
            [appName]: null,
          },
        },
      };
    }

    case types.FETCH_PRODUCTS_SETTINGS_SUCCESS: {
      const { integrationSetting } = action.payload.data;
      if (integrationSetting) {
        const { applicationSettings } = integrationSetting;
        if (applicationSettings) {
          const products = {};
          forEach(applicationSettings, (objectTypes, productKey) => {
            products[productKey] = products[productKey] || {};
            products[productKey].settings = {
              objectTypes: { ...objectTypes.objectTypes },
              paramsMapping: { ...objectTypes.paramsMapping },
              changeExecuted: objectTypes.changeExecuted,
            };
          });
          return {
            ...state,
            status: {
              ...state.status,
              [PRODUCT_SETTINGS]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
            },
            [PRODUCT_SETTINGS]: {
              ...state[PRODUCT_SETTINGS],
              // productKeys: Object.keys(products),
              products,
              loading: false,
            },
          };
        }
      }
      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          loading: false,
        },
      };
    }

    case types.FETCH_PRODUCT_SETTINGS_FAILURE:
      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          products: {},
          productKeys: [],
          loading: false,
        },
      };

    case types.SAVE_PRODUCT_SETTINGS_REQUEST:
      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          saving: true,
        },
      };

    case types.SAVE_PRODUCT_SETTINGS_SUCCESS: {
      const { payload } = action;
      const {
        objectTypes,
        productKey,
        paramsMapping,
        changeExecuted,
      } = payload;
      const productSettings = get(
        state[PRODUCT_SETTINGS],
        `products.${productKey}.settings`,
        {}
      );
      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          products: {
            ...state[PRODUCT_SETTINGS].products,
            [productKey]: {
              ...state[PRODUCT_SETTINGS].products[productKey],
              settings: {
                ...productSettings,
                objectTypes,
                paramsMapping,
                changeExecuted,
              },
            },
          },
          saving: false,
        },
        status: {
          ...state.status,
          [PRODUCT_SETTINGS]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
        },
      };
    }

    case types.APPLY_PRODUCT_SETTINGS_REQUEST:
      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          applying: true,
        },
      };
    case types.APPLY_PRODUCT_SETTINGS_SUCCESS: {
      const { productKey } = action.payload;
      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          applying: false,
          products: {
            ...state[PRODUCT_SETTINGS].products,
            [productKey]: {
              ...state[PRODUCT_SETTINGS].products[productKey],
              settings: {
                ...state[PRODUCT_SETTINGS].products[productKey].settings,
                changeExecuted: true,
              },
            },
          },
          saving: false,
        },
        status: {
          ...state.status,
          [PRODUCT_SETTINGS]: NEW_ORGANIZATION_STEP_STATUS_COMPLETED,
        },
      };
    }

    case types.APPLY_PRODUCT_SETTINGS_FAILURE:
      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          applying: false,
        },
      };

    case types.FETCH_RESTORE_BACKUP_LOGS_SUCCESS: {
      const list = action.payload.restoreLogs.map(item => new RestoreLog(item));
      return {
        ...state,
        [RESTORE_LOGS]: {
          list,
          mapped: groupBy(list, 'backupId')
        }
      }
    }

    case types.UPDATE_RESTORE_BACKUP_LOGS: {
      const { restoreLog } = action;
      const newList = [...state.RESTORE_LOGS.list, new RestoreLog(restoreLog)];
      return {
        ...state,
        [RESTORE_LOGS]: {
          list: newList,
          mapped: groupBy(newList, 'backupId')
        }
      }
    }
    case types.UPDATE_EXAM_IN_CORE_CONFIG_SUCCESS: {
      const { payload: { appSetting } } = action
      return {
        ...state,
        [PRODUCT_SETTINGS]: {
          ...state[PRODUCT_SETTINGS],
          productSettings: {
            ...state[PRODUCT_SETTINGS].productionSettings,
            [appSetting.appName]: appSetting
          }
        }
      }
    }
  
    case types.FETCH_INTEGRATION_CONFIGURATION_FIELD_REQUEST: {
      const { config, field } = action.payload;
      return {
        ...state,
        [EXAM_CONFIGURATION_FIELD_INTEGRATION_OPTIONS]: {
          ...state[EXAM_CONFIGURATION_FIELD_INTEGRATION_OPTIONS],
          [config]: {
            ...state[EXAM_CONFIGURATION_FIELD_INTEGRATION_OPTIONS][config],
            [field]: {
              ...state[EXAM_CONFIGURATION_FIELD_INTEGRATION_OPTIONS][config][field],
              isLoading: true,
            }
          }
        }
      }
    }

    case types.FETCH_INTEGRATION_CONFIGURATION_FIELD_FAILURE: {
      const { config, field } = action.payload;
      return {
        ...state,
        [EXAM_CONFIGURATION_FIELD_INTEGRATION_OPTIONS]: {
          ...state[EXAM_CONFIGURATION_FIELD_INTEGRATION_OPTIONS],
          [config]: {
            ...state[EXAM_CONFIGURATION_FIELD_INTEGRATION_OPTIONS][config],
            [field]: {
              options: [],
              isLoading: false,
            }
          }
        }
      }
    }

    case types.FETCH_INTEGRATION_CONFIGURATION_FIELD_SUCCESS: {
      const { config, field, data, source } = action.payload;
      return {
        ...state,
        [EXAM_CONFIGURATION_FIELD_INTEGRATION_OPTIONS]: {
          ...state[EXAM_CONFIGURATION_FIELD_INTEGRATION_OPTIONS],
          [config]: {
            ...state[EXAM_CONFIGURATION_FIELD_INTEGRATION_OPTIONS][config],
            [field]: {
              isLoading: false,
              options: data.map(TRANSFORM_EXAM_CONFIG_FIELD[`${source}__${field}`])
            }
          }
        }
      }
    }
    default:
      return state;
  }
};
