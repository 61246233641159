import { createAuthClient } from 'te-auth-app-lib';

import { asyncAction } from 'providers/utils/actionHelpers';
import { api } from 'providers/api';
import { alertOnError } from 'providers/api/api.model';
import { AUTH_DOMAIN } from '../../configs';

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
} from './authentication.actionTypes';

export const login = asyncAction(
  {
    type: LOGIN_REQUEST,
    success: LOGIN_SUCCESS,
    failure: LOGIN_FAILURE,
    callback: alertOnError,
  },
  async payload => {
    const { userName, password } = payload;

    const json = await api.post(
      '/auth/login',
      {
        email: userName,
        password,
      },
      null,
      { withToken: false }
    );

    if (json && json.data) {
      const { token } = json.data;
      const { accessToken } = token;
      localStorage.setItem('api_token', accessToken);
      api.setToken(accessToken);
    }
    return json;
  }
);

// LOGOUT
export const logout = () => {
  localStorage.removeItem('api_token');
  return {
    type: LOGOUT,
  };
};

// GET USER
export const getUser = asyncAction(
  {
    type: GET_USER_REQUEST,
    success: GET_USER_SUCCESS,
    failure: GET_USER_FAILURE,
  },
  async () => {
    const authClient = createAuthClient({
      domain: AUTH_DOMAIN,
    });

    const token = await authClient.getTokenSilently();

    if (!token) throw new Error('Token does not exist');

    api.setToken(token);

    const json = await api.get('/users/profile');
    if (json && json.status === 200) {
      return json;
    }
    throw new Error(json && json.message);
  }
);
