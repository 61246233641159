import React from 'react';
import PropTypes from 'prop-types';
import { Form, Radio } from 'te-antd';

// const COPY_USER = {
//   NOT_COPY: "Don't copy",
//   COPY_WITHOUT_ROLE: 'Copy but set to no active role',
//   COPY: 'Copy as-is'
// }

const ProductSetting = ({ appName, form, formItemLayout }) => {
  const { getFieldDecorator } = form;
  switch (appName) {
    case 'tePref':
      return (
        <Form.Item
          label="TE Preference"
          name="tePref.copyConfig"
          {...formItemLayout}
        >
          {getFieldDecorator('tePref.copyConfig', {
            initialValue: 'ONLY_FORM'
          })(
            <Radio.Group>
              <Radio value="ONLY_FORM">Only Form</Radio>
              <Radio value="RELATED_FORM">Form including form instance & recipient</Radio>
            </Radio.Group>
          )}
        </Form.Item>
      );
    // case 'teExam':
    //   return (
    //     <Form.Item
    //       label="Supervisor Management"
    //       name="teExam.supervisor"
    //       {...formItemLayout}
    //     >
    //       {getFieldDecorator('teExam.supervisor', {
    //         initialValue: 'NOT_COPY'
    //       })(
    //         <Radio.Group>
    //           {Object.keys(COPY_USER).map((key) => (
    //             <Radio key={key} value={key}>{COPY_USER[key]}</Radio>
    //           ))}
    //         </Radio.Group>
    //       )}
    //     </Form.Item>
    //   )
    default:
      return (<div />)
  }
}

ProductSetting.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  formItemLayout: PropTypes.objectOf(PropTypes.any).isRequired,
  appName: PropTypes.string.isRequired,
}

export default ProductSetting;
