import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Button, Input } from 'te-antd';

// eslint-disable-next-line react/prefer-stateless-function
class CreateTokenModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  onSubmit = e => {
    if (e) e.preventDefault();
    const { form } = this.props;
    const { validateFields } = form;
    validateFields((err, val) => {
      if (!err) {
        const { onSubmit } = this.props;
        onSubmit(val);
        this.onModalToggle(false)();
      }
    });
  };

  onModalToggle = open => () => {
    this.setState(() => ({ open }));
    const { form } = this.props;
    form.setFieldsValue({
      name: null,
    });
  };

  render() {
    const { open } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <React.Fragment>
        <Button type="primary" onClick={this.onModalToggle(true)}>
          Generate new token
        </Button>
        <Modal
          title="New Token"
          onOk={this.onSubmit}
          visible={open}
          onCancel={this.onModalToggle(false)}
          cancelText="Close"
          destroyOnClose
        >
          <Form onSubmit={this.onSubmit}>
            <Form.Item label="Name">
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: 'Required!',
                  },
                ],
              })(<Input placeholder="What's the token used for?" />)}
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

CreateTokenModal.propTypes = {
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Form.create({ name: 'createTokenModal' })(CreateTokenModal);
