import React from 'react';
import PropTypes from 'prop-types';

import { Form, Popover, Button, Input } from 'te-antd';

// eslint-disable-next-line react/prefer-stateless-function
class PopoverUserPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  onVisibleChange = () => {
    const { open } = this.state;
    const { form } = this.props;
    this.setState(() => ({
      open: !open,
    }));
    form.resetFields();
  };

  onSubmit = e => {
    const { form, onSubmit } = this.props;
    e.preventDefault();
    // eslint-disable-next-line no-unused-expressions
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (typeof onSubmit === 'function') {
          onSubmit(values.password);
        }
        this.onVisibleChange();
      }
    });
  };

  render() {
    const { open } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;

    const handleConfirmPassword = (rule, value, callback) => {
      const { getFieldValue } = form;
      const password = getFieldValue('password');
      if (password && value !== password) {
        callback('Password and confirm password does not match');
      } else {
        callback();
      }
    };
    const popoverContent = (
      <Form className="form--user-password" onSubmit={this.onSubmit}>
        <Form.Item label="Password">
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: 'Please input Password',
              },
              {
                min: 8,
                message: 'Password must be at least 8 characters',
              },
            ],
          })(<Input type="password" />)}
        </Form.Item>
        <Form.Item label="Confirm Password">
          {getFieldDecorator('confirmPassword', {
            rules: [
              {
                required: true,
                message: 'Please input Confirm password',
              },
              {
                validator: handleConfirmPassword,
              },
            ],
          })(<Input type="password" />)}
        </Form.Item>
        <Button htmlType="submit">Update</Button>
      </Form>
    );
    return (
      <Popover
        visible={open}
        onVisibleChange={this.onVisibleChange}
        content={popoverContent}
        trigger="click"
      >
        <Button size="small">Change password</Button>
      </Popover>
    );
  }
}

PopoverUserPassword.propTypes = {
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
};
PopoverUserPassword.defaultProps = {
  onSubmit: null,
};

export default Form.create({ name: 'popoverUserPassword' })(
  PopoverUserPassword
);
