export const FETCH_ADMINS_REQUEST = 'FETCH_ADMINS_REQUEST';
export const FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS';
export const FETCH_ADMINS_FAILURE = 'FETCH_ADMINS_FAILURE';

export const UPDATE_ADMIN_REQUEST = 'UPDATE_ADMIN_REQUEST';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_FAILURE = 'UPDATE_ADMIN_FAILURE';

export const RESEND_INVITATION_CODE_ADMIN = 'RESEND_INVITATION_CODE_ADMIN';
export const RESEND_INVITATION_CODE_ADMIN_SUCCESS =
  'RESEND_INVITATION_CODE_ADMIN_SUCCESS';
export const RESEND_INVITATION_CODE_ADMIN_FAILURE =
  'RESEND_INVITATION_CODE_ADMIN_FAILURE';

export const UPDATE_ADMIN_STATUS = 'UPDATE_ADMIN_STATUS';
export const UPDATE_ADMIN_STATUS_SUCCESS = 'UPDATE_ADMIN_STATUS_SUCCESS';
export const UPDATE_ADMIN_STATUS_FAILURE = 'UPDATE_ADMIN_STATUS_FAILURE';
