import { asyncAction } from 'providers/utils/actionHelpers';
import { api } from 'providers/api';
import { map, pick } from 'lodash';
import { alertOnError } from 'providers/api/api.model';

import {
  INTEGRATION_SETTINGS,
  TEX_COURSE_EVENT_DATA_MODEL,
  TEX_OBJECT_TYPE_MAPPING,
  TEX_ROOM_DATA_MODEL,
  TEX_ROOMS,
  USERS,
  PLACEHOLDER_ROLE,
} from 'constants/organization.constants';

import * as types from './newOrganization.actionTypes';
import { MAP_FETCH_INTEGRATION_DATA } from './newOrganization.helper';

// /////////////////////////////////////////////////////////////
// Steps management
// @funcs:
// changeStep
// /////////////////////////////////////////////////////////////
export const changeStep = step => ({
  type: types.CHANGE_STEP,
  payload: {
    step,
  },
});

// /////////////////////////////////////////////////////////////
// INIT
// @funcs:
// initNewOrganization
// /////////////////////////////////////////////////////////////
export const initNewOrganization = () => ({
  type: types.INIT_NEW_ORGANIZATION,
});

// /////////////////////////////////////////////////////////////
// INIT
// @funcs:
// startEditingOrganization
// /////////////////////////////////////////////////////////////
export const getOrganizationDetails = asyncAction(
  {
    type: types.GET_ORGANIZATION_REQUEST,
    success: types.GET_ORGANIZATION_SUCCESS,
    failure: types.GET_ORGANIZATION_FAILURE,
    callback: alertOnError,
  },
  async payload => {
    const json = await api.get(`/organizations/${payload.organizationId}`);
    const { callback } = payload;
    if (typeof callback === 'function') {
      callback(json.data.organization);
    }
    return json;
  }
);

export const startEditingOrganization = ({ organizationId }) => ({
  type: types.START_EDIT_ORGANIZATION,
  organizationId,
});

// /////////////////////////////////////////////////////////////
// GENERAL SETTINGS
// @funcs:
// editGeneralSettings
// saveGeneralSettings
// /////////////////////////////////////////////////////////////
export const editGeneralSettings = ({ key, value }) => ({
  type: types.EDIT_GENERAL_SETTINGS,
  payload: {
    key,
    value,
  },
});

export const saveGeneralSettings = asyncAction(
  {
    type: types.SAVE_GENERAL_SETTINGS_REQUEST,
    success: types.SAVE_GENERAL_SETTINGS_SUCCESS,
    failure: types.SAVE_GENERAL_SETTINGS_FAILURE,
    callback: alertOnError,
  },
  async (payload, meta, dispatch, getState) => {
    const storeState = getState();
    const { organizationId } = storeState.newOrganization;
    const {
      name,
      authStrategy,
      authConfig,
      primaryLanguage,
      secondaryLanguage,
      customerSignature,
      restrictAccess,
      region,
      type,
      weekNames,
    } = payload;

    let json;
    if (organizationId) {
      json = await api.put(`/organizations/${organizationId}`, {
        name,
        authStrategy,
        authConfig,
        primaryLanguage,
        secondaryLanguage,
        customerSignature,
        restrictAccess,
        region,
        type,
        weekNames
      });
    } else {
      json = await api.post('/organizations', {
        name,
        authStrategy,
        authConfig,
        primaryLanguage,
        secondaryLanguage,
        customerSignature,
        restrictAccess,
        region,
        type,
        weekNames,
      });
    }
    const { callback } = payload;
    if (typeof callback === 'function') {
      callback(json.data);
    }
    return json;
  }
);

// /////////////////////////////////////////////////////////////
// INTEGRATION SETTINGS
// @funcs:
// fetchIntegrationSettings
// editIntegrationSettings
// saveIntegrationSettings
// /////////////////////////////////////////////////////////////
export const fetchIntegrationSettings = asyncAction(
  {
    type: types.FETCH_INTEGRATION_SETTINGS_REQUEST,
    success: types.FETCH_INTEGRATION_SETTINGS_SUCCESS,
    failure: types.FETCH_INTEGRATION_SETTINGS_FAILURE,
    callback: alertOnError,
  },
  async payload => {
    const { organizationId } = payload;
    const json = await api.get(
      `/organizations/${organizationId}/certificate`,
      {},
      null,
      { logError: true, logErrorTitle: 'INTEGRATION ERROR:' }
    );
    return json;
  }
);

export const editIntegrationSettings = ({ key, value }) => ({
  type: types.EDIT_INTEGRATION_SETTINGS,
  payload: {
    key,
    value,
  },
});

export const saveIntegrationSettings = asyncAction(
  {
    type: types.SAVE_INTEGRATION_SETTINGS_REQUEST,
    success: types.SAVE_INTEGRATION_SETTINGS_SUCCESS,
    failure: types.SAVE_INTEGRATION_SETTINGS_FAILURE,
    callback: alertOnError,
  },
  async (payload, meta, dispatch, getState) => {
    const storeState = getState();
    const { organizationId } = storeState.newOrganization;
    const settings = storeState.newOrganization[INTEGRATION_SETTINGS];
    const { baseUrl, username, password, certificateKey } = settings;
    const body = {
      baseUrl,
      username,
      password,
      certificateKey,
    };

    const json = await api.put(
      `/organizations/${organizationId}/certificate`,
      body,
      null,
      { logError: true, logErrorTitle: 'INTEGRATION ERROR:' }
    );
    const { callback } = payload;
    if (typeof callback === 'function') {
      callback();
    }
    return json;
  }
);

// /////////////////////////////////////////////////////////////
// TEX OBJECT TYPE MAPPING
// @funcs:
// fetchTEXObjectTypeMapping
// editIntegrationSettings
// saveIntegrationSettings
// /////////////////////////////////////////////////////////////

export const fetchTEXObjectTypeMapping = asyncAction(
  {
    type: types.FETCH_ORGANIZATION_OBJECT_TYPES_REQUEST,
    success: types.FETCH_ORGANIZATION_OBJECT_TYPES_SUCCESS,
    failure: types.FETCH_ORGANIZATION_OBJECT_TYPES_FAILURE,
    callback: alertOnError,
  },
  async () => {
    // const storeState = getState();
    // const { organizationId } = storeState.newOrganization;
    const json = await api.get(
      `/integrations/timeedit/object-types`,
      {},
      null,
      { logError: true, logErrorTitle: 'INTEGRATION ERROR:' }
    );
    return json;
  }
);

export const editTEXObjectTypeMapping = ({ key, value }) => ({
  type: types.EDIT_TEX_OBJECT_TYPE_MAPPING,
  payload: {
    key,
    value,
  },
});

export const saveTEXObjectTypeMapping = asyncAction(
  {
    type: types.SAVE_TEX_OBJECT_TYPE_MAPPING_REQUEST,
    success: types.SAVE_TEX_OBJECT_TYPE_MAPPING_SUCCESS,
    failure: types.SAVE_TEX_OBJECT_TYPE_MAPPING_FAILURE,
    callback: alertOnError,
  },
  async (payload, meta, dispatch, getState) => {
    const storeState = getState();
    // const { organizationId } = storeState.newOrganization;
    const body = {
      room: storeState.newOrganization[TEX_OBJECT_TYPE_MAPPING].roomId,
      course_event:
        storeState.newOrganization[TEX_OBJECT_TYPE_MAPPING].courseEventId,
      professor:
        storeState.newOrganization[TEX_OBJECT_TYPE_MAPPING].professorId,
      module: 'module',
      course: 'course',
    };

    const json = await api.post(
      `/integrations/timeedit/object-types`,
      body,
      null,
      { logError: true, logErrorTitle: 'INTEGRATION ERROR:' }
    );
    return json;
  }
);

// /////////////////////////////////////////////////////////////
// TEX ROOM DATA MODEL
// @funcs:
// fetchTERoomDataModel
// editTEXRoomDataModel
// saveTEXRoomDataModel
// /////////////////////////////////////////////////////////////
// export const fetchTERoomDataModel = asyncAction(
//   {
//     type: FETCH_INTEGRATION_SETTINGS_REQUEST,
//     success: FETCH_INTEGRATION_SETTINGS_SUCCESS,
//     failure: FETCH_INTEGRATION_SETTINGS_FAILURE,
//     callback: alertOnError,
//   },
//   async (payload, meta, dispatch, getState) => {
//     // const storeState = getState();
//     // const { organizationId } = storeState.newOrganization;
//     const json = await api.get(`/integrations/timeedit/rooms/data-model`);
//     return json;
//   }
// );

export const editTEXRoomDataModel = ({ key, value }) => ({
  type: types.EDIT_TEX_ROOM_DATA_MODEL,
  payload: {
    key,
    value,
  },
});

export const saveTEXRoomDataModel = asyncAction(
  {
    type: types.SAVE_TEX_ROOM_DATA_MODEL_REQUEST,
    success: types.SAVE_TEX_ROOM_DATA_MODEL_SUCCESS,
    failure: types.SAVE_TEX_ROOM_DATA_MODEL_FAILURE,
    callback: alertOnError,
  },
  async (payload, meta, dispatch, getState) => {
    const storeState = getState();
    const {
      organizationId,
      [TEX_ROOM_DATA_MODEL]: dataModel,
    } = storeState.newOrganization;
    const body = {
      name: dataModel.name,
      description: dataModel.description,
      capacity: dataModel.capacity,
      floor: dataModel.floor,
      building: dataModel.building,
      campus: dataModel.campus,
      address: dataModel.address,
      price_per_seat_per_hour: dataModel.pricePerSeatPerHour,
      info_field_1: dataModel.infoField1,
      info_field_2: dataModel.infoField2,
      info_field_3: dataModel.infoField3,
      info_field_4: dataModel.infoField4,
      info_field_5: dataModel.infoField5,
    };

    const json = await api.post(
      `/organizations/${organizationId}/rooms/data-model`,
      body,
      null,
      { logError: true, logErrorTitle: 'INTEGRATION ERROR:' }
    );
    return json;
  }
);

// /////////////////////////////////////////////////////////////
// TEX ROOMS
// @funcs:
// toggleRoomObject
// selectAllRoomObjects
// deselectAllRoomObjects
// saveTEXRooms
// /////////////////////////////////////////////////////////////
export const toggleRoomObject = ({ teExtId }) => ({
  type: types.TOGGLE_ROOM_OBJECT,
  payload: {
    teExtId,
  },
});

export const selectAllRoomObjects = () => {
  const storeState = window.store.getState();
  const roomArray = (storeState.teRoomObjects || []).map(rObj => rObj.extid);
  return {
    type: types.SELECT_ALL_ROOM_OBJECTS,
    payload: {
      roomArray,
    },
  };
};

export const deselectAllRoomObjects = () => ({
  type: types.DESELECT_ALL_ROOM_OBJECTS,
});

export const saveTEXRooms = asyncAction(
  {
    type: types.SAVE_TEX_ROOMS_REQUEST,
    success: types.SAVE_TEX_ROOMS_SUCCESS,
    failure: types.SAVE_TEX_ROOMS_FAILURE,
    callback: alertOnError,
  },
  async (payload, meta, dispatch, getState) => {
    const storeState = getState();
    const { organizationId, [TEX_ROOMS]: rooms } = storeState.newOrganization;

    if (!rooms || rooms.length === 0) return;

    const body = {
      object_ids: rooms,
    };

    await api.post(`/organizations/${organizationId}/rooms/add`, body, null, {
      logError: true,
      logErrorTitle: 'INTEGRATION ERROR:',
    });
    // return json;
  }
);
// /////////////////////////////////////////////////////////////
// TEX COURSE EVENT DATA MODEL
// @funcs:
// editTEXCourseEventDataModel
// saveTEXCourseEventDataModel
// /////////////////////////////////////////////////////////////
export const editTEXCourseEventDataModel = ({ key, value }) => ({
  type: types.EDIT_TEX_COURSE_EVENT_DATA_MODEL,
  payload: {
    key,
    value,
  },
});

export const saveTEXCourseEventDataModel = asyncAction(
  {
    type: types.SAVE_TEX_COURSE_EVENT_DATA_MODEL_REQUEST,
    success: types.SAVE_TEX_COURSE_EVENT_DATA_MODEL_SUCCESS,
    failure: types.SAVE_TEX_COURSE_EVENT_DATA_MODEL_FAILURE,
    callback: alertOnError,
  },
  async (payload, meta, dispatch, getState) => {
    const storeState = getState();
    const {
      organizationId,
      [TEX_COURSE_EVENT_DATA_MODEL]: dataModel,
    } = storeState.newOrganization;
    const body = {
      name: dataModel.name,
      course_name: dataModel.courseName,
      course_code: dataModel.courseCode,
      department: dataModel.department,
      info_field_1: dataModel.infoField1,
      info_field_2: dataModel.infoField2,
      info_field_3: dataModel.infoField3,
      info_field_4: dataModel.infoField4,
      info_field_5: dataModel.infoField5,
    };

    const json = await api.post(
      `/organizations/${organizationId}/course-events/data-model`,
      body,
      null,
      { logError: true, logErrorTitle: 'INTEGRATION ERROR:' }
    );
    return json;
  }
);

// /////////////////////////////////////////////////////////////
// TEX USERS
// @funcs:
// addUser
// removeUser
// saveUsers
// /////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////////
// REVIEW AND FINALIZE
// @funcs:
// activateOrganization
// /////////////////////////////////////////////////////////////
export const activateOrganization = asyncAction(
  {
    type: types.ACTIVATE_ORGANIZATION_REQUEST,
    success: types.ACTIVATE_ORGANIZATION_SUCCESS,
    failure: types.ACTIVATE_ORGANIZATION_FAILURE,
    callback: alertOnError,
  },
  async (payload, meta, dispatch, getState) => {
    const storeState = getState();
    const { organizationId } = storeState.newOrganization;

    const json = await api.post(`/organizations/${organizationId}/activate`);
    return json;
  }
);

// /////////////////////////////////////////////////////////////
// EDIT PRODUCTS
// @funcs:
// editProducts
// editProductSetting
// /////////////////////////////////////////////////////////////
export const editProducts = productKeys => ({
  type: types.EDIT_PRODUCTS,
  payload: {
    productKeys,
  },
});

export const editProductSetting = ({
  productKey,
  // settingName,
  key,
  value,
}) => ({
  type: types.EDIT_PRODUCT_SETTINGS,
  payload: {
    productKey,
    // settingName,
    key,
    value,
  },
});

// /////////////////////////////////////////////////////////////
// TEX ROOMS
// @funcs:
// fetchUsers
// updateUsers
// saveUsers
// editProductUsers
// updateProductUser
// /////////////////////////////////////////////////////////////

export const fetchUsers = asyncAction(
  {
    type: types.FETCH_USERS_REQUEST,
    success: types.FETCH_USERS_SUCCESS,
    failure: types.FETCH_USERS_FAILURE,
    callback: alertOnError,
  },
  async payload => {
    const { organizationId } = payload;
    return api.get(`/organizations/${organizationId}/users`);
  }
);
export const updateUsers = users => ({
  type: types.UPDATE_USERS,
  payload: {
    users,
  },
});

export const saveUsers = asyncAction(
  {
    type: types.SAVE_USERS_REQUEST,
    success: types.SAVE_USERS_SUCCESS,
    failure: types.SAVE_USERS_FAILURE,
    callback: alertOnError,
  },
  async (payload, meta, dispatch, getState) => {
    const storeState = getState();
    const { organizationId, [USERS]: users } = storeState.newOrganization;
    const updatingUsers = map(users.list, usr => ({
      ...pick(usr, ['app', 'email', 'firstName', 'lastName']),
    }));
    await api.post(`/organizations/${organizationId}/users`, {
      users: updatingUsers,
    });
    return { users: updatingUsers };
  }
);

function orgnizationGroupsConveter(orgGroups) {
  return orgGroups && orgGroups.length ? orgGroups : [PLACEHOLDER_ROLE];
}

export const createUser = asyncAction(
  {
    type: types.CREATE_USER_REQUEST,
    success: types.CREATE_USER_SUCCESS,
    failure: types.CREATE_USER_FAILURE,
    callback: alertOnError,
  },
  async (
    {
      user: {
        firstName,
        lastName,
        email,
        type,
        language,
        organizationGroups,
        password,
        selectedUser,
      },
    },
    meta,
    dispatch,
    getState
  ) => {
    const { organizationId } = getState().newOrganization;
    const dataToUpdate = {
      firstName,
      lastName,
      email,
      username: email,
      organizationGroups: orgnizationGroupsConveter(organizationGroups),
      type,
      language,
    };
    if (selectedUser) {
      return api.post(`/users/${selectedUser}/assign-organization`, {
        organizationId,
        organizationGroups: orgnizationGroupsConveter(organizationGroups),
      });
    }
    if (type === 'share') {
      dataToUpdate.password = password;
    }
    return api.post(`/organizations/${organizationId}/users`, dataToUpdate);
  }
);

export const updateUser = asyncAction(
  {
    type: types.UPDATE_USER_REQUEST,
    success: types.UPDATE_USER_SUCCESS,
    failure: types.UPDATE_USER_FAILURE,
    callback: alertOnError,
  },
  async (
    {
      user: {
        _id,
        firstName,
        lastName,
        email,
        language,
        type,
        organizationGroups,
      },
    },
    meta,
    dispatch,
    getState
  ) => {
    const { organizationId } = getState().newOrganization;
    const dataToUpdate = {
      firstName,
      lastName,
      email,
      language,
      organizationGroups: orgnizationGroupsConveter(organizationGroups),
      type,
    };
    return api.put(
      `/organizations/${organizationId}/users/${_id}`,
      dataToUpdate
    );
  }
);

export const activeUser = asyncAction(
  {
    type: types.UPDATE_USER_REQUEST,
    success: types.UPDATE_USER_SUCCESS,
    failure: types.UPDATE_USER_FAILURE,
    callback: alertOnError,
  },
  async ({ organizationId, userId }) => {
    return api.put(`/organizations/${organizationId}/users/${userId}/active`);
  }
);

export const deactiveUser = asyncAction(
  {
    type: types.UPDATE_USER_REQUEST,
    success: types.UPDATE_USER_SUCCESS,
    failure: types.UPDATE_USER_FAILURE,
    callback: alertOnError,
  },
  async ({ organizationId, userId }) => {
    return api.put(`/organizations/${organizationId}/users/${userId}/deactive`);
  }
);

export const updateUserPassword = asyncAction(
  {
    type: types.UPDATE_USER_REQUEST,
    success: types.UPDATE_USER_SUCCESS,
    failure: types.UPDATE_USER_FAILURE,
    callback: alertOnError,
  },
  async (
    {
      user: {
        _id,
        // firstName,
        // lastName,
        // email,
        // type,
        // isActive,
        organizationGroups,
        password,
      },
    },
    meta,
    dispatch,
    getState
  ) => {
    const { organizationId } = getState().newOrganization;
    return api.put(`/organizations/${organizationId}/users/${_id}`, {
      password,
      organizationGroups,
    });
  }
);

export const deleteUser = asyncAction(
  {
    type: types.DELETE_USER_REQUEST,
    success: types.DELETE_USER_SUCCESS,
    failure: types.DELETE_USER_FAILURE,
    callback: alertOnError,
  },
  async ({ organizationId, user: { _id } }) => {
    await api.delete(`/organizations/${organizationId}/users/${_id}`);
    return { data: { user: { _id } } };
  }
);

export const resendInvitationCode = asyncAction(
  {
    type: types.RESEND_INVITATION_CODE_REQUEST,
    success: types.RESEND_INVITATION_CODE_SUCCESS,
    failure: types.RESEND_INVITATION_CODE_FAILURE,
    callback: alertOnError,
  },
  async ({ userId }, meta, dispatch, getState) => {
    const { organizationId } = getState().newOrganization;
    const json = await api.post(
      `/organizations/${organizationId}/users/${userId}/resend`
    );
    return {
      user: {
        _id: userId,
        invitation: json.data.invitation,
      },
    };
  }
);

export const updateProductUser = ({ productKey, user }) => ({
  type: types.UPDATE_PRODUCT_USER,
  payload: {
    productKey,
    user,
  },
});

export const editProductUsers = ({ productKey, users }) => ({
  type: types.EDIT_PRODUCT_USERS,
  payload: {
    productKey,
    users,
  },
});

export const fetchSharedUsers = asyncAction(
  {
    type: types.FETCH_SHARED_USERS_REQUEST,
    success: types.FETCH_SHARED_USERS_SUCCESS,
    failure: types.FETCH_SHARED_USERS_FAILURE,
    callback: alertOnError,
  },
  async payload => {
    const { organizationId } = payload;
    const json = await api.get(`/organizations/${organizationId}/users/share/`);
    return { users: json.data.users };
  }
);

export const saveSharedUsersConfig = asyncAction(
  {
    type: types.SAVE_SHARED_USERS_CONFIG_REQUEST,
    success: types.SAVE_SHARED_USERS_CONFIG_SUCCESS,
    failure: types.SAVE_SHARED_USERS_CONFIG_FAILURE,
    callback: alertOnError,
  },
  async (payload, meta, dispatch, getState) => {
    const { organizationId } = getState().newOrganization;
    const json = await api.post(
      `/organizations/${organizationId}/users/share/config`,
      {
        ...payload.shareUserConfig,
      }
    );
    return json.data;
  }
);

// ORGANIZATION PRODUCTS
export const fetchOrganizationProducts = asyncAction(
  {
    type: types.FETCH_ORGANIZATION_PRODUCTS_REQUEST,
    success: types.FETCH_ORGANIZATION_PRODUCTS_SUCCESS,
    failure: types.FETCH_ORGANIZATION_PRODUCTS_FAILURE,
    callback: alertOnError,
  },
  async payload => {
    const { organizationId } = payload;
    const json = await api.get(`/organizations/${organizationId}/app-settings`);
    return json;
  }
);

export const updateAssistedScheduling = asyncAction(
  {
    type: types.UPDATE_ORGANIZATION_ASSISTED_SCHEDULING_REQUEST,
    success: types.UPDATE_ORGANIZATION_ASSISTED_SCHEDULING_SUCCESS,
    failure: types.UPDATE_ORGANIZATION_ASSISTED_SCHEDULING_FAILURE,
    callback: alertOnError,
  },
  async (payload, meta, dispatch, getState) => {
    const storeState = getState();
    const { organizationId } = storeState.newOrganization;
    const { appId, assistedScheduling } = payload;
    const json = await api.post(
      `/organizations/${organizationId}/app-settings/${appId}/assisted-scheduling`,
      assistedScheduling
    );
    return json;
  }
);

export const generateTokenForAssistedScheduling = asyncAction(
  {
    type: types.GENERATE_TOKEN_ORGANIZATION_ASSISTED_SCHEDULING_REQUEST,
    success: types.GENERATE_TOKEN_ORGANIZATION_ASSISTED_SCHEDULING_SUCCESS,
    failure: types.GENERATE_TOKEN_ORGANIZATION_ASSISTED_SCHEDULING_FAILURE,
    callback: alertOnError,
  },
  async (payload, meta, dispatch, getState) => {
    const storeState = getState();
    const { organizationId } = storeState.newOrganization;
    const { appId, name } = payload;
    const json = await api.post(
      `/organizations/${organizationId}/app-settings/${appId}/assisted-scheduling/token`,
      name
    );
    return json;
  }
);

export const addOrganizationProduct = asyncAction(
  {
    type: types.ADD_ORGANIZATION_PRODUCT_REQUEST,
    success: types.ADD_ORGANIZATION_PRODUCT_SUCCESS,
    failure: types.ADD_ORGANIZATION_PRODUCT_FAILURE,
    callback: alertOnError,
  },
  async (payload, meta, dispatch, getState) => {
    const storeState = getState();
    const { organizationId } = storeState.newOrganization;
    const { appId } = payload;
    const { data: { appSetting } } = await api.post(
      `/organizations/${organizationId}/app-settings/${appId}/active`
    );
    return appSetting;
  }
);

export const removeOrganizationProduct = asyncAction(
  {
    type: types.REMOVE_ORGANIZATION_PRODUCT_REQUEST,
    success: types.REMOVE_ORGANIZATION_PRODUCT_SUCCESS,
    failure: types.REMOVE_ORGANIZATION_PRODUCT_FAILURE,
    callback: alertOnError,
  },
  async (payload, meta, dispatch, getState) => {
    const storeState = getState();
    const { organizationId } = storeState.newOrganization;
    const { appId, appName } = payload;
    await api.post(
      `/organizations/${organizationId}/app-settings/${appId}/deactive`
    );
    return { appName };
  }
);

export const fetchOrganizationTokens = asyncAction(
  {
    type: types.FETCH_ORGANIZATION_TOKEN_REQUEST,
    success: types.FETCH_ORGANIZATION_TOKEN_SUCCESS,
    failure: types.FETCH_ORGANIZATION_TOKEN_FAILURE,
    callback: alertOnError,
  },
  async payload => {
    const { organizationId } = payload;

    const json = await api.get('/permanent-token', {
      organizationId,
    });

    return json;
  }
);

export const addOrganizationToken = asyncAction(
  {
    type: types.ADD_ORGANIZATION_TOKEN_REQUEST,
    success: types.ADD_ORGANIZATION_TOKEN_SUCCESS,
    failure: types.ADD_ORGANIZATION_TOKEN_FAILURE,
    callback: alertOnError,
  },
  async payload => {
    const json = api.post('/permanent-token', payload);
    return json;
  }
);

export const deactivateOrganizationToken = asyncAction(
  {
    type: types.UPDATE_ORGANIZATION_TOKEN_REQUEST,
    success: types.UPDATE_ORGANIZATION_TOKEN_SUCCESS,
    failure: types.UPDATE_ORGANIZATION_TOKEN_FAILURE,
    callback: alertOnError,
  },
  async ({ appName, _id }) => {
    await api.patch(`/permanent-token/${_id}/deactivate`);
    return {
      data: {
        appName,
      },
    };
  }
);

// PRODUCTS SETTINGS
export const fetchProductsSettings = asyncAction(
  {
    type: types.FETCH_PRODUCTS_SETTINGS_REQUEST,
    success: types.FETCH_PRODUCTS_SETTINGS_SUCCESS,
    failure: types.FETCH_PRODUCTS_SETTINGS_FAILURE,
    callback: alertOnError,
  },
  async payload => {
    const { organizationId } = payload;
    const json = await api.get(
      `/organizations/${organizationId}/object-types`,
      {},
      null,
      { logError: true, logErrorTitle: 'INTEGRATION ERROR:' }
    );
    return json;
  }
);

export const fetchProductSettings = asyncAction(
  {
    type: types.FETCH_PRODUCT_SETTINGS_REQUEST,
    success: types.FETCH_PRODUCT_SETTINGS_SUCCESS,
    failure: types.FETCH_PRODUCT_SETTINGS_FAILURE,
    callback: alertOnError,
  },
  async (payload, meta, dispatch, getState) => {
    const { organizationId } = getState().newOrganization;
    const { appName } = payload;
    const json = await api.get(
      `/organizations/${organizationId}/service/${appName}/object-types`
    );
    return json;
  }
);

export const fetchRestoreLogs = asyncAction(
  {
    type: types.FETCH_RESTORE_BACKUP_LOGS_REQUEST,
    success: types.FETCH_RESTORE_BACKUP_LOGS_SUCCESS,
    failure: types.FETCH_RESTORE_BACKUP_LOGS_FAILURE,
    callback: alertOnError,
  },
  async (payload) => {
    const { organizationId } = payload;
    const json = await api.get(
      `/organizations/${organizationId}/backup/logs`
    );
    return json.data;
  }
);

export const updateDataSourceTypes = asyncAction(
  {
    type: types.UPDATE_DATA_SOURCE_TYPES_REQUEST,
    success: types.UPDATE_DATA_SOURCE_TYPES_SUCCESS,
    failure: types.UPDATE_DATA_SOURCE_TYPES_FAILURE,
    callback: alertOnError,
  },
  async payload => {
    const { organizationId, appId, dataSourceTypes } = payload;
    const json = await api.put(`/organizations/${organizationId}/app-settings/${appId}/data-source-type`, dataSourceTypes);
    return json;
  }
);

export const updateExamInCoreConfiguration = asyncAction(
  {
    type: types.UPDATE_EXAM_IN_CORE_CONFIG_REQUEST,
    success: types.UPDATE_EXAM_IN_CORE_CONFIG_SUCCESS,
    failure: types.UPDATE_EXAM_IN_CORE_CONFIG_FAILURE,
    callback: alertOnError,
  },
  async payload => {
    const { organizationId, appId, examInCore } = payload;
    const { data } = await api.put(`/organizations/${organizationId}/app-settings/${appId}`, { examInCore });
    return data;
  }
);

export const fetchIntegrationForConfigurationField = asyncAction(
  {
    type: types.FETCH_INTEGRATION_CONFIGURATION_FIELD_REQUEST,
    success: types.FETCH_INTEGRATION_CONFIGURATION_FIELD_SUCCESS,
    failure: types.FETCH_INTEGRATION_CONFIGURATION_FIELD_FAILURE,
    callback: alertOnError,
  },
  async payload => {
    const { config, source, field, options } = payload;
    const url = MAP_FETCH_INTEGRATION_DATA[`${source}__${field}`](options);
    const { data } = await api.get(url);
    return { data, source, config, field };
  }
);
