/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { Switch, Form, Icon, Typography, Tooltip, Row, Col } from 'te-antd';
import { ObjectTypesGetter } from 'te-integration-lib';
import { PRODUCT_SETTINGS, EXAM_CONFIGURATION_FIELD_MODE } from 'constants/organization.constants';
import {
  updateExamInCoreConfiguration,
} from '../../../../../providers/newOrganization/newOrganization.actions';
import './ExamInCoreConfiguration.scss';
import ExamInCoreConfigurationDropdown from './ExamInCoreConfigurationDropdown';
import { EXAM_CONFIG_FIELD_DROPDOWN_TYPE, renderDefaultSelectField } from './helpers';

const updateRelatedExamField = ({ type, value }) => {
  switch (type) {
    case EXAM_CONFIG_FIELD_DROPDOWN_TYPE.SOURCE:
      return {
        [type]: value,
        mode: undefined,
        field: undefined
      }
    case EXAM_CONFIG_FIELD_DROPDOWN_TYPE.MODE:
      return {
        [type]: value,
        field: undefined,
      }
    default:
      return {
        [type]: value,
      }
  }
}

const formItemLayout = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 6 },
    style: {
      textAlign: 'left'
    },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const iconStyle = {
  icon: {
    fontSize: '23px',
    position: 'relative',
    bottom: '3px'
  },
  warning: {
    color: '#faad14'
  },
  success: {
    color: '#28a745'
  }
}

const appName = 'teExam';

const ExamInCoreConfiguration = ({
  isLoading,
  updateExamInCoreConfigurationAction,
  appSettings,
  form: { getFieldDecorator }
}) => {
  const { examInCore } = appSettings;
  const [isExamIdConfigured, setExamIdConfigured] = useState(false);

  const handleOnChange = (value) => {
    updateExamInCoreConfigurationAction({
      organizationId: appSettings.organizationId,
      appId: appSettings.appId,
      examInCore: {
        ...examInCore,
        isEnabled: value
      }
    })
  }

  const handleConfigureField = ({ config, type, value}) => {
    updateExamInCoreConfigurationAction({
      organizationId: appSettings.organizationId,
      appId: appSettings.appId,
      examInCore: {
        ...examInCore,
        configureFields: {
          ...examInCore.configureFields,
          [config]: {
            ...examInCore.configureFields[config],
            ...updateRelatedExamField({ type, value })
          }
        }
      }
    })
  }

  const handleLoadExamIdConfigured = ({ payload = undefined }) => {
    if (!payload) return false;
    const examIdField = payload.find(config => config.appFieldId === 'exam_id') || {};
    return setExamIdConfigured(!!examIdField.fieldExt)
  }

  const examTitle = _.get(examInCore, 'configureFields.examTitle', {})
  const student = _.get(examInCore, 'configureFields.student', {})

  return (
    <div className="exam-in-core-configuration-container">
      <ObjectTypesGetter
        appName={appName}
        objectPath="reservationSettings"
        onInitialized={(results) => { handleLoadExamIdConfigured(results)}}
      />
      <Typography.Title level={4}>TE Exam in TE Core</Typography.Title>
      <Form {...formItemLayout} colon={false}>
        <Form.Item label="Enable TE Exam in TE Core">
          {getFieldDecorator('isEnabled', {
            valuePropName: 'checked',
            initialValue: _.get(examInCore, 'isEnabled', false),
          })(<Switch onChange={handleOnChange} />)}
        </Form.Item>
        <Form.Item label="Exam ID field is configured">
          {
            isExamIdConfigured
              ? <Icon type="check-circle" style={{...iconStyle.icon, ...iconStyle.success }} />
              : (
                <Tooltip title="Exam ID hasn't been configured">
                  <Icon type="info-circle" style={{...iconStyle.icon, ...iconStyle.warning }} />
                </Tooltip>
              )
          }
        </Form.Item>
        <Typography.Title level={4}>Configure fields</Typography.Title>
        <Row gutter={16} className="configure-field-content">
          <Col span={3} className="exam-title-label"><p>Exam title:</p></Col>
          <Col span={7}>
            <p>Select source for Field:</p>
            <ExamInCoreConfigurationDropdown
              type={EXAM_CONFIG_FIELD_DROPDOWN_TYPE.SOURCE}
              config="examTitle"
              value={examTitle.source}
              options={renderDefaultSelectField}
              onChangeDropdown={handleConfigureField}
            />
          </Col>
          <Col span={7}>
            <p>Select type or reservation mode:</p>
            <ExamInCoreConfigurationDropdown
              type={EXAM_CONFIG_FIELD_DROPDOWN_TYPE.MODE}
              config="examTitle"
              dependValue={examTitle.source}
              source={examTitle.source}
              value={examTitle.mode}
              organizationId={appSettings.organizationId}
              onChangeDropdown={handleConfigureField}
            />
          </Col>
          <Col span={7}>
            <p>Select fields:</p>
            <ExamInCoreConfigurationDropdown
              type={EXAM_CONFIG_FIELD_DROPDOWN_TYPE.FIELD}
              config="examTitle"
              source={examTitle.source}
              dependValue={examTitle.mode}
              value={examTitle.field}
              organizationId={appSettings.organizationId}
              onChangeDropdown={handleConfigureField}
            />
          </Col>
        </Row>
        <Row gutter={16} className="student-content">
          <Col span={3} className="student-label"><p>Student:</p></Col>
          <Col span={7}>
            <ExamInCoreConfigurationDropdown
              type={EXAM_CONFIG_FIELD_DROPDOWN_TYPE.SOURCE}
              config="student"
              options={renderDefaultSelectField}
              value={student.source}
              onChangeDropdown={handleConfigureField}
            />
          </Col>
          <Col span={7}>
            <ExamInCoreConfigurationDropdown
              type={EXAM_CONFIG_FIELD_DROPDOWN_TYPE.MODE}
              config="student"
              dependValue={student.source}
              value={student.mode}
              source={student.source}
              organizationId={appSettings.organizationId}
              onChangeDropdown={handleConfigureField}
            />
          </Col>
          <Col span={7}>
            <ExamInCoreConfigurationDropdown
              type={EXAM_CONFIG_FIELD_DROPDOWN_TYPE.FIELD}
              config="student"
              dependValue={student.mode}
              value={student.field}
              source={student.source}
              organizationId={appSettings.organizationId}
              onChangeDropdown={handleConfigureField}
            />
          </Col>
        </Row>
      </Form>
    </div>
  )
}

ExamInCoreConfiguration.propTypes = {
  appSettings: PropTypes.shape({
    examInCore: PropTypes.shape({
      isEnabled: PropTypes.bool,
    })
  }),
  updateExamInCoreConfigurationAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

ExamInCoreConfiguration.defaultProps = {
  appSettings: {
    examInCore: {
      isEnabled: false,
    }
  },
  isLoading: false
}

const mapStateToProps = ({ newOrganization }) => ({
  appSettings: _.get(newOrganization, `[${PRODUCT_SETTINGS}].productSettings[${appName}]`),
  isLoading: _.get(newOrganization, `[${PRODUCT_SETTINGS}].loading`)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateExamInCoreConfigurationAction: updateExamInCoreConfiguration,
}, dispatch);

const WrappedForm = Form.create({ name: 'examInCoreConfiguration' })(ExamInCoreConfiguration);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedForm);