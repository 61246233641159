import { combineReducers } from 'redux';
import UserManagement from 'te-components/dist/UserManagement'

import authentication from 'providers/authentication/authentication.reducer';
import organizations from 'providers/organizations/organizations.reducer';
import newOrganization from 'providers/newOrganization/newOrganization.reducer';
import teObjectTypes from 'providers/teObjectTypes/teObjectTypes.reducer';
import teRoomObjectFields from 'providers/teRoomObjectFields/teRoomObjectFields.reducer';
import teRoomObjects from 'providers/teRoomObjects/teRoomObjects.reducer';
import teCourseEventObjectFields from 'providers/teCourseEventObjectFields/teCourseEventObjectFields.reducer';
import teApps from 'providers/teApps/teApps.reducer';
import templateForms from 'providers/templateForms/templateForms.reducer';
import users from 'providers/users/users.reducer';
import organizationGroups from 'providers/organizationGroups/organizationGroups.reducer';
import editingUser from 'providers/newOrganization/editingUser/editingUser.reducer';
import backup from 'providers/backup/backup.reducer';
import { globalUI } from 'providers/globalUI/globalUI.reducer';
import filter from 'components/FreeTextFilter/FreeTextFilter.reducer';

const appReducer = combineReducers({
  authentication,
  organizations,
  newOrganization,
  teObjectTypes,
  teRoomObjectFields,
  teRoomObjects,
  teCourseEventObjectFields,
  globalUI,
  teApps,
  templateForms,
  users,
  organizationGroups,
  editingUser,
  backup,
  filter,
  userManagement: UserManagement.reducer
});

export default (state, action) => {
  // eslint-disable-next-line no-param-reassign
  if (action.type === 'LOGOUT') state = undefined;

  return appReducer(state, action);
};
