import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// ACTIONS
import { setBreadcrumbs } from 'providers/globalUI/globalUI.actions';
import { createNewRole } from 'providers/teApps/teApps.actions';

// COMPONENTS
import { SectionHeader } from 'components/PageLayout/PageLayout';
import RoleDetails from './Components/Roles/RoleDetails';

// eslint-disable-next-line react/prefer-stateless-function
class PageCreateNewRule extends React.Component {
  roleDetails = null;

  componentWillMount() {
    const { setBreadcrumbs, application } = this.props;
    setBreadcrumbs([
      {
        path: '../../',
        label: 'Applications',
      },
      {
        path: `./../${application._id}`,
        label: application.name,
      },
      {
        path: './',
        label: 'Create new role',
      },
    ]);
  }

  // ------------------------------------------------------------------------
  // EVENT HANDLER
  // ------------------------------------------------------------------------
  onSubmit = values => {
    const { createNewRole, history, match, application } = this.props;
    const { params } = match;
    const { name, description, permissions, title } = values;

    createNewRole({
      role: { app: application.name, name, description, permissions, title },
      callback: () => {
        history.push(`/applications/${params.applicationId}`);
      },
    });
  };

  // ------------------------------------------------------------------------
  // RENDERING
  // ------------------------------------------------------------------------
  render() {
    const { permissions, roleCreating } = this.props;
    return (
      <div>
        <SectionHeader sectionTitle="Create new role" />
        <RoleDetails
          submitButton={{
            loading: roleCreating,
            label: 'Create',
          }}
          onSubmit={this.onSubmit}
          permissions={permissions}
        />
      </div>
    );
  }
}

const mapActionsToProps = {
  setBreadcrumbs,
  createNewRole,
};

const mapStateToProps = state => ({
  application: state.teApps.apps.edit,
  roleCreating: state.teApps.roleCreating,
  permissions: state.teApps.permissions,
});

PageCreateNewRule.propTypes = {
  application: PropTypes.object,
  match: PropTypes.object,
  setBreadcrumbs: PropTypes.func.isRequired,
  createNewRole: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  permissions: PropTypes.object,
  roleCreating: PropTypes.bool,
};

PageCreateNewRule.defaultProps = {
  application: {},
  match: {},
  permissions: [],
  roleCreating: false,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(PageCreateNewRule);
