// STEP MANAGEMENT
export const CHANGE_STEP = 'CHANGE_STEP';
// INIT
export const INIT_NEW_ORGANIZATION = 'INIT_NEW_ORGANIZATION';
export const START_EDIT_ORGANIZATION = 'START_EDIT_ORGANIZATION';

// GENERAL SETTINGS
export const EDIT_GENERAL_SETTINGS = 'EDIT_GENERAL_SETTINGS';
export const SAVE_GENERAL_SETTINGS_REQUEST = 'SAVE_GENERAL_SETTINGS_REQUEST';
export const SAVE_GENERAL_SETTINGS_SUCCESS = 'SAVE_GENERAL_SETTINGS_SUCCESS';
export const SAVE_GENERAL_SETTINGS_FAILURE = 'SAVE_GENERAL_SETTINGS_FAILURE';

// INTEGRATION SETTINGS
export const EDIT_INTEGRATION_SETTINGS = 'EDIT_INTEGRATION_SETTINGS';
export const SAVE_INTEGRATION_SETTINGS_REQUEST =
  'SAVE_INTEGRATION_SETTINGS_REQUEST';
export const SAVE_INTEGRATION_SETTINGS_SUCCESS =
  'SAVE_INTEGRATION_SETTINGS_SUCCESS';
export const SAVE_INTEGRATION_SETTINGS_FAILURE =
  'SAVE_INTEGRATION_SETTINGS_FAILURE';

export const FETCH_INTEGRATION_SETTINGS_REQUEST =
  'FETCH_INTEGRATION_SETTINGS_REQUEST';
export const FETCH_INTEGRATION_SETTINGS_SUCCESS =
  'FETCH_INTEGRATION_SETTINGS_SUCCESS';
export const FETCH_INTEGRATION_SETTINGS_FAILURE =
  'FETCH_INTEGRATION_SETTINGS_FAILURE';

// TEX OBJECT TYPE MAPPING
export const EDIT_TEX_OBJECT_TYPE_MAPPING = 'EDIT_TEX_OBJECT_TYPE_MAPPING';
export const SAVE_TEX_OBJECT_TYPE_MAPPING_REQUEST =
  'SAVE_TEX_OBJECT_TYPE_MAPPING_REQUEST';
export const SAVE_TEX_OBJECT_TYPE_MAPPING_SUCCESS =
  'SAVE_TEX_OBJECT_TYPE_MAPPING_SUCCESS';
export const SAVE_TEX_OBJECT_TYPE_MAPPING_FAILURE =
  'SAVE_TEX_OBJECT_TYPE_MAPPING_FAILURE';

// TEX ROOM DATA MODEL
export const EDIT_TEX_ROOM_DATA_MODEL = 'EDIT_TEX_ROOM_DATA_MODEL';
export const SAVE_TEX_ROOM_DATA_MODEL_REQUEST =
  'SAVE_TEX_ROOM_DATA_MODEL_REQUEST';
export const SAVE_TEX_ROOM_DATA_MODEL_SUCCESS =
  'SAVE_TEX_ROOM_DATA_MODEL_SUCCESS';
export const SAVE_TEX_ROOM_DATA_MODEL_FAILURE =
  'SAVE_TEX_ROOM_DATA_MODEL_FAILURE';

// TEX ROOM OBJECTS
export const TOGGLE_ROOM_OBJECT = 'TOGGLE_ROOM_OBJECT';
export const SELECT_ALL_ROOM_OBJECTS = 'SELECT_ALL_ROOM_OBJECTS';
export const DESELECT_ALL_ROOM_OBJECTS = 'DESELECT_ALL_ROOM_OBJECTS';
export const SAVE_TEX_ROOMS_REQUEST = 'SAVE_TEX_ROOMS_REQUEST';
export const SAVE_TEX_ROOMS_SUCCESS = 'SAVE_TEX_ROOMS_SUCCESS';
export const SAVE_TEX_ROOMS_FAILURE = 'SAVE_TEX_ROOMS_FAILURE';

// TEX COURSE EVENT DATA MODEL
export const EDIT_TEX_COURSE_EVENT_DATA_MODEL =
  'EDIT_TEX_COURSE_EVENT_DATA_MODEL';
export const SAVE_TEX_COURSE_EVENT_DATA_MODEL_REQUEST =
  'SAVE_TEX_COURSE_EVENT_DATA_MODEL_REQUEST';
export const SAVE_TEX_COURSE_EVENT_DATA_MODEL_SUCCESS =
  'SAVE_TEX_COURSE_EVENT_DATA_MODEL_SUCCESS';
export const SAVE_TEX_COURSE_EVENT_DATA_MODEL_FAILURE =
  'SAVE_TEX_COURSE_EVENT_DATA_MODEL_FAILURE';

// TEX USERS
export const ADD_TEX_USER = 'ADD_TEX_USER';
export const REMOVE_TEX_USER = 'REMOVE_TEX_USER';
export const SAVE_TEX_USERS_REQUEST = 'SAVE_TEX_USERS_REQUEST';
export const SAVE_TEX_USERS_SUCCESS = 'SAVE_TEX_USERS_SUCCESS';
export const SAVE_TEX_USERS_FAILURE = 'SAVE_TEX_USERS_FAILURE';

// REVIEW AND FINALIZE
export const ACTIVATE_ORGANIZATION_REQUEST = 'ACTIVATE_ORGANIZATION_REQUEST';
export const ACTIVATE_ORGANIZATION_SUCCESS = 'ACTIVATE_ORGANIZATION_SUCCESS';
export const ACTIVATE_ORGANIZATION_FAILURE = 'ACTIVATE_ORGANIZATION_FAILURE';

export const GET_ORGANIZATION_REQUEST = 'GET_ORGANIZATION_REQUEST';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_FAILURE = 'GET_ORGANIZATION_FAILURE';

// FETCH ORGANIZATION INTEGRATION
export const FETCH_TE_ORGANIZATION_DATA_MODEL_REQUEST =
  'FETCH_TE_ORGANIZATION_DATA_MODEL_REQUEST';
export const FETCH_TE_ORGANIZATION_DATA_MODEL_SUCCESS =
  'FETCH_TE_ORGANIZATION_DATA_MODEL_SUCCESS';
export const FETCH_TE_ORGANIZATION_DATA_MODEL_FAILURE =
  'FETCH_TE_ORGANIZATION_DATA_MODEL_FAILURE';

// FETCH ORGANIZATION OBJECT TYPES
export const FETCH_ORGANIZATION_OBJECT_TYPES_REQUEST =
  'FETCH_ORGANIZATION_OBJECT_TYPES_REQUEST';
export const FETCH_ORGANIZATION_OBJECT_TYPES_SUCCESS =
  'FETCH_ORGANIZATION_OBJECT_TYPES_SUCCESS';
export const FETCH_ORGANIZATION_OBJECT_TYPES_FAILURE =
  'FETCH_ORGANIZATION_OBJECT_TYPES_FAILURE';

// PRODUCTS
export const EDIT_PRODUCTS = 'EDIT_PRODUCTS';
export const EDIT_PRODUCT_SETTINGS = 'EDIT_PRODUCT_SETTINGS';
export const EDIT_PRODUCT_USERS = 'EDIT_PRODUCT_USERS';
export const UPDATE_PRODUCT_USER = 'UPDATE_PRODUCT_USER';

export const FETCH_ORGANIZATION_PRODUCTS_REQUEST =
  'FETCH_ORGANIZATION_PRODUCTS_REQUEST';
export const FETCH_ORGANIZATION_PRODUCTS_SUCCESS =
  'FETCH_ORGANIZATION_PRODUCTS_SUCCESS';
export const FETCH_ORGANIZATION_PRODUCTS_FAILURE =
  'FETCH_ORGANIZATION_PRODUCTS_FAILURE';

export const ADD_ORGANIZATION_PRODUCT_REQUEST =
  'ADD_ORGANIZATION_PRODUCT_REQUEST';
export const ADD_ORGANIZATION_PRODUCT_SUCCESS =
  'ADD_ORGANIZATION_PRODUCT_SUCCESS';
export const ADD_ORGANIZATION_PRODUCT_FAILURE =
  'ADD_ORGANIZATION_PRODUCT_FAILURE';

export const REMOVE_ORGANIZATION_PRODUCT_REQUEST =
  'REMOVE_ORGANIZATION_PRODUCT_REQUEST';
export const REMOVE_ORGANIZATION_PRODUCT_SUCCESS =
  'REMOVE_ORGANIZATION_PRODUCT_SUCCESS';
export const REMOVE_ORGANIZATION_PRODUCT_FAILURE =
  'FETCH_ORGANIZATION_PRODUCT_FAILURE';

export const FETCH_ORGANIZATION_TOKEN_REQUEST =
  'FETCH_ORGANIZATION_TOKEN_REQUEST';
export const FETCH_ORGANIZATION_TOKEN_SUCCESS =
  'FETCH_ORGANIZATION_TOKEN_SUCCESS';
export const FETCH_ORGANIZATION_TOKEN_FAILURE =
  'FETCH_ORGANIZATION_TOKEN_FAILURE';

export const ADD_ORGANIZATION_TOKEN_REQUEST = 'ADD_ORGANIZATION_TOKEN_REQUEST';
export const ADD_ORGANIZATION_TOKEN_SUCCESS = 'ADD_ORGANIZATION_TOKEN_SUCCESS';
export const ADD_ORGANIZATION_TOKEN_FAILURE = 'ADD_ORGANIZATION_TOKEN_FAILURE';

export const UPDATE_ORGANIZATION_TOKEN_REQUEST =
  'UPDATE_ORGANIZATION_TOKEN_REQUEST';
export const UPDATE_ORGANIZATION_TOKEN_SUCCESS =
  'UPDATE_ORGANIZATION_TOKEN_SUCCESS';
export const UPDATE_ORGANIZATION_TOKEN_FAILURE =
  'FETCH_ORGANIZATION_PRODUCT_FAILURE';

// PRODUCT SETTINGS
export const FETCH_PRODUCT_SETTINGS_REQUEST = 'FETCH_PRODUCT_SETTINGS_REQUEST';
export const FETCH_PRODUCT_SETTINGS_SUCCESS = 'FETCH_PRODUCT_SETTINGS_SUCCESS';
export const FETCH_PRODUCT_SETTINGS_FAILURE = 'FETCH_PRODUCT_SETTINGS_FAILURE';

export const FETCH_PRODUCTS_SETTINGS_REQUEST =
  'FETCH_PRODUCTS_SETTINGS_REQUEST';
export const FETCH_PRODUCTS_SETTINGS_SUCCESS =
  'FETCH_PRODUCTS_SETTINGS_SUCCESS';
export const FETCH_PRODUCTS_SETTINGS_FAILURE =
  'FETCH_PRODUCTS_SETTINGS_FAILURE';

export const SAVE_PRODUCT_SETTINGS_REQUEST = 'SAVE_PRODUCT_SETTINGS_REQUEST';
export const SAVE_PRODUCT_SETTINGS_SUCCESS = 'SAVE_PRODUCT_SETTINGS_SUCCESS';
export const SAVE_PRODUCT_SETTINGS_FAILURE = 'SAVE_PRODUCT_SETTINGS_FAILURE';

export const APPLY_PRODUCT_SETTINGS_REQUEST = 'APPLY_PRODUCT_SETTINGS_REQUEST';
export const APPLY_PRODUCT_SETTINGS_SUCCESS = 'APPLY_PRODUCT_SETTINGS_SUCCESS';
export const APPLY_PRODUCT_SETTINGS_FAILURE = 'APPLY_PRODUCT_SETTINGS_FAILURE';

// USERS
export const UPDATE_USERS = 'UPDATE_USERS';
export const SAVE_USERS_REQUEST = 'SAVE_USERS_REQUEST';
export const SAVE_USERS_SUCCESS = 'SAVE_USERS_SUCCESS';
export const SAVE_USERS_FAILURE = 'SAVE_USERS_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const RESEND_INVITATION_CODE_REQUEST = 'RESEND_INVITATION_CODE_REQUEST';
export const RESEND_INVITATION_CODE_SUCCESS = 'RESEND_INVITATION_CODE_SUCCESS';
export const RESEND_INVITATION_CODE_FAILURE = 'RESEND_INVITATION_CODE_FAILURE';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const FETCH_SHARED_USERS_REQUEST = 'FETCH_SHARED_USERS_REQUEST';
export const FETCH_SHARED_USERS_SUCCESS = 'FETCH_SHARED_USERS_SUCCESS';
export const FETCH_SHARED_USERS_FAILURE = 'FETCH_SHARED_USERS_FAILURE';

export const SAVE_SHARED_USERS_CONFIG_REQUEST =
  'SAVE_SHARED_USERS_CONFIG_REQUEST';
export const SAVE_SHARED_USERS_CONFIG_SUCCESS =
  'SAVE_SHARED_USERS_CONFIG_SUCCESS';
export const SAVE_SHARED_USERS_CONFIG_FAILURE =
  'SAVE_SHARED_USERS_CONFIG_FAILURE';

export const CREATE_SHARED_USER_REQUEST = 'CREATE_SHARED_USER_REQUEST';
export const CREATE_SHARED_USER_SUCCESS = 'CREATE_SHARED_USER_SUCCESS';
export const CREATE_SHARED_USER_FAILURE = 'CREATE_SHARED_USER_FAILURE';

export const FETCH_RESTORE_BACKUP_LOGS_REQUEST = 'FETCH_RESTORE_BACKUP_LOGS_REQUEST';
export const FETCH_RESTORE_BACKUP_LOGS_SUCCESS = 'FETCH_RESTORE_BACKUP_LOGS_SUCCESS';
export const FETCH_RESTORE_BACKUP_LOGS_FAILURE = 'FETCH_RESTORE_BACKUP_LOGS_FAILURE';

export const UPDATE_RESTORE_BACKUP_LOGS = 'UPDATE_RESTORE_BACKUP_LOGS';

export const UPDATE_ORGANIZATION_ASSISTED_SCHEDULING_REQUEST =
  'UPDATE_ORGANIZATION_ASSISTED_SCHEDULING_REQUEST';
export const UPDATE_ORGANIZATION_ASSISTED_SCHEDULING_SUCCESS =
  'UPDATE_ORGANIZATION_ASSISTED_SCHEDULING_SUCCESS';
export const UPDATE_ORGANIZATION_ASSISTED_SCHEDULING_FAILURE =
  'UPDATE_ORGANIZATION_ASSISTED_SCHEDULING_FAILURE';

export const GENERATE_TOKEN_ORGANIZATION_ASSISTED_SCHEDULING_REQUEST =
  'GENERATE_TOKEN_ORGANIZATION_ASSISTED_SCHEDULING_REQUEST';
export const GENERATE_TOKEN_ORGANIZATION_ASSISTED_SCHEDULING_SUCCESS =
  'GENERATE_TOKEN_ORGANIZATION_ASSISTED_SCHEDULING_SUCCESS';
export const GENERATE_TOKEN_ORGANIZATION_ASSISTED_SCHEDULING_FAILURE =
  'GENERATE_TOKEN_ORGANIZATION_ASSISTED_SCHEDULING_FAILURE';

export const UPDATE_DATA_SOURCE_TYPES_REQUEST =
  'UPDATE_DATA_SOURCE_TYPES_REQUEST';
export const UPDATE_DATA_SOURCE_TYPES_SUCCESS =
  'UPDATE_DATA_SOURCE_TYPES_SUCCESS';
export const UPDATE_DATA_SOURCE_TYPES_FAILURE =
  'UPDATE_DATA_SOURCE_TYPES_FAILURE';

export const UPDATE_EXAM_IN_CORE_CONFIG_REQUEST =
  'UPDATE_EXAM_IN_CORE_CONFIG_REQUEST';
export const UPDATE_EXAM_IN_CORE_CONFIG_SUCCESS =
  'UPDATE_EXAM_IN_CORE_CONFIG_SUCCESS';
export const UPDATE_EXAM_IN_CORE_CONFIG_FAILURE =
  'UPDATE_EXAM_IN_CORE_CONFIG_FAILURE';

export const FETCH_INTEGRATION_CONFIGURATION_FIELD_REQUEST =
  'FETCH_INTEGRATION_CONFIGURATION_FIELD_REQUEST';
export const FETCH_INTEGRATION_CONFIGURATION_FIELD_SUCCESS =
  'FETCH_INTEGRATION_CONFIGURATION_FIELD_SUCCESS';
export const FETCH_INTEGRATION_CONFIGURATION_FIELD_FAILURE =
  'FETCH_INTEGRATION_CONFIGURATION_FIELD_FAILURE';