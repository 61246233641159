import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { TEInput, Button, Popconfirm } from 'te-antd';

// eslint-disable-next-line react/prefer-stateless-function
class Permission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: {},
      editing: false,
    };
  }

  componentWillMount() {
    const { children } = this.props;
    this.setState(() => ({
      permission: children[0].props.record,
    }));
  }

  onStartEditing = () => {
    const { permission } = this.state;
    this.setState(() => ({
      editing: true,
      clonedPermission: { ...permission },
    }));
  };

  onSaveEditing = () => {
    this.setState(() => ({
      editing: false,
      clonedPermission: null,
    }));

    // UPDATE PERMISSIONG!
    const { onSave } = this.props;
    if (typeof onSave === 'function') {
      const { permission } = this.state;
      onSave(permission);
    }
  };

  onCancelEditing = () => {
    const { clonedPermission } = this.state;
    this.setState(() => ({
      editing: false,
      permission: { ...clonedPermission },
    }));
  };

  onPermissionUpdate = (key, val) => {
    const { permission } = this.state;
    this.setState(() => ({
      permission: {
        ...permission,
        [key]: val,
      },
    }));
  };

  onPermissionDelete = () => {
    const { permission } = this.state;
    const { onDelete } = this.props;
    if (typeof onDelete === 'function') {
      onDelete(permission);
    }
  };

  renderActions = () => {
    const { editing } = this.state;
    if (editing) {
      return (
        <React.Fragment>
          <Button size="small" type="priamry" onClick={this.onSaveEditing}>
            Save
          </Button>
          &nbsp;
          <Button size="small" type="priamry" onClick={this.onCancelEditing}>
            Cancel
          </Button>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Button size="small" onClick={this.onStartEditing}>
          Edit
        </Button>
        &nbsp;
        <Popconfirm
          title="Do you want to delete this permission ?"
          onConfirm={this.onPermissionDelete}
        >
          <Button size="small">Delete</Button>
        </Popconfirm>
      </React.Fragment>
    );
  };

  render() {
    const { permission, editing } = this.state;
    const { name, description } = permission;
    return (
      <tr>
        <td>
          {editing ? (
            <TEInput
              disabled={!editing}
              value={name}
              onChange={val => this.onPermissionUpdate('name', val)}
            />
          ) : (
            <span>{name}</span>
          )}
        </td>
        <td>
          {editing ? (
            <TEInput
              disabled={!editing}
              value={description}
              onChange={val => this.onPermissionUpdate('description', val)}
            />
          ) : (
            <span>{description}</span>
          )}
        </td>
        <td>{this.renderActions()}</td>
      </tr>
    );
  }
}

Permission.propTypes = {
  children: PropTypes.node.isRequired,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
};
Permission.defaultProps = {
  onDelete: null,
  onSave: null,
};

export default Permission;
