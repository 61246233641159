import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Tag, Badge, Popover, Button } from 'te-antd';
import { MdInfo } from 'react-icons/md';

// STYLES
import './UserStatus.scss';

const COLORS = {
  inactive: '#f50',
  active: '#2db7f5',
  pending: '#ffc53d',
};

const UserStatus = ({ user, onResend }) => {
  const {
    password,
    invitation: { isExpired },
  } = user;
  if (!password) {
    if (isExpired) {
      const popover = (
        <Popover
          trigger="click"
          // eslint-disable-next-line prettier/prettier
          content={<span>Invitation code has expired. <Button onClick={onResend} style={{ padding: 0 }} type="link"><u>Resend invite</u></Button></span>}
        >
          <MdInfo style={{ color: COLORS.inactive }} />
        </Popover>
      );
      return (
        <Badge className="user-status" count={popover}>
          <Tag color={COLORS.pending}>Pending</Tag>
        </Badge>
      );
    }
    return <Tag color={COLORS.pending}>Pending</Tag>;
  }

  const { isActive } = user;
  if (isActive) return <Tag color={COLORS.active}>Active</Tag>;

  return <Tag color={COLORS.inactive}>Inctive</Tag>;
};

UserStatus.propTypes = {
  user: PropTypes.object,
  onResend: PropTypes.func,
};
UserStatus.defaultProps = {
  user: {},
  onResend: () => {},
};

export default UserStatus;
