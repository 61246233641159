export const FETCH_ORGANIZATIONS = 'FETCH_ORGANIZATIONS';
export const FETCH_ORGANIZATIONS_LOADING = 'FETCH_ORGANIZATIONS_LOADING';
export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS';
export const FETCH_ORGANIZATIONS_FAILURE = 'FETCH_ORGANIZATIONS_FAILURE';

export const SEARCH_ORGANIZATIONS = 'SEARCH_ORGANIZATIONS';

export const DEACTIVATE_ORGANIZATIONS = 'DEACTIVATE_ORGANIZATIONS';
export const DEACTIVATE_ORGANIZATIONS_SUCCESS = 'DEACTIVATE_ORGANIZATIONS_SUCCESS';
export const DEACTIVATE_ORGANIZATIONS_FAILURE = 'DEACTIVATE_ORGANIZATIONS_FAILURE';

export const ACTIVATE_ORGANIZATIONS = 'ACTIVATE_ORGANIZATIONS';
export const ACTIVATE_ORGANIZATIONS_SUCCESS = 'ACTIVATE_ORGANIZATIONS_SUCCESS';
export const ACTIVATE_ORGANIZATIONS_FAILURE = 'ACTIVATE_ORGANIZATIONS_FAILURE';

export const FLUSH_COPY_ORGANIZATION = 'FLUSH_COPY_ORGANIZATION';
export const SELECT_COPY_ORGANIZATION = 'SELECT_COPY_ORGANIZATION';

export const FETCH_ORGANIZATION_DETAIL_CONNECTION = 'FETCH_ORGANIZATION_DETAIL_CONNECTION';
export const FETCH_ORGANIZATION_DETAIL_CONNECTION_SUCCESS = 'FETCH_ORGANIZATION_DETAIL_CONNECTION_SUCCESS';
export const FETCH_ORGANIZATION_DETAIL_CONNECTION_FAILURE = 'FETCH_ORGANIZATION_DETAIL_CONNECTION_FAILURE';

export const COPY_ORGANIZATION = 'COPY_ORGANIZATION';
export const COPY_ORGANIZATION_SUCCESS = 'COPY_ORGANIZATION_SUCCESS';
export const COPY_ORGANIZATION_FAILURE = 'COPY_ORGANIZATION_FAILURE';

export const FETCH_APP_SETTING_COPY = "FETCH_APP_SETTING_COPY";
export const FETCH_APP_SETTING_COPY_SUCCESS = "FETCH_APP_SETTING_COPY_SUCCESS";
export const FETCH_APP_SETTING_COPY_FAILURE = "FETCH_APP_SETTING_COPY_FAILURE";

export const ADD_REGION_TO_ORGANIZATION = 'ADD_REGION_TO_ORGANIZATION';

