import React from 'react';
import PropTypes from 'prop-types';
import { Form, TEInput, Select, Button, Row, Col } from 'te-antd';

const { Option, OptGroup } = Select;

class RoleDetails extends React.Component {
  // ------------------------------------------------------------------------
  // EVENT HANDLER
  // ------------------------------------------------------------------------
  onSubmit = e => {
    e.preventDefault();
    const { form } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { onSubmit } = this.props;
        if (typeof onSubmit === 'function') {
          onSubmit(values);
        }
      }
    });
  };

  // ------------------------------------------------------------------------
  // RENDERING
  // ------------------------------------------------------------------------
  render() {
    const {
      permissions: allPermissions,
      form,
      role,
      submitButton,
    } = this.props;
    const { label, loading } = submitButton;
    const { name, title, description, permissions } = role;
    const { getFieldDecorator } = form;

    return (
      <Row>
        <Col md={12}>
          <Form onSubmit={this.onSubmit}>
            <Form.Item label="Role name">
              {getFieldDecorator('name', {
                initialValue: name,
                rules: [
                  {
                    required: true,
                    message: 'Please input role name',
                  },
                  {
                    pattern: new RegExp(
                      '^[a-zA-Z0-9]+(?:(-|_)+[a-zA-Z0-9]+)*$'
                    ),
                    message: 'Role name is invalid',
                  },
                ],
              })(<TEInput />)}
            </Form.Item>
            <Form.Item label="Role title">
              {getFieldDecorator('title', {
                initialValue: title,
                rules: [
                  {
                    required: true,
                    message: 'Please input role title',
                  },
                ],
              })(<TEInput />)}
            </Form.Item>
            <Form.Item label="Description">
              {getFieldDecorator('description', {
                initialValue: description,
              })(<TEInput type="textarea" />)}
            </Form.Item>
            <Form.Item label="Permissions">
              {getFieldDecorator('permissions', {
                initialValue: permissions || [],
                rules: [
                  {
                    required: true,
                    message: 'Please select at least one permission',
                  },
                ],
              })(
                <Select style={{ width: '100%' }} mode="tags">
                  {Object.keys(allPermissions).map(key => (
                    <OptGroup key={key} label={key}>
                      {allPermissions[key].map(permission => (
                        <Option value={permission._id} key={permission._id}>
                          {permission.name}
                        </Option>
                      ))}
                    </OptGroup>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Button loading={loading} htmlType="submit" type="primary">
              {label}
            </Button>
          </Form>
        </Col>
      </Row>
    );
  }
}

RoleDetails.propTypes = {
  permissions: PropTypes.object,
  submitButton: PropTypes.object.isRequired,
  role: PropTypes.object,
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

RoleDetails.defaultProps = {
  permissions: [],
  role: {},
};

export default Form.create({ name: 'RoleDetails' })(RoleDetails);
