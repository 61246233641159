import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find } from 'lodash';

// ACTIONS
import { setBreadcrumbs } from 'providers/globalUI/globalUI.actions';
import { updateRole } from 'providers/teApps/teApps.actions';

// COMPONENTS
import { SectionHeader } from 'components/PageLayout/PageLayout';
import RoleDetails from './Components/Roles/RoleDetails';

// eslint-disable-next-line react/prefer-stateless-function
class PageRoleDetails extends React.Component {
  roleDetails = null;

  componentWillMount() {
    const { setBreadcrumbs, application, role } = this.props;
    setBreadcrumbs([
      {
        path: '../../',
        label: 'Applications',
      },
      {
        path: `./../${application._id}`,
        label: application.name,
      },
      {
        path: './',
        label: role ? role.name : 'Loading',
      },
    ]);
  }

  // ------------------------------------------------------------------------
  // EVENT HANDLER
  // ------------------------------------------------------------------------
  onSubmit = async values => {
    const { updateRole, role } = this.props;
    const { name, title, description, permissions } = values;

    await updateRole({
      role: { ...role, name, title, description, permissions },
    });
  };

  // ------------------------------------------------------------------------
  // RENDERING
  // ------------------------------------------------------------------------
  render() {
    const { permissions, roleSaving, role } = this.props;
    if (!role) return null;
    return (
      <div>
        <SectionHeader sectionTitle="Edit role" />
        <RoleDetails
          role={role}
          submitButton={{
            loading: roleSaving,
            label: 'Save',
          }}
          onSubmit={this.onSubmit}
          permissions={permissions}
        />
      </div>
    );
  }
}

const mapActionsToProps = {
  setBreadcrumbs,
  updateRole,
};

const mapStateToProps = (state, ownProps) => ({
  application: state.teApps.apps.edit,
  roleSaving: state.teApps.roleSaving,
  permissions: state.teApps.permissions,
  role: find(
    state.teApps.roles[state.teApps.apps.edit.name],
    item => item._id === ownProps.match.params.roleId
  ),
});

PageRoleDetails.propTypes = {
  application: PropTypes.object,
  setBreadcrumbs: PropTypes.func.isRequired,
  updateRole: PropTypes.func.isRequired,
  permissions: PropTypes.object,
  roleSaving: PropTypes.bool,
  role: PropTypes.object,
};

PageRoleDetails.defaultProps = {
  application: {},
  permissions: [],
  roleSaving: false,
  role: null,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(PageRoleDetails);
