import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// ACTIONS
import { stopEditingTable } from 'providers/globalUI/globalUI.actions';

// COMPONENTS
import { Popconfirm, Button } from 'te-antd';

const EditableTableButton = props => {
  // RENDERING
  const {
    children,
    editing,
    onConfirm,
    popoverTitle,
    stopEditingTable,
    tableId,
  } = props;
  if (!editing) return children;
  return (
    <Popconfirm
      onConfirm={() => {
        onConfirm();
        stopEditingTable(tableId);
      }}
      title={popoverTitle}
    >
      <Button>Save changes</Button>
    </Popconfirm>
  );
};

const mapActionsToProps = {
  stopEditingTable,
};

const mapStateToProps = (state, ownProps) => ({
  editing: state.globalUI.editingTable[ownProps.tableId],
});

EditableTableButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.array,
  ]).isRequired,
  editing: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  popoverTitle: PropTypes.string,
  stopEditingTable: PropTypes.func.isRequired,
  tableId: PropTypes.string.isRequired,
};
EditableTableButton.defaultProps = {
  editing: false,
  popoverTitle: 'You didn’t save your changes, do you want to proceed?',
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(EditableTableButton);
