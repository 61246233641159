// ACTION TYPES
import {
  SELECT_MENU_ITEM,
  SELECT_SUB_MENU_ITEM,
  HIDE_SECONDARY_SIDEBAR,
  SET_BREADCRUMBS,
  START_EDITING_TABLE,
  STOP_EDITING_TABLE,
} from './globalUI.actionTypes';

export const selectMenuItem = ({
  selectedMenuItemIndex,
  shouldShowSecondSidebar,
}) => ({
  type: SELECT_MENU_ITEM,
  payload: {
    selectedMenuItemIndex,
    shouldShowSecondSidebar,
  },
});

export const selectSubMenuItem = ({
  selectedSubMenuItemIndex,
  selectedMenuItemIndex,
}) => ({
  type: SELECT_SUB_MENU_ITEM,
  payload: {
    selectedSubMenuItemIndex,
    selectedMenuItemIndex,
    shouldShowSecondSidebar: true,
  },
});

export const hideSecondarySidebar = () => ({
  type: HIDE_SECONDARY_SIDEBAR,
});

export const setBreadcrumbs = breadcrumbs => ({
  type: SET_BREADCRUMBS,
  breadcrumbs,
});

export const startEditingTable = tableId => ({
  type: START_EDITING_TABLE,
  tableId,
});

export const stopEditingTable = tableId => ({
  type: STOP_EDITING_TABLE,
  tableId,
});
