import { compact, isEmpty } from 'lodash';
import { asyncAction } from 'providers/utils/actionHelpers';
import { api } from 'providers/api';
import { alertOnError } from 'providers/api/api.model';
import { ObjectId } from 'bson';
import * as types from './users.actionTypes';

// CONSTANTS
import { AUTH_DOMAIN } from '../../configs';
import { ANZ, EU_EES } from '../../constants/organization.constants';

const regions = [
  EU_EES,
  ANZ
]

export const fetchAdmins = asyncAction(
  {
    type: types.FETCH_ADMINS_REQUEST,
    success: types.FETCH_ADMINS_SUCCESS,
    failure: types.FETCH_ADMINS_FAILURE,
    callback: alertOnError,
  },
  async () => {
    const json = await api.get(`/users?list=all&perPage=5000`);
    return json;
  }
);

export const createAdmin = ({ user, cb}) => async (dispatch, getState) => {
  const storeState = getState();
  const { roles, ...rest } = user;
  const userId = new ObjectId();
  dispatch({
    type: types.UPDATE_ADMIN_REQUEST,
  });

  // Add admin in all regions
  const originalToken = localStorage.getItem('api_token');
  const promises = regions.map(region => async () => {
    let res = null;
    api.setToken(originalToken);
    const changeRegionResults = await api.post(
      `${AUTH_DOMAIN}/v1/auth/change-region`,
      {
        region,
        userId: storeState.authentication.user.id,
      },
    );
    if (changeRegionResults) {
      const { accessToken } = changeRegionResults;
      api.setToken(accessToken);
      res = await api.post(`/users`, { ...rest, username: user.email, _id: userId });
    }
    api.setToken(originalToken);
    return new Promise(resolve => resolve(res));
  });

  try {
    const res = await Promise.all(promises.map(task => task()));
    if (typeof cb === 'function') cb();
    if (isEmpty(compact(res))) {
      dispatch({
        type: types.UPDATE_ADMIN_FAILURE,
      });
    } else {
      dispatch({
        type: types.UPDATE_ADMIN_SUCCESS,
        payload: res[0],
      });
    }
  } catch(e) {
    dispatch({
      type: types.UPDATE_ADMIN_FAILURE,
    });
  }
}

export const updateAdmin = ({ user, cb}) => async (dispatch, getState) => {
  const storeState = getState();
  const { roles, ...rest } = user;
  dispatch({
    type: types.UPDATE_ADMIN_REQUEST,
  });

  // Add admin in all regions
  const originalToken = localStorage.getItem('api_token');
  const promises = regions.map(region => async () => {
    let res = null;
    api.setToken(originalToken);
    const changeRegionResults = await api.post(
      `${AUTH_DOMAIN}/v1/auth/change-region`,
      {
        region,
        userId: storeState.authentication.user.id,
      },
    );
    if (changeRegionResults) {
      const { accessToken } = changeRegionResults;
      api.setToken(accessToken);
      res = await api.put(`/users/${user._id}`, { ...rest, username: user.email });
    }
    api.setToken(originalToken);
    return new Promise(resolve => resolve(res));
  });

  try {
    const res = await Promise.all(promises.map(task => task()));
    if (typeof cb === 'function') cb();
    if (isEmpty(compact(res))) {
      dispatch({
        type: types.UPDATE_ADMIN_FAILURE,
      });
    } else {
      dispatch({
        type: types.UPDATE_ADMIN_SUCCESS,
        payload: res[0],
      });
    }
  } catch(e) {
    dispatch({
      type: types.UPDATE_ADMIN_FAILURE,
    });
  }
}


export const resendInvitationCodeAdmin = asyncAction(
  {
    type: types.RESEND_INVITATION_CODE_ADMIN,
    success: types.RESEND_INVITATION_CODE_ADMIN_SUCCESS,
    failure: types.RESEND_INVITATION_CODE_ADMIN_FAILURE,
    callback: alertOnError,
  },
  async ({ userId, cb }) => {
    const json = await api.post(`/users/${userId}/resend`);
    if (typeof cb === 'function') {
      cb();
    }
    return {
      user: {
        _id: userId,
        invitation: json.data.invitation,
      },
    };
  }
);

export const updateAdminStatus = asyncAction(
  {
    type: types.UPDATE_ADMIN_STATUS,
    success: types.UPDATE_ADMIN_STATUS_SUCCESS,
    failure: types.UPDATE_ADMIN_STATUS_FAILURE,
    callback: alertOnError,
  },
  async ({ userId, isActive, cb }) => {
    const json = await api.put(
      `/users/${userId}/${isActive ? 'activate' : 'deactivate'}`
    );
    if (typeof cb === 'function') {
      cb();
    }

    return {
      user: {
        _id: userId,
        isActive: json.data.user.isActive,
      },
    };
  }
);
