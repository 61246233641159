import React from 'react';
import PropTypes from 'prop-types';

// CONSTANTS
import {
  AUTHENTICATION_STRATEGY,
  SIGNATUREALGORITHMS,
  LANGUAGE_LIST,
  LANGUAGE_CONSTANT,
  ORGANIZATION_TYPE,
  RESTRICTION_OPTIONS,
  REGIONS,
} from 'constants/organization.constants';

// COMPONENTS
import { Form, Input, Select } from 'te-antd';

const { Option } = Select;

// eslint-disable-next-line react/prefer-stateless-function
class FormOrganizationDetails extends React.Component {
  onSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { onSubmit } = this.props;
        if (typeof onSubmit === 'function') {
          onSubmit(values);
        }
      }
    });
  };

  renderAuthConfig = () => {
    const { initialValues, form } = this.props;
    const authStrategy = form.getFieldValue('authStrategy');
    if (authStrategy !== 'SAML2') return null;

    const { getFieldDecorator } = form;
    const { authConfig } = initialValues;

    const fields = [
      {
        label: 'Entry Point',
        name: 'entryPoint',
        element: <Input />,
        rules: [
          {
            required: true,
            message: 'Entry Point is required',
          },
        ],
      },
      {
        label: 'Issuer',
        name: 'issuer',
        element: <Input />,
        rules: [
          {
            required: true,
            message: 'Issuer is required',
          },
        ],
      },
      {
        label: 'Signature Algorithm',
        name: 'signatureAlgorithm',
        element: (
          <Select>
            {SIGNATUREALGORITHMS.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        ),
      },
      {
        label: 'Cert',
        name: 'cert',
        element: <Input.TextArea onPressEnter={(e) => e.preventDefault()} />,
      },
      {
        label: 'Private Cert',
        name: 'privateCert',
        element: <Input.TextArea onPressEnter={(e) => e.preventDefault()} />,
      },
      {
        label: 'Authn Context',
        name: 'authnContext',
        element: <Input />,
      },
    ];
    return (
      <React.Fragment>
        {fields.map((item) => (
          <Form.Item label={item.label}>
            {getFieldDecorator(`authConfig.${item.name}`, {
              initialValue: authConfig[item.name],
              rules: item.rules,
            })(item.element)}
          </Form.Item>
        ))}
      </React.Fragment>
    );
  };

  render() {
    const { form, initialValues, submitButton, availableRegion } = this.props;
    console.log(availableRegion, ':: availableRegion');
    if (!form) return null;
    const {
      name,
      authStrategy,
      primaryLanguage,
      secondaryLanguage,
      customerSignature,
      restrictAccess,
      type,
      region,
    } = initialValues;
    const { getFieldDecorator } = form;
    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Item label="Organization Name">
          {getFieldDecorator('name', {
            initialValue: name,
            rules: [
              {
                required: true,
                message: 'Organization Name is required',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Authentication strategy">
          {getFieldDecorator('authStrategy', {
            initialValue: authStrategy,
          })(
            <Select>
              {AUTHENTICATION_STRATEGY.map((item) => (
                <Option key={item.key} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Primary Language">
          {getFieldDecorator('primaryLanguage', {
            initialValue: primaryLanguage || LANGUAGE_CONSTANT.english,
          })(
            <Select>
              {LANGUAGE_LIST.map((item) => (
                <Option key={item.key} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Secondary Language">
          {getFieldDecorator('secondaryLanguage', {
            initialValue: secondaryLanguage || LANGUAGE_CONSTANT.english,
          })(
            <Select>
              {LANGUAGE_LIST.map((item) => (
                <Option key={item.key} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Customer Signature">
          {getFieldDecorator('customerSignature', {
            initialValue: customerSignature,
            rules: [
              {
                message: 'Customer Signature is required',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Restrict access">
          {getFieldDecorator('restrictAccess', {
            initialValue: restrictAccess || 'NO',
          })(
            <Select placeholder="select...">
              {Object.keys(RESTRICTION_OPTIONS).map((key) => (
                <Select.Option key={key} value={key}>
                  {RESTRICTION_OPTIONS[key]}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        {!region && (
          <Form.Item label="Region">
            {getFieldDecorator('region', {
              initialValue: region,
            })(
              <Select placeholder="select...">
                {Object.keys(REGIONS)
                  .filter((key) => key === availableRegion)
                  .map((key) => {
                    return (
                      <Select.Option key={key} value={key}>
                        {REGIONS[key]}
                      </Select.Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        )}
        <Form.Item label="Organization Type">
          {getFieldDecorator('type', {
            initialValue: type,
            rules: [
              { required: true, message: 'Please select organization type' },
            ],
          })(
            <Select placeholder="select...">
              {Object.keys(ORGANIZATION_TYPE).map((key) => (
                <Select.Option key={key} value={key}>
                  {ORGANIZATION_TYPE[key]}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        {this.renderAuthConfig()}
        <Form.Item>{submitButton}</Form.Item>
      </Form>
    );
  }
}

FormOrganizationDetails.propTypes = {
  form: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  submitButton: PropTypes.node,
  availableRegion: PropTypes.string,
};
FormOrganizationDetails.defaultProps = {
  initialValues: {},
  onSubmit: null,
  submitButton: null,
  availableRegion: null,
};

export default Form.create({ name: 'organizationDetails' })(
  FormOrganizationDetails
);
