import React, { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { Collapse, Modal, Form, Button, Typography, Alert } from 'te-antd';

import IntegrationSetting from './IntegrationSetting';
import GeneralInformation from './GeneralInformation';
import UserManagement from './UserManagement';
import ProductSetting from './ProductSetting';
import {
  clearCopyOrganization,
  copyOrganization,
  fetchAppSetting,
} from '../../providers/organizations/organizations.actions'

const { Panel } = Collapse;

const mapApp = {
  tePref: 'tePref',
  teExam: 'teExam'
};

const appLabel = {
  [mapApp.tePref]: 'TE Preferences',
  [mapApp.teExam]: 'TE Exam',
  teHootdesk: 'Hootdesk',
}

const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
}

const OrganizationCopyModal = ({
  organization,
  connectionSetting,
  clearCopyOrganizationAction,
  copyOrganizationAction,
  isCopying,
  copyOrgSuccess,
  copyOrgError,
  form,
  fetchAppSettingAction,
  isFetchAppSetting,
}) => {
  const {
    // getFieldDecorator,
    validateFields,
    setFieldsValue,
    resetFields,
  } = form;
  useEffect(() => {
    if (organization) {
      setFieldsValue({
        name: `copy_${organization.name}`,
        customerSignature: organization.customerSignature
      })
    }
    if (connectionSetting) {
      const essentialFields = _.pick(
        connectionSetting,
        ['username', 'password', 'certificateKey']
      );
      setFieldsValue({...essentialFields})
    }
    if (organization && !organization.appSettings) fetchAppSettingAction({ organizationId: organization._id })
  }, [organization, connectionSetting]);

  const onClose = () => {
    resetFields()
    clearCopyOrganizationAction()
  }

  useEffect(() => {
    if (copyOrgSuccess) onClose();
  }, [copyOrgSuccess])

  const onSubmit = () => {
    validateFields().then(values => {
      copyOrganizationAction({ organizationId: organization._id,...values });
    });
  }

  return (
    <Modal
      title="Copy Organization"
      visible={!!organization}
      onCancel={onClose}
      width="80%"
      footer={[
        <Button
          key="submitBtn"
          type="primary"
          htmlType="submit"
          loading={isCopying}
          onClick={onSubmit}
        >
            Copy
        </Button>,
        <Button key="cancelBtn" htmlType="cancel" onClick={onClose}>
          Cancel
        </Button>
      ]}
    >
      <Form
        name="basic"
        layout='horizontal'
      >
        <Alert
          message="Warning"
          description="Please make sure that you don’t by mistake connect two organizations to the same database."
          type="warning"
        />
        {!isFetchAppSetting && (
          <Collapse defaultActiveKey={['1', '2']}>
            <Panel header="General Information" key="1">
              <GeneralInformation form={form} formItemLayout={formItemLayout} />
            </Panel>
            <Panel header="Integration Settings" key="2">
              <IntegrationSetting form={form} formItemLayout={formItemLayout} />
            </Panel>
            <Panel header="User management" key="3">
              <UserManagement form={form} formItemLayout={formItemLayout} />
            </Panel>
            {_.get(organization, 'appSettings', []).filter(app => [mapApp.tePref, mapApp.teExam].includes(app.appName)).map((app, key) => (
              <Panel header={appLabel[app.appName]} key={key + 4}>
                <ProductSetting appName={app.appName} form={form} formItemLayout={formItemLayout} />
              </Panel>
            ))}
          </Collapse>
        )}
        { copyOrgError && (
        <Typography.Text
          style={{ width: '100%', textAlign: 'center', display: 'inline-block' }}
          type="danger"
        >{copyOrgError}
        </Typography.Text>
) }
      </Form>
    </Modal>
  )
};

OrganizationCopyModal.propTypes = {
  organization: PropTypes.objectOf(PropTypes.any),
  connectionSetting: PropTypes.objectOf(PropTypes.any),
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  clearCopyOrganizationAction: PropTypes.func.isRequired,
  copyOrganizationAction: PropTypes.func.isRequired,
  fetchAppSettingAction: PropTypes.func.isRequired,
  isFetchAppSetting: PropTypes.bool.isRequired,
  isCopying: PropTypes.bool,
  copyOrgSuccess: PropTypes.bool,
  copyOrgError: PropTypes.string,
};

OrganizationCopyModal.defaultProps = {
  organization: null,
  connectionSetting: null,
  isCopying: false,
  copyOrgSuccess: false,
  copyOrgError: null,
};

const mapPropsToState = ({ organizations }) => ({
  organization: _.get(organizations, 'orgSelected'),
  isFetchAppSetting: _.get(organizations, 'isFetchAppSetting'),
  isCopying: _.get(organizations, 'isCopying'),
  copyOrgSuccess: _.get(organizations, 'copyOrgSuccess'),
  copyOrgError: _.get(organizations, 'copyOrgError'),
  connectionSetting: _.get(organizations, `connectionSettingData[${_.get(organizations, 'orgSelected._id')}]`)
})

const mapDispatchToState = {
  clearCopyOrganizationAction: clearCopyOrganization,
  copyOrganizationAction: copyOrganization,
  fetchAppSettingAction: fetchAppSetting,
}

export default connect(
  mapPropsToState,
  mapDispatchToState
)(Form.create({ name: 'copyOrganization' })(OrganizationCopyModal));