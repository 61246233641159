import { find, findIndex, get } from 'lodash';
import { organizationGroupsConverter } from 'providers/utils/organizationGroups';
import * as types from './organizationGroups.actionTypes';
import { initialState } from './organizationGroups.initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_ORGANIZATION_GROUPS:
      return {
        ...state,
        list: action.updatedGroups,
        formatedList: organizationGroupsConverter(action.updatedGroups),
      };

    case types.FETCH_ORGANIZATION_GROUPS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_ORGANIZATION_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.data.organizationGroups,
        formatedList: organizationGroupsConverter(
          action.payload.data.organizationGroups
        ),
      };

    case types.UPDATE_ORGANIZATION_GROUP:
      return {
        ...state,
        edit: {
          ...state.edit,
          [action.key]: action.val,
        },
      };

    case types.INIT_EDITING_ORGANIZATION_GROUP:
      return {
        ...state,
        edit: find(state.list, item => item._id === action.groupId) || {
          parentIds: [],
        },
      };

    case types.CANCEL_EDITING_ORGANIZATION_GROUP:
      return {
        ...state,
        edit: null,
      };

    case types.SAVE_ORGANIZATION_GROUP_REQUEST:
      return {
        ...state,
        saving: true,
      };

    case types.SAVE_ORGANIZATION_GROUP_SUCCESS: {
      const organizationGroup = get(action.payload, 'data.organizationGroup');
      const newList = [...state.list];
      const foundIndex = findIndex(
        newList,
        item => item._id === organizationGroup._id
      );
      if (foundIndex > -1) {
        newList[foundIndex] = { ...organizationGroup };
      } else {
        newList.push(organizationGroup);
      }

      return {
        ...state,
        saving: false,
        list: newList,
        formatedList: organizationGroupsConverter(newList),
      };
    }

    case types.SAVE_ORGANIZATION_GROUP_FAILURE:
      return {
        ...state,
        saving: false,
      };

    default:
      return {
        ...state,
      };
  }
};
