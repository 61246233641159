import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// COMPONENTS
import { Typography } from 'te-antd';

const { Title, Text } = Typography;
const dateFormat = 'MMM Do YYYY';
const dateTimeFormat = 'MMM Do, h:mm a';

const UserInvitationDetails = ({ user }) => {
  const { invitation, email } = user;
  const { isExpired, invitationCode, iat, expiryDate } = invitation;
  return (
    <div style={{ fontSize: '1.6rem' }}>
      <Title type={isExpired ? 'danger' : ''} level={2}>
        {invitationCode}
      </Title>
      {isExpired ? (
        <React.Fragment>
          <Text type="danger" style={{ fontStyle: 'italic' }}>
            The code expired on {expiryDate.format(dateTimeFormat)}
          </Text>
          <br />
          <br />
          <Text>
            Sent to {email} {moment(iat).format(dateFormat)}
          </Text>
        </React.Fragment>
      ) : (
        <Text>
          {/* eslint-disable-next-line prettier/prettier */}
            Sent to {email} {moment(iat).format(dateFormat)}.&nbsp;The code is valid until {expiryDate.format(dateTimeFormat)}
        </Text>
      )}
    </div>
  );
};

UserInvitationDetails.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserInvitationDetails;
