import React, { useRef, useEffect } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Input, Icon } from 'te-antd';
import { searchOrganization } from '../../providers/organizations/organizations.actions';
import './OrganizationSearch.scss';

const OrganizationSearch = ({ searchOrganizationAction, filterKey }) => {
  useEffect(() => {
    return () => searchOrganizationAction({ searchText: '', filterKey });
  }, []);

  const delaySearch = useRef(_.debounce((value) => {
    searchOrganizationAction({ searchText: value.trim(), filterKey });
  }, 500)).current;

  const handleSearch = (event) => {
    delaySearch(event.target.value);
  };
  return (
    <Input
      className="organization-search"
      placeholder="Search"
      prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
      onChange={handleSearch}
    />
  )
}

OrganizationSearch.propTypes = {
  searchOrganizationAction: PropTypes.func.isRequired,
  filterKey: PropTypes.string.isRequired,
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  searchOrganizationAction: searchOrganization,
}, dispatch)

export default connect(null, mapDispatchToProps)(OrganizationSearch);