export const initialState = {
  organizationsLoading: false,
  list: {},
  orgSelected: null,
  connectionSettingData: {},
  connectSettingLoad: false,
  isCopying: false,
  copyOrgSuccess: false,
  orgSearch: {
    active: '',
    inactive: '',
  },
  region: null,
};
