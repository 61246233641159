import User from 'models/User.model';
import { get } from 'lodash';
import * as types from './editingUser.actionTypes';
import { initialState } from './editingUser.initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case types.EDIT_USER_ROLES:
      return {
        ...state,
        editingUser: {
          ...state.editingUser,
          userRoles: action.userRoles,
        },
        // userRoles: action.userRoles,
      };

    case types.START_EDITING_USER:
      return {
        ...state,
        editingUser: action.editingUser,
      };

    case types.STOP_EDITING_USER:
      return {
        ...state,
        editingUser: null,
      };

    case types.FIND_USER_REQUEST:
      return {
        ...state,
        findingUser: true,
      };

    case types.FIND_USER_SUCCESS: {
      const foundUser = get(action.payload.data, 'users[0]', null);
      if (!foundUser) {
        return {
          ...state,
          foundUser: null,
        };
      }

      const { organizationPermissions } = foundUser;
      if (Array.isArray(organizationPermissions)) {
        const organizationPermission = organizationPermissions.find(
          item =>
            item.organizationId === action.meta.actionPayload.organizationId
        );
        foundUser.organizationGroups = organizationPermission
          ? organizationPermission.organizationGroups
          : [];
      }
      return {
        ...state,
        findingUser: false,
        foundUser: new User(foundUser),
      };
    }

    case types.FIND_USER_FAILURE:
      return {
        ...state,
        findingUser: false,
        foundUser: null,
      };

    default:
      return { ...state };
  }
};
