import React from 'react';

// CONSTANTS
import { TE_PRICING } from 'constants/products.constants';

// COMPONENTS
import { Mapping } from 'te-integration-lib';

const ProductTEPricing = () => (
  <div>
    <Mapping appName={TE_PRICING} />
  </div>
);

export default ProductTEPricing;
