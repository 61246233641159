import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UserManagement from 'te-components/dist/UserManagement';

const OrganizationUsers = ({ productKeys, organizationId, userId }) => (
  <UserManagement.Component
    componentId="userTeAdmin"
    isSort
    isSearch
    config={{
      app: productKeys,
      endpoint: process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:3006/',
      accessToken: window.localStorage.getItem('api_token'),
      organizationId,
      userId,
    }}
  />
);

OrganizationUsers.propTypes = {
  productKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  organizationId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

const mapStateToProps = ({ newOrganization, authentication  }) => ({
  productKeys: _.get(newOrganization, 'PRODUCT_SETTINGS.productKeys'),
  organizationId: _.get(newOrganization, 'organizationId'),
  userId: _.get(authentication, 'user.id'),
})

export default connect(
  mapStateToProps,
)(OrganizationUsers);
