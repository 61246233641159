import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'te-antd';
import { Link } from 'react-router-dom';

const TEBreadcrumb = props => {
  const { routes } = props;
  if (!Array.isArray(routes) || !routes.length) return null;
  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.label}</span>
    ) : (
      <Link key={`${route.path}-${route.label}`} to={paths.join('/')}>
        {route.label}
      </Link>
    );
  };
  return (
    <Breadcrumb
      className="breadcrumbs"
      itemRender={itemRender}
      routes={[
        {
          path: '/',
          label: 'Home',
        },
      ].concat(routes)}
      separator=">"
    />
  );
};

TEBreadcrumb.propTypes = {
  routes: PropTypes.array,
};
TEBreadcrumb.defaultProps = {
  routes: [],
};

export default TEBreadcrumb;
