import { Backup } from 'models';
import _ from 'lodash';
import { asyncAction } from 'providers/utils/actionHelpers';
import { api } from 'providers/api';
import { alertOnError } from 'providers/api/api.model';
import * as types from './backup.actionTypes';
import * as newOrganizationTypes from '../newOrganization/newOrganization.actionTypes';

export const fetchBackup = asyncAction(
  {
    type: types.FETCH_BACKUP,
    success: types.FETCH_BACKUP_SUCCESS,
    failure: types.FETCH_BACKUP_FAILURE,
    callback: alertOnError,
  },
  async () => {
    const json = await api.get(`/backup`);
    json.data.backups = json.data.backups.map(backup => new Backup(backup));
    return json;
  }
);

export const generateBackup = asyncAction(
  {
    type: types.GENERATE_BACKUP,
    success: types.GENERATE_BACKUP_SUCCESS,
    failure: types.GENERATE_BACKUP_FAILURE,
    callback: alertOnError,
  },
  async ({ cb }) => {
    const json = await api.post('/backup');
    if (typeof cb === 'function') {
      cb();
    }
    json.data.backup = new Backup(json.data.backup);
    return json;
  }
);

export const restore = asyncAction(
  {
    type: types.RESTORE_BACKUP,
    success: types.RESTORE_BACKUP_SUCCESS,
    failure: types.RESTORE_BACKUP_FAILURE,
    callback: alertOnError,
  },
  async ({ backupId, organizationId, apps, cb }, meta, dispatch) => {
    const json = await api.put(
      `/backup/${backupId}/restore/organization/${organizationId}`,
      { apps }
    );
    if (typeof cb === 'function') {
      cb();
    }
    if(_.get(json, 'data.backups.restoreLog')) {
      dispatch({
        type: newOrganizationTypes.UPDATE_RESTORE_BACKUP_LOGS,
        restoreLog: _.get(json, 'data.backups.restoreLog'),
      });
    }
    return json;
  }
);
