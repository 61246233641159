import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Table, Button, Popconfirm } from 'te-antd';

class Roles extends React.Component {
  // eslint-disable-next-line react/sort-comp
  onDelete = item => () => {
    const { onDelete } = this.props;
    if (typeof onDelete === 'function') {
      onDelete(item);
    }
  };

  columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Actions',
      render: item => {
        const { onGoToRoleDetails } = this.props;
        return (
          <div>
            <Button
              onClick={onGoToRoleDetails(item)}
              size="small"
              type="primary"
            >
              View/Edit
            </Button>
            &nbsp;
            <Popconfirm
              title="Do you want to delete this role?"
              onConfirm={this.onDelete(item)}
            >
              <Button size="small" type="danger">
                Delete
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  render() {
    const { roles } = this.props;
    return (
      <Table
        columns={this.columns}
        dataSource={roles}
        footer={() => {
          const { onGoToCreateNewRole } = this.props;
          if (typeof onGoToCreateNewRole !== 'function') return null;
          return <Button onClick={onGoToCreateNewRole}>Create new role</Button>;
        }}
      />
    );
  }
}

Roles.propTypes = {
  onGoToCreateNewRole: PropTypes.func,
  onGoToRoleDetails: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  roles: PropTypes.array,
};
Roles.defaultProps = {
  onGoToCreateNewRole: null,
  onDelete: null,
  roles: [],
};

export default Roles;
