import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { keyBy } from 'lodash';
import { VALIDATION_RULES_NAME } from 'constants/general.constants';

// COMPONENTS
import { Form, TEInput, Select, Button } from 'te-antd';
import EditableTable from 'components/Tables/EditableTable/EditableTable';

// ACTIONS
import {
  updateOrganizationGroup,
  saveOrganizationGroup,
  cancelEditingOrganizationGroup,
  initEditingOrganizationGroup,
} from 'providers/organizationGroups/organizationGroups.actions';
import { isRoot } from 'providers/utils/organizationGroups';

class OrganizationGroupDetails extends React.Component {
  mounted = false;

  componentWillReceiveProps(nextProps) {
    const { organizationGroup: prevGroup } = this.props;
    const { organizationGroup } = nextProps;
    if (prevGroup && organizationGroup._id !== prevGroup._id) {
      const { form } = nextProps;
      form.resetFields();
    }
  }

  componentWillUnmount() {
    const { cancelEditingOrganizationGroup } = this.props;
    cancelEditingOrganizationGroup();
  }

  onSubmit = e => {
    e.preventDefault();
    const { form, saveOrganizationGroup, organizationGroup } = this.props;
    const { parentIds } = organizationGroup;
    form.validateFieldsAndScroll(err => {
      if (!err && parentIds && parentIds.length) {
        saveOrganizationGroup({
          callback: () => {
            const {
              organizationGroup,
              initEditingOrganizationGroup,
            } = this.props;
            if (!organizationGroup._id) {
              initEditingOrganizationGroup(null);
            }
          },
        });
      }
    });
  };

  render() {
    const {
      organizationGroup,
      organizationGroups,
      updateOrganizationGroup,
      saving,
      form,
      organizationMappedGroups,
    } = this.props;

    if (!organizationGroup) return null;

    const { _id, name, parentIds, tags, description } = organizationGroup;

    const filterOrgGroups = (currentId, rootNode) => {
      if (Array.isArray(rootNode.children)) {
        return rootNode.children.filter(item => {
          // eslint-disable-next-line no-param-reassign
          item.children = filterOrgGroups(currentId, item);
          return item._id !== currentId;
        });
      }
      return null;
    };

    const orgGroupOptions = organizationGroups
      .filter(item => item._id !== _id)
      .map(item => ({
        ...item,
        children: filterOrgGroups(_id, item),
        isRoot: isRoot(item),
        value: item._id,
        label: item.name,
      }));
    if (!this.columns) {
      this.columns = [
        {
          dataIndex: 'parentGroup',
          title: 'Organization Group',
          inputType: 'tree-select',
          editable: true,
          inputRules: [
            {
              required: true,
              message: 'Please select organization group',
            },
          ],
          inputOptions: {
            placeholder: 'Select organization group',
            style: { width: '100%' },
            showSearch: true,
            options: orgGroupOptions,
            filterFn: item => {
              return (
                // eslint-disable-next-line react/destructuring-assignment
                this.props.organizationGroup.parentIds.indexOf(item.value) ===
                -1
              );
            },
          },
        },
        {
          title: 'Actions',
          editableButtons: {
            addButton: {
              onClick: data => {
                const {
                  updateOrganizationGroup,
                  organizationGroup,
                } = this.props;
                const { parentIds } = organizationGroup;
                updateOrganizationGroup(
                  'parentIds',
                  (parentIds || []).concat(data.parentGroup)
                );
              },
            },
            editButton: {
              onClick: (data, dataIndex) => {
                const {
                  updateOrganizationGroup,
                  organizationGroup,
                } = this.props;
                const { parentIds } = organizationGroup;
                parentIds[dataIndex] = data.parentGroup;
                updateOrganizationGroup('parentIds', parentIds);
              },
            },
            removeButton: {
              onClick: (data, dataIndex) => {
                const {
                  updateOrganizationGroup,
                  organizationGroup,
                } = this.props;
                const { parentIds } = organizationGroup;
                parentIds.splice(dataIndex, 1);
                updateOrganizationGroup('parentIds', parentIds);
              },
            },
          },
        },
      ];
    }
    this.columns[0].inputOptions.options = orgGroupOptions;
    const { getFieldDecorator } = form;
    const parents = (parentIds || []).map(parentId => ({
      parentGroup: organizationMappedGroups[parentId]._id,
    }));
    return (
      <div className="organization-group-details">
        <Form onSubmit={this.onSubmit}>
          <Form.Item>
            {getFieldDecorator('name', {
              initialValue: name || '',
              onChange: val => updateOrganizationGroup('name', val),
              rules: [
                {
                  required: true,
                  message: 'Organization group name is required',
                },
                {
                  pattern: VALIDATION_RULES_NAME,
                  message: 'Organization group name is invalid',
                },
              ],
            })(<TEInput placeholder="Name" />)}
          </Form.Item>
          <Form.Item>
            <TEInput
              value={description}
              type="textarea"
              placeholder="Description"
              onChange={val => updateOrganizationGroup('description', val)}
            />
          </Form.Item>
          <Form.Item>
            <Select
              value={tags}
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Tags"
              onChange={val => updateOrganizationGroup('tags', val)}
            />
          </Form.Item>
          <div>
            <h3>Hierarchy</h3>
            <EditableTable
              className="table--users"
              columns={this.columns}
              dataSource={parents}
              loading={saving}
            />
          </div>
          <Form.Item
            validateStatus="error"
            help={
              name !== 'root' && (!parentIds || !parentIds.length)
                ? 'Please select parent organization'
                : null
            }
          />
          <br />
          <Button loading={saving} type="primary" htmlType="submit">
            Save changes
          </Button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  saving: state.organizationGroups.saving,
  organizationGroup: state.organizationGroups.edit,
  organizationGroups: state.organizationGroups.list,
  organizationMappedGroups: keyBy(state.organizationGroups.list, '_id'),
});

const mapActionsToProps = {
  updateOrganizationGroup,
  saveOrganizationGroup,
  cancelEditingOrganizationGroup,
  initEditingOrganizationGroup,
};

OrganizationGroupDetails.propTypes = {
  updateOrganizationGroup: PropTypes.func.isRequired,
  saveOrganizationGroup: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  organizationGroup: PropTypes.object,
  initEditingOrganizationGroup: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  organizationGroups: PropTypes.array,
  organizationMappedGroups: PropTypes.object,
  cancelEditingOrganizationGroup: PropTypes.func.isRequired,
};
OrganizationGroupDetails.defaultProps = {
  saving: false,
  organizationGroup: null,
  organizationGroups: [],
  organizationMappedGroups: {},
};

export default Form.create({ name: 'OrganizationGroupDetails' })(
  connect(
    mapStateToProps,
    mapActionsToProps
  )(OrganizationGroupDetails)
);
