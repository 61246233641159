import React from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import { connect } from 'react-redux';

// MODELS
import UserRole from 'models/UserRole.model';

// COMPONENTS
import EditableTable from 'components/Tables/EditableTable/EditableTable';

// CONSTANTS
import { PRODUCT_SETTINGS } from 'constants/organization.constants';

// ACTIONS
import { updateEditingUserRoles } from 'providers/newOrganization/editingUser/editingUser.actions';

class ModalUserRoles extends React.Component {
  constructor(props) {
    super(props);
    const { organizationGroups } = props;
    this.cols = {
      organizationGroup: {
        title: 'Organization group',
        dataIndex: 'organizationGroup',
        editable: true,
        inputType: 'select',
        inputOptions: {
          placeholder: 'Unselected',
          style: { width: '100%' },
          allowClear: true,
          options: organizationGroups.map(item => ({
            value: item.name,
            label: item.name,
          })),
        },
        inputRules: [
          {
            required: true,
            message: 'Please select organization group',
          },
        ],
      },
      isActive: {
        title: 'Is Active',
        dataIndex: 'isActive',
        inputType: 'switch',
        editable: true,
        // render: item => {
        //   if (item.isNewRow) return null;
        //   return <Switch defaultChecked={item.isActive} />;
        // },
      },
    };
  }

  componentWillMount() {
    const { user } = this.props;
    const { organizationGroups } = user;
    this.updatePermissionGroups(groupBy(organizationGroups, 'app'));
  }

  getPermissionGroupCol = app => {
    const { appRoles } = this.props;
    const permissionGroup = {
      title: 'Permission group',
      dataIndex: 'permissionGroup',
      editable: true,
      inputType: 'select',
      inputOptions: {
        placeholder: 'Unselected',
        style: { width: '100%' },
        allowClear: true,
        options: (appRoles[app] || []).map(item => ({
          value: item.title,
          label: item.title,
        })),
      },
      inputRules: [
        {
          required: true,
          message: 'Please select permission group',
        },
      ],
    };
    return permissionGroup;
  };

  getActionGroupCol = app => {
    return {
      title: 'Actions',
      editableButtons: {
        addButton: {
          onClick: role => {
            const {
              user: { userRoles },
            } = this.props;
            userRoles[app] = userRoles[app] || [];
            userRoles[app].push(new UserRole({ ...role, app }));
            this.setState(() => ({ userRoles }));
            this.updatePermissionGroups(userRoles);
          },
        },
        editButton: {
          onClick: (role, roleIndex) => {
            const {
              user: { userRoles },
            } = this.props;
            userRoles[app][roleIndex] = { ...role };
            this.setState(() => ({ userRoles }));
            this.updatePermissionGroups(userRoles);
          },
        },
        removeButton: {
          onClick: (role, roleIndex) => {
            const {
              user: { userRoles },
            } = this.props;
            userRoles[app].splice(roleIndex, 1);
            this.setState(() => ({ userRoles }));
            this.updatePermissionGroups(userRoles);
          },
        },
      },
    };
  };

  getColumns = app => {
    return [
      this.cols.organizationGroup,
      this.getPermissionGroupCol(app),
      this.cols.isActive,
      this.getActionGroupCol(app),
    ];
  };

  updatePermissionGroups = userRoles => {
    const { updateEditingUserRoles } = this.props;
    updateEditingUserRoles({ userRoles });
  };

  render() {
    const { user, productKeys } = this.props;
    const userRoles = user.userRoles || {};
    return (
      <div>
        {productKeys.map(key => (
          <div rel={`user-roles-${key}`}>
            <br />
            <h4>{key}</h4>
            <EditableTable
              tableId="userRolesTable"
              columns={this.getColumns(key)}
              dataSource={userRoles[key] || []}
              defaultNewRow={{ isActive: true }}
            />
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  appRoles: state.teApps.roles,
  organizationGroups: state.organizationGroups.list,
  productKeys: state.newOrganization[PRODUCT_SETTINGS].productKeys,
  user: state.editingUser.editingUser,
});

const mapActionsToProps = {
  updateEditingUserRoles,
};

ModalUserRoles.propTypes = {
  updateEditingUserRoles: PropTypes.func.isRequired,
  organizationGroups: PropTypes.array,
  appRoles: PropTypes.object,
  productKeys: PropTypes.array,
  user: PropTypes.object.isRequired,
};
ModalUserRoles.defaultProps = {
  organizationGroups: [],
  productKeys: [],
  appRoles: {},
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ModalUserRoles);
