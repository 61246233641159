import React from 'react';
import PropTypes from 'prop-types';

// CSS
import './StatusLabel.scss';

export const StatusLabel = ({ color, children }) => (
  <div className={`container-status-label ${color}`}>{children}</div>
);
StatusLabel.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
};
StatusLabel.defaultProps = {
  color: null,
  children: null,
};
