import React, { useState } from 'react';
import { Tabs } from 'te-antd';

// CONSTANTS
import { TE_REPORTING } from 'constants/products.constants';

// COMPONENTS
import { Mapping } from 'te-integration-lib';
import { DataSourceTypes } from '../DataSourceTypes'

const REPORTING_SETTINGS = {
  MAPPING: 'MAPPING',
  DATA_SOURCE_TYPE: 'DATA_SOURCE_TYPE'
};

const ProductTEReporting = () => {
  const [activeTab, setTab] = useState(REPORTING_SETTINGS.DATA_SOURCE_TYPE)

  const handleChangeTab = tab => setTab(tab);
  return (
    <div>
      <Tabs
        defaultActiveKey={activeTab}
        onChange={handleChangeTab}
      >
        <Tabs.TabPane tab="Data source types" key={REPORTING_SETTINGS.DATA_SOURCE_TYPE}>
          <DataSourceTypes />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Mapping" key={REPORTING_SETTINGS.MAPPING}>
          <Mapping appName={TE_REPORTING} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
};

export default ProductTEReporting;
