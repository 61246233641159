import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Card } from 'te-antd';
import { connect } from 'react-redux';

// ACTIONS
import { setBreadcrumbs } from 'providers/globalUI/globalUI.actions';

class PageApplicationsListing extends React.Component {
  componentWillMount() {
    const { setBreadcrumbs } = this.props;
    setBreadcrumbs([
      {
        path: 'applications',
        label: 'Applications',
      },
    ]);
  }

  toDetailsPage = appId => () => {
    const { history } = this.props;
    history.push(`/applications/${appId}`);
  };

  renderApplication = app => {
    return (
      <Card
        key={app._id}
        className="application"
        onClick={this.toDetailsPage(app._id)}
      >
        <Avatar shape="square" size={52} className="application__icon">
          <b>{app.name}</b>
        </Avatar>
        <h3 className="application__name">{app.title}</h3>
        <p className="application__description">{app.description}</p>
      </Card>
    );
  };

  render() {
    const { applications } = this.props;
    return (
      <div id="pageApplications">
        <div className="applications-wrapper">
          {applications.map(item => this.renderApplication(item))}
        </div>
      </div>
    );
  }
}

const mapActionsToProps = {
  setBreadcrumbs,
};
const mapStateToProps = state => ({
  applications: state.teApps.apps.list,
});

PageApplicationsListing.propTypes = {
  history: PropTypes.object.isRequired,
  setBreadcrumbs: PropTypes.func.isRequired,
  applications: PropTypes.array,
};

PageApplicationsListing.defaultProps = {
  applications: [],
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(PageApplicationsListing);
