import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'rc-color-picker/assets/index.css';

// ACTIONS
import {
  editGeneralSettings,
  saveGeneralSettings,
  changeStep,
} from 'providers/newOrganization/newOrganization.actions';

// CSS

// CONSTANTS
import {
  GENERAL_SETTINGS,
  INTEGRATION_SETTINGS,
  NEW_ORGANIZATION_STEP_STATUS_INCOMPLETE,
} from 'constants/organization.constants';

// COMPONENT
import AnimationLoading from 'components/AnimationLoading/AnimationLoading';
import { Button } from 'te-antd';
import FormOrganizationDetails from './FormOrganizationDetails';

const mapStateToProps = (state) => {
  return {
    organizationId: state.newOrganization.organizationId,
    generalSettings: state.newOrganization[GENERAL_SETTINGS],
    saving: state.newOrganization.saving[GENERAL_SETTINGS],
    nextStatus: state.newOrganization.status[INTEGRATION_SETTINGS],
    region: state.organizations.region,
  };
};

const mapActionsToProps = {
  editGeneralSettings,
  saveGeneralSettings,
  changeStep,
};

class GeneralSettings extends React.Component {
  // --------------------------------------------------------
  // EVENT HANDLING
  // --------------------------------------------------------
  onSave = (values) => {
    const {
      name,
      authStrategy,
      authConfig,
      primaryLanguage,
      secondaryLanguage,
      customerSignature,
      restrictAccess,
      region,
      type,
    } = values;
    const { saveGeneralSettings, history } = this.props;
    saveGeneralSettings({
      name,
      authStrategy,
      authConfig,
      primaryLanguage,
      secondaryLanguage,
      customerSignature,
      restrictAccess,
      region,
      type,
      callback: (data) => {
        this.setState(() => ({ showError: false }));
        const { organizationId, nextStatus } = this.props;
        if (!organizationId) {
          history.push(
            `/organizations/${data.organization._id}#${INTEGRATION_SETTINGS}`
          );
        } else if (nextStatus === NEW_ORGANIZATION_STEP_STATUS_INCOMPLETE) {
          const { changeStep } = this.props;
          changeStep(INTEGRATION_SETTINGS);
        }
      },
    });
  };

  // --------------------------------------------------------
  // RENDERING
  // --------------------------------------------------------
  render = () => {
    const { generalSettings, saving, region } = this.props;
    const tokenRegion = region || 'Undefined region';
    const { loading } = generalSettings;

    if (loading) return <AnimationLoading />;

    const submitButton = (
      <Button type="primary" loading={saving} htmlType="submit">
        Save changes
      </Button>
    );
    return (
      <div>
        <h1>General settings</h1>
        <div className="organization__general-settings">
          <FormOrganizationDetails
            initialValues={generalSettings}
            onSubmit={this.onSave}
            submitButton={submitButton}
            availableRegion={tokenRegion}
          />
        </div>
      </div>
    );
  };
}
GeneralSettings.propTypes = {
  // editGeneralSettings: PropTypes.func.isRequired,
  saveGeneralSettings: PropTypes.func.isRequired,
  generalSettings: PropTypes.object,
  saving: PropTypes.bool,
  organizationId: PropTypes.string,
  history: PropTypes.object,
  changeStep: PropTypes.func.isRequired,
  nextStatus: PropTypes.string,
  region: PropTypes.string.isRequired,
};
GeneralSettings.defaultProps = {
  generalSettings: {},
  saving: false,
  organizationId: null,
  history: {},
  nextStatus: null,
};

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(GeneralSettings)
);
