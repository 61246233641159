import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { find, has, size, get, mapValues, isArray, without } from 'lodash';

// ACTIONS
import {
  startEditingTable,
  stopEditingTable,
} from 'providers/globalUI/globalUI.actions';

// COMPONENTS
import {
  Table,
  Input,
  InputNumber,
  Checkbox,
  Select,
  Popconfirm,
  Form,
  Button,
  Tooltip,
  Menu,
  Dropdown,
  Switch,
} from 'te-antd';

import TETreeSelect from 'components/TETreeSelect/TETreeSelect';

// const InputGroup = Input.Group;
const { Option } = Select;

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = (inputType, inputOptions, children, inputValue) => {
    switch (inputType) {
      case 'number':
        return <InputNumber {...inputOptions} />;

      case 'switch':
        return <Switch defaultChecked={inputValue} />;

      case 'checkbox':
        return <Checkbox.Group {...inputOptions} />;

      case 'select': {
        const { options, filterFn, ...restInputOptions } = inputOptions;
        const filteredOptions = (options || [])
          .filter(opt => {
            if (typeof filterFn === 'function') return filterFn(opt);
            return opt;
          })
          .map(({ value, label }) => <Option value={value}>{label}</Option>);
        return <Select {...restInputOptions}>{filteredOptions}</Select>;
      }

      case 'tree-select': {
        const { options, filterFn, ...restInputOptions } = inputOptions;
        const filteredOptions = (options || []).map(opt => {
          return {
            ...opt,
            disabled: typeof filterFn === 'function' ? !filterFn(opt) : false,
          };
        });
        return <TETreeSelect options={filteredOptions} {...restInputOptions} />;
      }

      default:
        return <Input {...inputOptions} />;
    }
  };

  renderChildren = (inputValue, inputType, inputOptions, record) => {
    switch (inputType) {
      case 'checkbox': {
        if (!inputValue) return null;
        const { options = {} } = inputOptions;
        if (!options) return null;

        return inputValue.map(value => {
          const valueObject = find(options, { value });
          if (!valueObject) return null;
          return <div>{valueObject.label}</div>;
        });
      }

      case 'switch': {
        if (record.isNewRow || typeof inputValue === 'undefined') return null;
        return <Switch disabled defaultChecked={inputValue} />;
      }

      case 'select':
      case 'tree-select': {
        if (!inputValue) return null;
        const { options = {} } = inputOptions;
        if (!options) return null;

        const valueObject = find(options, { value: inputValue });
        if (!valueObject) return null;

        return <div>{valueObject.label}</div>;
      }

      default:
        return inputValue;
    }
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      inputOptions,
      inputRules,
      inputGroup,
      inputLabel,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    return (
      <td {...restProps}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {inputGroup ? (
          <div>
            {inputGroup.map((input, key) => {
              const value = get(record, [dataIndex, key]);
              return (
                <div>
                  {editing ? (
                    <Form.Item style={{ margin: 0 }} label={input.inputLabel}>
                      <Tooltip title={input.inputLabel || ''}>
                        {getFieldDecorator(`${dataIndex}[${key}]`, {
                          rules: input.inputRules,
                          initialValue: value,
                        })(
                          this.getInput(
                            input.inputType,
                            input.inputOptions,
                            children,
                            value
                          )
                        )}
                      </Tooltip>
                    </Form.Item>
                  ) : (
                    value && (
                      <span>
                        {input.inputLabel ? (
                          <strong>{`${input.inputLabel}:`}</strong>
                        ) : null}
                        {this.renderChildren(
                          value,
                          input.inputType,
                          input.inputOptions,
                          record
                        )}
                      </span>
                    )
                  )}
                </div>
              );
            })}
          </div>
        ) : editing ? (
          <Form.Item style={{ margin: 0 }} label={inputLabel}>
            {getFieldDecorator(dataIndex, {
              rules: inputRules,
              initialValue: get(record, dataIndex),
            })(
              this.getInput(
                inputType,
                inputOptions,
                null,
                get(record, dataIndex)
              )
            )}
          </Form.Item>
        ) : (
          this.renderChildren(
            get(record, dataIndex),
            inputType,
            inputOptions,
            record
          ) || children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class EditableTable extends React.Component {
  state = { editingIndex: null };

  editableButtons = {};

  constructor(props) {
    super(props);

    const editableActionsColumn = find(props.columns, col =>
      has(col, 'editableButtons')
    );
    if (editableActionsColumn) {
      this.editableButtons = editableActionsColumn.editableButtons;
    }
    this.columns = props.columns.map(col => {
      if (!col.editableButtons) {
        return col;
      }

      return {
        ...col,
        render: (text, record, index) => {
          if (this.isEditing(index)) {
            return (
              <span>
                <EditableContext.Consumer>
                  {form => (
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => this.save(form, index)}
                      style={{ marginRight: 8 }}
                      className="ediable-table__btn--save"
                    >
                      Save
                    </Button>
                  )}
                </EditableContext.Consumer>
                <Button
                  type="link"
                  size="small"
                  onClick={() => this.cancel(record.key)}
                >
                  Cancel
                </Button>
              </span>
            );
          }

          const {
            editButton,
            removeButton,
            otherActions,
            customActions,
          } = this.editableButtons;

          const { editingIndex } = this.state;

          const editButtonElement = editButton ? (
            <a
              disabled={editingIndex !== null}
              onClick={() => this.edit(index)}
              style={{ marginRight: 8 }}
            >
              Edit
            </a>
          ) : null;

          if (record.isNewRow) {
            return (
              <Button
                className="ediable-table__btn--add"
                disabled={editingIndex !== null}
                onClick={() => this.edit(index)}
              >
                Add
              </Button>
            );
          }

          // If has other actions, render dropdown, put edit button into dropdown menu
          if (otherActions && otherActions.length) {
            const dropdownMenu = (
              <Menu>
                <Menu.Item>{editButtonElement}</Menu.Item>
                {otherActions.map(item => (
                  <Menu.Item
                    disabled={
                      typeof item.disabledGetter === 'function'
                        ? item.disabledGetter(record)
                        : false
                    }
                  >
                    <a
                      onClick={item.onClick(record)}
                      type={item.type}
                      disabled={
                        typeof item.disabledGetter === 'function'
                          ? item.disabledGetter(record)
                          : false
                      }
                    >
                      {item.label}
                    </a>
                  </Menu.Item>
                ))}
              </Menu>
            );
            return (
              <Dropdown
                overlayClassName="dropdown--actions"
                trigger="click"
                overlay={dropdownMenu}
              >
                <Button>Actions</Button>
              </Dropdown>
            );
          }

          return (
            <span>
              {customActions
                ? customActions(record, editButtonElement)
                : editButtonElement}
              {removeButton && (
                <Popconfirm
                  title="Sure to remove?"
                  onConfirm={() => this.remove(index)}
                >
                  <a disabled={editingIndex !== null}>Remove</a>
                </Popconfirm>
              )}
              {otherActions}
            </span>
          );
        },
      };
    });
  }

  // eslint-disable-next-line react/destructuring-assignment
  isEditing = index => index === this.state.editingIndex;

  cancel = () => {
    const { stopEditingTable, tableId } = this.props;
    if (tableId) {
      stopEditingTable(tableId);
    }
    this.setState({ editingIndex: null });
  };

  remove(index) {
    const { dataSource } = this.props;
    const item = dataSource[index];
    const { removeButton } = this.editableButtons;

    if (removeButton && removeButton.onClick) {
      removeButton.onClick(item, index);
    }
  }

  save(form, index) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = mapValues(row, value => {
        // remove empty value for group of inputs
        if (isArray(value)) {
          return without(value, null, undefined);
        }
        return value;
      });

      const { dataSource } = this.props;
      const { editingIndex } = this.state;

      const item = {
        ...dataSource[index],
        ...newData,
      };

      if (editingIndex >= size(dataSource)) {
        const { addButton } = this.editableButtons;
        delete item.isNewRow;

        if (addButton && addButton.onClick) {
          addButton.onClick(item);
        }
      } else {
        const { editButton } = this.editableButtons;

        if (editButton && editButton.onClick) {
          editButton.onClick(item, index);
        }
      }

      const { stopEditingTable, tableId } = this.props;
      if (tableId) {
        stopEditingTable(tableId);
      }
      this.setState({ editingIndex: null });
    });
  }

  edit(index) {
    this.setState({ editingIndex: index });
    const { startEditingTable, tableId } = this.props;
    if (tableId) {
      startEditingTable(tableId);
    }
  }

  render() {
    const {
      form,
      dataSource,
      loading,
      footer,
      tableOptions,
      className,
      defaultNewRow,
    } = this.props;
    const { addButton } = this.editableButtons;

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }

      const {
        inputType,
        inputOptions,
        inputRules,
        inputGroup,
        inputLabel,
        ...restCol
      } = col;

      return {
        ...restCol,
        onCell: (record, rowIndex) => ({
          record,
          inputType: inputType || 'text',
          inputOptions: inputOptions || {},
          inputRules: inputRules || [],
          inputGroup,
          inputLabel,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(rowIndex),
        }),
      };
    });

    const { editingIndex } = this.state;

    // todo: should make this table able to be paginated
    return (
      <EditableContext.Provider value={form}>
        <Table
          className={`table--editable ${className}`}
          components={{ body: { cell: EditableCell } }}
          dataSource={
            addButton
              ? dataSource.concat({ isNewRow: true, ...defaultNewRow })
              : dataSource
          }
          columns={columns}
          rowClassName={(record, index) =>
            `editable-row ${
              index === editingIndex ? 'editable-row--editing' : ''
            }`
          }
          loading={loading}
          pagination={false}
          footer={footer}
          {...tableOptions}
        />
      </EditableContext.Provider>
    );
  }
}

EditableTable.propTypes = {
  form: PropTypes.any.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  footer: PropTypes.func,
  tableOptions: PropTypes.object,
  tableId: PropTypes.string,
};

EditableTable.defaultProps = {
  loading: false,
  footer: null,
  tableOptions: {},
  tableId: '',
};

const mapActionToProps = {
  startEditingTable,
  stopEditingTable,
};

export default connect(
  null,
  mapActionToProps
)(Form.create()(EditableTable));
