import * as types from './backup.actionTypes';
import { initialState } from './backup.initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_BACKUP:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_BACKUP_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.data.backups,
      };
    case types.FETCH_BACKUP_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.GENERATE_BACKUP:
      return {
        ...state,
        loadingGenerateBackup: true,
      };
    case types.GENERATE_BACKUP_SUCCESS: {
      state.list.unshift(action.payload.data.backup);
      return {
        ...state,
        loadingGenerateBackup: false,
      };
    }
    case types.GENERATE_BACKUP_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingGenerateBackup: false,
      };
    case types.RESTORE_BACKUP:
      return {
        ...state,
        saving: true,
      };
    case types.RESTORE_BACKUP_SUCCESS: {
      return {
        ...state,
        saving: false,
      };
    }
    case types.RESTORE_BACKUP_FAILURE:
      return {
        ...state,
        error: action.error,
        saving: false,
      };
    default:
      return { ...state };
  }
};
