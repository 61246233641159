import React from 'react';
import PropTypes from 'prop-types';
// import uuidv4 from 'uuid/v4';
import { Table } from 'te-antd';

import CreateNewPermisson from './CreateNewPermission';
import Permission from './Permission';

// eslint-disable-next-line react/prefer-stateless-function
class Permissions extends React.Component {
  columns = [
    {
      title: 'Permission Name',
      dataIndex: 'name',
      width: '400px',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: 'cacl(50% -  125px)',
    },
    {
      title: 'Actions',
      width: '200px',
    },
  ];

  // ---------------------------------------------
  // EVENT HANDLER
  // ---------------------------------------------
  onUpdate = permissions => {
    const { onUpdate } = this.props;
    if (typeof onUpdate === 'function') {
      onUpdate(permissions);
    }
  };

  onPermissionDelete = permission => {
    const { onDelete } = this.props;
    if (typeof onDelete === 'function') {
      onDelete(permission);
    }
  };

  onPermissionCreate = permission => {
    const { onCreate } = this.props;
    if (typeof onCreate === 'function') {
      onCreate(permission);
    }
    // const { permissions } = this.props;
    // permissions.push({ ...permission, id: uuidv4() });
    // this.onUpdate(permissions);
  };

  onPermissionSave = permission => {
    const { onUpdate } = this.props;
    if (typeof onUpdate === 'function') {
      onUpdate(permission);
    }
  };

  // ---------------------------------------------
  // RENDERING
  // ---------------------------------------------
  renderPermissions = () => {
    const { permissions, creating } = this.props;
    const components = {
      body: {
        row: Permission,
      },
    };
    return (
      <div>
        <Table
          onRow={() => ({
            onDelete: this.onPermissionDelete,
            onSave: this.onPermissionSave,
          })}
          components={components}
          columns={this.columns}
          dataSource={permissions}
          footer={() => (
            <CreateNewPermisson
              loading={creating}
              onSubmit={this.onPermissionCreate}
            />
          )}
          pagination={false}
        />
      </div>
    );
  };

  render() {
    return <div>{this.renderPermissions()}</div>;
  }
}

Permissions.propTypes = {
  onUpdate: PropTypes.func,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  permissions: PropTypes.array,
  creating: PropTypes.bool,
};
Permissions.defaultProps = {
  onUpdate: null,
  onCreate: null,
  onDelete: null,
  creating: false,
  permissions: [],
};
export default Permissions;
