import { EXAM_CONFIGURATION_FIELD_MODE } from 'constants/organization.constants';

export const EXAM_CONFIG_FIELD_DROPDOWN_TYPE = {
  SOURCE: 'source',
  MODE: 'mode',
  FIELD: 'field'
}

const SELECT_SOURCE_FOR_TYPE = {
  [EXAM_CONFIGURATION_FIELD_MODE.OBJECT_TYPES]: 'Object Types',
  [EXAM_CONFIGURATION_FIELD_MODE.RESERVATION_MODE]: 'Reservation mode'
}

export const renderDefaultSelectField = Object.entries(SELECT_SOURCE_FOR_TYPE).map(([key, value]) => ({
  value: key,
  label: value
}))