import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Form, Input, Button, Row, Col } from 'te-antd';

class NewUser extends React.Component {
  fields = [
    {
      label: 'First Name',
      name: 'firstName',
      rules: [
        {
          required: true,
          message: 'First Name is required',
        },
      ],
    },
    {
      label: 'Last Name',
      name: 'lastName',
      rules: [
        {
          required: true,
          message: 'Last Name is required',
        },
      ],
    },
    {
      label: 'Email',
      name: 'email',
      rules: [
        {
          type: 'email',
          message: 'Email is invalid',
        },
        {
          required: true,
          message: 'Email is required',
        },
        {
          validator: (rule, value, cb) => {
            const { emails } = this.props;
            if (emails.indexOf(value) > -1) {
              cb('Email exists');
            } else {
              cb();
            }
          },
          message: 'Email exists',
        },
      ],
    },
  ];

  onSubmit = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { onSubmit } = this.props;
        if (typeof onSubmit === 'function') {
          onSubmit(values);
        }
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form onSubmit={this.onSubmit}>
        <Row gutter={16} type="flex">
          {this.fields.map(field => (
            <Col md={6} key={field.name}>
              <Form.Item label={field.label}>
                {getFieldDecorator(field.name, {
                  rules: field.rules,
                })(field.elem ? field.elem : <Input />)}
              </Form.Item>
            </Col>
          ))}
          <Col md={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

NewUser.propTypes = {
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  emails: PropTypes.array,
};
NewUser.defaultProps = {
  onSubmit: null,
  emails: [],
};

export default Form.create({ name: 'newUser' })(NewUser);
