export const TE_EXAM = 'teExam';
export const TE_PREFERENCES = 'tePref';
export const TE_ADMIN = 'teAdmin';
export const TE_HOOTDESK = 'teHootdesk';
export const TE_TEST = 'TE_TEST';
export const TE_PRICING = 'tePricing';
export const TE_REPORTING = 'teReporting';

export const ALL_PRODUCTS = {
  [TE_EXAM]: {
    icon: 'EX',
    key: TE_EXAM,
    title: 'TE Exam',
    logoUrl: 'https://storage.googleapis.com/te-admin-products/te-exam.png',
  },
  [TE_PREFERENCES]: {
    icon: 'Pf',
    key: TE_PREFERENCES,
    title: 'TE Preferences',
  },
  [TE_TEST]: {
    icon: 'Te',
    key: TE_TEST,
    title: 'TE Test',
  },
  [TE_HOOTDESK]: {
    icon: 'Hd',
    key: TE_HOOTDESK,
    title: 'HootDesk',
  },
  [TE_PRICING]: {
    icon: 'PR',
    key: TE_PRICING,
    title: 'TE Pricing',
  },
  [TE_REPORTING]: {
    icon: 'Re',
    key: TE_REPORTING,
    title: 'TE Reporting',
  },
};

export const TE_PREF_OBJECT_TYPES = [
  {
    title: 'Professors',
    id: 'professors',
  },
  {
    title: 'Programs',
    id: 'programs',
  },
  {
    title: 'Courses',
    id: 'courses',
  },
  {
    title: 'Student groups',
    id: 'student_groups',
  },
  {
    title: 'Rooms',
    id: 'rooms',
  },
];

export const TECourseEventDataModel = [
  'staff.department',
  'general.title',
  'course.code',
  'courseevent.ID',
  'course.name',
  'course.department',
  'courseevent.description',
  'general.id',
];
