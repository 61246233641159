import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

// ACTIONS
import { setBreadcrumbs } from 'providers/globalUI/globalUI.actions';

// COMPONENTS
import AnimationLoading from 'components/AnimationLoading/AnimationLoading';

import PageApplicationDetails from './PageApplicationDetails/PageApplicationDetails';
import PageApplicationsListing from './PageApplicationsListing/PageApplicationsListing';

// STYLE
import './PageApplications.scss';

// eslint-disable-next-line react/prefer-stateless-function
class PageApplications extends React.Component {
  render() {
    const { loading, match } = this.props;
    if (loading) return <AnimationLoading />;
    return (
      <Switch>
        <Route
          path={`${match.path}/:applicationId`}
          component={PageApplicationDetails}
        />
        <Route component={PageApplicationsListing} />
      </Switch>
    );
  }
}

const mapActionsToProps = {
  setBreadcrumbs,
};
const mapStateToProps = state => ({
  applications: state.teApps.apps.list,
  loading: state.teApps.apps.loading,
});

PageApplications.propTypes = {
  loading: PropTypes.bool,
  match: PropTypes.object.isRequired,
};
PageApplications.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(PageApplications);
