import moment from 'moment';

const BACKUP_STATUS = {
  restored: 'green',
};

/* eslint lines-between-class-members: "off", prettier/prettier: "off" */
export default class RestoreLog {
  _id;
  apps;
  backupId;
  backup;
  organizationId;
  initiator;
  result;
  previousBackupStatus;
  restoredBy;
  createdAt;
  updatedAt;

  constructor({
    _id,
    apps,
    backupId,
    backup,
    restoredBy,
    organizationId,
    initiator,
    result,
    previousBackupStatus,
    createdAt,
    updatedAt,
  }) {
    this._id = _id;
    this.key = _id;
    this.status = 'RESTORE';
    this.apps = apps;
    this.backupDetail = backup;
    this.backupId = backupId;
    this.organizationId = organizationId;
    this.date = moment(createdAt).format('MMM DD, YYYY h:mm A');
    this.tagColor = BACKUP_STATUS.restored;
    this.initiator = initiator;
    this.result = result;
    this.previousBackupStatus = previousBackupStatus;
    this.restoredBy = restoredBy;
    this.updatedAt = updatedAt;
    this.backupDate = moment(backup.createdAt).format('MMM DD, YYYY h:mm A');
    this.restoreDate = moment(createdAt).format('MMM DD, YYYY h:mm A');
  }
};
