export default class UserRole {
  app;

  isActive;

  organizationGroup;

  permissionGroup;

  constructor({ app, isActive, organizationGroup, permissionGroup }) {
    this.app = app;
    this.isActive = typeof isActive === 'undefined' ? false : isActive;
    this.organizationGroup = organizationGroup;
    this.permissionGroup = permissionGroup;
  }
}
