/* @flow */
import { message } from 'te-antd';

export type RestAPI<P, M, T> = (payload: P, meta?: M) => Promise<T>;

export type FilterMeta = {
  filterKey: string,
  filter?: Object,
};

export const defaultFilterMeta: FilterMeta = {
  filterKey: 'all',
  filter: undefined,
};

export class ApiResult {
  static DEFAULT = '__DEFAULT__';

  static FETCHING = '__FETCHING__';

  static SUCCESS = '__SUCCESS__';

  static FAILURE = '__FAILURE__';

  constructor(status = ApiResult.DEFAULT, data, error) {
    if (typeof status === 'object') {
      return Object.assign(this, status);
    }
    this.status = status;
    this.data = data;
    this.error = error;
    this.timestamp = new Date().valueOf();
  }

  isDefault() {
    return this.status === ApiResult.DEFAULT;
  }

  isFetching() {
    return this.status === ApiResult.FETCHING;
  }

  isSuccess() {
    return this.status === ApiResult.SUCCESS;
  }

  isFailure() {
    return this.status === ApiResult.FAILURE;
  }

  get message() {
    if (this._message) return this._message;
    if (this.data instanceof Error) {
      const errorMessage = this.data.message;
      return typeof errorMessage === 'string'
        ? errorMessage
        : `${errorMessage}`;
    }
    switch (this.status) {
      case ApiResult.SUCCESS:
        return 'Success';
      case ApiResult.FAILURE:
        return 'Failure';
      case ApiResult.FETCHING:
        return 'Fetchching';
      default:
        return 'Unknown';
    }
  }

  static initDefault() {
    return new ApiResult(ApiResult.DEFAULT);
  }

  static initFetching() {
    return new ApiResult(ApiResult.FETCHING);
  }

  static initSuccess(data) {
    return new ApiResult(ApiResult.SUCCESS, data);
  }

  static initFailure(error) {
    return new ApiResult(ApiResult.FAILURE, undefined, error);
  }

  setFetching() {
    return Object.assign(Object.create(ApiResult.prototype), this, {
      status: ApiResult.FETCHING,
    });
  }

  setSuccess(data) {
    return Object.assign(Object.create(ApiResult.prototype), this, {
      data,
      status: ApiResult.SUCCESS,
    });
  }

  setFailure(error) {
    return Object.assign(Object.create(ApiResult.prototype), this, {
      error,
      status: ApiResult.SUCCESS,
    });
  }
}

export type FetchResult<T> = {
  status: ApiResult,
  data: Array<T>,
};

export function alertOnError(error) {
  if (error) {
    message.error(error.message);
  }
}
