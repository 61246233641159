import React, { useState } from 'react';
import { Tabs } from 'te-antd';

// CONSTANTS
import { TE_PREFERENCES } from 'constants/products.constants';

// COMPONENTS
import { Mapping } from 'te-integration-lib';
import { AssistedScheduling } from '../AssistedScheduling';
import WeekNames from '../WeekNames/WeekNames';

const PREFERENCE_SETTINGS = {
  MAPPING: 'MAPPING',
  ASSISTED_SCHEDULING: 'ASSISTED_SCHEDULING',
  WEEK_NAMES: 'WEEK_NAMES',
};

const ProductTEPreferences = () => {
  const [activeTab, setTab] = useState(PREFERENCE_SETTINGS.MAPPING)

  const handleChangeTab = tab => setTab(tab);

  return (
    <div>
      <Tabs
        defaultActiveKey={activeTab}
        onChange={handleChangeTab}
      >
        <Tabs.TabPane tab="Mapping" key={PREFERENCE_SETTINGS.MAPPING}>
          <Mapping appName={TE_PREFERENCES} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Assisted Scheduling" key={PREFERENCE_SETTINGS.ASSISTED_SCHEDULING}>
          <AssistedScheduling />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Week names" key={PREFERENCE_SETTINGS.WEEK_NAMES}>
          <WeekNames />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
};

export default ProductTEPreferences;
