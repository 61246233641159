import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal, Alert, Icon, Typography, Tooltip } from 'te-antd';

// ACTIONS
import {
  deactivateOrganizationToken,
  addOrganizationToken,
} from 'providers/newOrganization/newOrganization.actions';
import CreateTokenModal from './CreateTokenModal';

// CONSTANTS
const mapActionsToProps = {
  deactivateOrganizationToken,
  addOrganizationToken,
};

const mapStateToProps = (state) => ({
  // product: state.teApps.apps.map[ownProps.productKey],
  organizationId: state.newOrganization.organizationId,
});

const ManageProductItem = props => {
  const [tooltipOpen, setVisibleOpen] = useState(false);
  let tokenInput;
  const { product, token } = props;

  const onCreateNewToken = name => {
    const { addOrganizationToken, product, organizationId } = props;
    addOrganizationToken({
      appId: product._id,
      appName: product.name,
      organizationId,
      name: name && name.name,
    });
  };

  const onOpenDeleteTokenModal = () => {
    Modal.confirm({
      className: 'modal--alert modal--without-icon',
      title: 'Are you sure you want to delete this token?',
      maskClosable: true,
      width: 650,
      cancelText: 'Cancel',
      okText: 'Yes, revoke token',
      okButtonProps: {
        type: 'danger',
      },
      content: (
        <Alert
          message="Any applications or scripts using this token will no longer to be able to access the TimeEdit API. You cannot undo this action."
          type="warning"
        />
      ),
      onCancel: () => {
        console.log('onCancel');
      },
      onOk: () => {
        const { token, deactivateOrganizationToken } = props;
        deactivateOrganizationToken(token);
      },
    });
  };

  const onCopyToClipboard = () => {
    if (!tokenInput) return;
    tokenInput.select();
    document.execCommand('copy');
    setVisibleOpen(true);
    setTimeout(() => {
      setVisibleOpen(false);
    }, 500);
  };

  const tokenMessage = token ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography.Text style={{ width: '30rem' }} ellipsis>
        {token.token}
      </Typography.Text>
      &nbsp;&nbsp;&nbsp;
      <Tooltip title="Copied" visible={tooltipOpen}>
        <Button size="small" type="link" onClick={onCopyToClipboard}>
          <Icon type="copy" />
        </Button>
      </Tooltip>
    </div>
  ) : null;

  return (
    <div
      className="manage-product-item--wrapper"
      style={{ justifyContent: 'space-between' }}
    >
      <div style={{ display: 'flex' }}>
        <img
          src={product.urls.imageUrl}
          className="manage-product-item--logo"
          alt={`${product.title} logo`}
          width="40"
          height="40"
        />
        <div className="manage-product-item_product-properties--wrapper">
          <div className="manage-product-item_product-properties--label">
            {product.title}
          </div>
          <div className="manage-product-item_product-properties--status">
            {token
              ? `Token generated ${moment(token.createdAt).format(
                  'YYYY-MM-DD'
                )}`
              : 'No active token'}
          </div>
        </div>
      </div>
      {token ? (
        <React.Fragment>
          <Alert message={tokenMessage} type="success" />
          <input
            style={{ opacity: 0, width: '10px', marginLeft: '-10px' }}
            ref={el => {
              tokenInput = el;
            }}
            value={token.token}
          />
        </React.Fragment>
      ) : null}
      <div
        className="manage-product-item--button"
        style={{ display: 'inline-block', margin: 0 }}
      >
        {!token ? null : (
          <Button
            style={{ marginRight: '8px' }}
            onClick={onOpenDeleteTokenModal}
          >
            Revoke
          </Button>
        )}
        <CreateTokenModal onSubmit={onCreateNewToken} />
      </div>
    </div>
  );
};

ManageProductItem.propTypes = {
  addOrganizationToken: PropTypes.func.isRequired,
  deactivateOrganizationToken: PropTypes.func.isRequired,
  product: PropTypes.object,
  token: PropTypes.object,
  organizationId: PropTypes.string.isRequired,
};
ManageProductItem.defaultProps = {
  product: {},
  token: null,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ManageProductItem);
