/* @flow */
/* eslint jsx-a11y/click-events-have-key-events: "off", jsx-a11y/no-static-element-interactions: "off"  */

import * as React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// CSS
import './Breadcrumbs.scss';

const Breadcrumbs = props => {
  const { fragments, rightChildren, userrole } = props;
  if (!Array.isArray(fragments)) return null;
  return (
    <div className="breadcrumbs">
      <div className="links flex-fill">
        <span>
          <React.Fragment key="Home">
            <Link to={`/${userrole}`}>Home</Link>
          </React.Fragment>
        </span>
        {fragments.map((fragment, fragmentIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={fragment.path + fragmentIndex}>
            <React.Fragment>
              {fragment.path && fragmentIndex < fragments.length - 1 ? (
                <Link to={fragment.path}>{fragment.title}</Link>
              ) : (
                fragment.title
              )}
            </React.Fragment>
          </span>
        ))}
      </div>
      {rightChildren}
    </div>
  );
};

Breadcrumbs.propTypes = {
  fragments: PropTypes.array,
  rightChildren: PropTypes.node,
  userrole: PropTypes.string,
};
Breadcrumbs.defaultProps = {
  fragments: [],
  rightChildren: null,
  userrole: 'user',
};

export default Breadcrumbs;
