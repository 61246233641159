/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { forEach } from 'lodash';

// ACTIONS
import { stopEditingUser } from 'providers/newOrganization/editingUser/editingUser.actions';
import {
  createUser,
  updateUser,
} from 'providers/newOrganization/newOrganization.actions';

// COMPONENTS
import { Modal, Button } from 'te-antd';
import EditableTableButton from 'components/Tables/EditableTable/EditableTableButton';
import UserRoles from './UserRoles';
import UserDetails from './UserDetails';
import SaveRoleButton from './SaveRoleButton';

class EditingUserModals extends React.Component {
  // EVENT HANDLERS
  onModalClose = () => {
    const { stopEditingUser } = this.props;
    stopEditingUser();
  };

  userOrganizationGroupsConverter = userRoles => {
    const organizationGroups = [];
    forEach(userRoles, (roles, appName) => {
      forEach(roles, role => {
        organizationGroups.push({
          app: appName,
          ...role,
          isActive: typeof role.isActive !== 'undefined' ? role.isActive : true,
        });
      });
    });
    return organizationGroups;
  };

  onUserUpdate = user => {
    const { createUser, editingUser, updateUser, type } = this.props;
    const { userRoles } = editingUser;

    const roles = Object.keys(userRoles).filter(
      key => userRoles[key] && userRoles[key].length
    );

    const userDetails = user || editingUser;

    const organizationGroups = this.userOrganizationGroupsConverter(userRoles);
    if (editingUser._id) {
      updateUser({
        user: {
          ...userDetails,
          type,
          organizationGroups,
        },
      });
    } else {
      createUser({
        user: {
          ...userDetails,
          type,
          organizationGroups,
        },
      });
    }
    this.onModalClose();
  };

  // RENDERING
  render() {
    const { editingUser, type } = this.props;
    if (!editingUser) return null;

    const submitRolesButton = (
      <div>
        <Button onClick={this.onModalClose}>Cancel</Button>
        &nbsp;&nbsp;
        <EditableTableButton
          onConfirm={() => this.onUserUpdate()}
          tableId="userRolesTable"
        >
          <SaveRoleButton
            onConfirm={this.onUserUpdate}
            id="addNewOrgUserBtn"
            style={{ marginLeft: '8px' }}
            type="primary"
          >
            Save changes
          </SaveRoleButton>
        </EditableTableButton>
      </div>
    );

    return (
      <div>
        <Modal
          width={750}
          title={editingUser._id ? 'Edit user' : 'Add new user'}
          visible={!editingUser._id || editingUser.isEditing}
          footer={null}
          onCancel={this.onModalClose}
          destroyOnClose
        >
          <UserDetails
            onSubmit={this.onUserUpdate}
            onClose={this.onModalClose}
            userType={type}
          />
        </Modal>

        <Modal
          title="User roles"
          width={750}
          footer={submitRolesButton}
          visible={editingUser._id && !editingUser.isEditing}
          onOk={this.onUserUpdate}
          onCancel={this.onModalClose}
          destroyOnClose
        >
          <UserRoles />
        </Modal>
      </div>
    );
  }
}

EditingUserModals.propTypes = {
  stopEditingUser: PropTypes.func.isRequired,
  editingUser: PropTypes.object,
  createUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  type: PropTypes.string,
};
EditingUserModals.defaultProps = {
  editingUser: null,
  type: 'normal',
};

const mapStateToProps = state => ({
  editingUser: state.editingUser.editingUser,
});

const mapActionsToProps = {
  stopEditingUser,
  createUser,
  updateUser,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(EditingUserModals);
