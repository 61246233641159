import { get, keyBy, find, filter, map } from 'lodash';
import {
  TE_ADMIN,
  ADMINISTRATOR,
  SHARED,
} from 'constants/applications.constants';
import * as types from './teApps.actionTypes';
import { initialState } from './teApps.initialState';


export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TE_APPS_REQUEST:
      return {
        ...state,
        apps: {
          list: [],
          map: {},
          loading: true,
        },
      };

    case types.FETCH_TE_APPS_SUCCESS: {
      const { apps } = action.payload.data;
      return {
        ...state,
        apps: {
          list: apps,
          map: keyBy(apps, 'name'),
          loading: false,
        },
      };
    }

    case types.FETCH_TE_APPS_FAILURE:
      return {
        ...state,
        apps: {
          list: [],
          map: {},
          loading: false,
        },
      };

    case types.START_EDITING_APP: {
      const foundApplication = find(
        state.apps.map,
        item => item._id === action.appId
      );
      return {
        ...state,
        apps: {
          ...state.apps,
          edit: { ...foundApplication },
        },
      };
    }

    case types.FETCH_ROLES_REQUEST:
      return {
        ...state,
        rolesLoading: true,
      };
    case types.FETCH_ROLES_SUCCESS: {
      const { roles, permissions } = action.payload;
      const adminPermission = find(
        permissions[TE_ADMIN],
        item => item.name === ADMINISTRATOR
      );
      const adminRole = find(
        roles[TE_ADMIN],
        item =>
          adminPermission && item.permissions.indexOf(adminPermission._id) > -1
      );

      const sharedUserPermission = find(
        permissions[TE_ADMIN],
        item => item.name === SHARED
      );
      const sharedUserRole = {};
      Object.keys(roles).forEach(appKey => {
        sharedUserRole[appKey] = filter(roles[appKey], item => {
          return (
            sharedUserPermission &&
            item.permissions.indexOf(sharedUserPermission._id) > -1
          );
        });
      });

      return {
        ...state,
        roles,
        permissions,
        rolesLoading: false,
        adminRole: adminRole
          ? {
              app: TE_ADMIN,
              roleId: adminRole._id,
              title: adminRole.title,
              name: adminRole.name,
            }
          : null,
        sharedUserRole,
      };
    }
    case types.FETCH_ROLES_FAILURE:
      return {
        ...state,
        rolesLoading: false,
      };

    case types.CREATE_PERMISSION_REQUEST:
      return {
        ...state,
        permissionCreating: true,
      };
    case types.CREATE_PERMISSION_SUCCESS: {
      const { permission } = action.payload;
      const { permissions } = state;
      if (permissions[permission.app]) {
        permissions[permission.app].push(permission);
      } else {
        permissions[permission.app] = [permission];
      }
      return {
        ...state,
        permissions,
        permissionCreating: false,
      };
    }
    case types.CREATE_PERMISSION_FAILURE:
      return {
        ...state,
        permissionCreating: false,
      };

    case types.UPDATE_PERMISSION_REQUEST:
      return {
        ...state,
      };
    case types.UPDATE_PERMISSION_SUCCESS: {
      const { permission } = action.payload;
      const { permissions } = state;
      permissions[permission.app] = map(permissions[permission.app], item => {
        if (item._id === permission._id) {
          return permission;
        }
        return item;
      });

      return {
        ...state,
        permissions,
      };
    }
    case types.UPDATE_PERMISSION_FAILURE:
      return {
        ...state,
      };

    case types.DELETE_PERMISSION_REQUEST:
      return {
        ...state,
      };
    case types.DELETE_PERMISSION_SUCCESS: {
      const { permission } = action.payload;
      const { permissions } = state;
      permissions[permission.app] = filter(
        permissions[permission.app],
        item => item._id !== permission._id
      );

      return {
        ...state,
        permissions,
      };
    }
    case types.DELETE_PERMISSION_FAILURE:
      return {
        ...state,
      };

    case types.CREATE_ROLE_REQUEST:
      return {
        ...state,
        roleCreating: true,
      };
    case types.CREATE_ROLE_SUCCESS: {
      const { role } = action.payload;
      const { roles } = state;
      if (roles[role.app]) {
        roles[role.app].push(role);
      } else {
        roles[role.app] = [role];
      }
      return {
        ...state,
        roles,
        roleCreating: false,
      };
    }
    case types.CREATE_ROLE_FAILURE:
      return {
        ...state,
        roleCreating: false,
      };

    case types.UPDATE_ROLE_REQUEST:
      return {
        ...state,
        roleSaving: true,
      };
    case types.UPDATE_ROLE_SUCCESS: {
      const { role } = action.payload;
      const { roles } = state;
      roles[role.app] = map(roles[role.app], item => {
        if (item._id === role._id) {
          return role;
        }
        return item;
      });

      return {
        ...state,
        roles,
        roleSaving: false,
      };
    }
    case types.UPDATE_ROLE_FAILURE:
      return {
        ...state,
        roleSaving: false,
      };

    case types.DELETE_ROLE_REQUEST:
      return {
        ...state,
      };
    case types.DELETE_ROLE_SUCCESS: {
      const { role } = action.payload;
      const { roles } = state;
      roles[role.app] = filter(roles[role.app], item => item._id !== role._id);

      return {
        ...state,
        roles,
      };
    }
    case types.DELETE_ROLE_FAILURE:
      return {
        ...state,
      };

    case types.FETCH_CONFIG_ACCESS_TOKEN_APP_SUCCESS:
      return {
        ...state,
        accessTokenApp: {
          isFetched: true,
          apps: get(action, 'payload.data.apps'),
        }
      }

    default:
      return {
        ...state,
      };
  }
};
