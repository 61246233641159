/* eslint-disable camelcase */
import {
  EXAM_CONFIGURATION_FIELD_MODE,
} from 'constants/organization.constants';

export const TRANSFORM_EXAM_CONFIG_FIELD = {
  [`${EXAM_CONFIGURATION_FIELD_MODE.OBJECT_TYPES}__mode`]: ({ extid, name }) => ({ value: extid, label: name }),
  [`${EXAM_CONFIGURATION_FIELD_MODE.RESERVATION_MODE}__mode`]: ({ extid, name }) => ({ value: extid, label: name }),
  [`${EXAM_CONFIGURATION_FIELD_MODE.OBJECT_TYPES}__field`]: field => ({ value: field, label: field }),
  [`${EXAM_CONFIGURATION_FIELD_MODE.RESERVATION_MODE}__field`]: field => ({ value: field, label: field })
}

export const MAP_FETCH_INTEGRATION_DATA =  {
  [`${EXAM_CONFIGURATION_FIELD_MODE.OBJECT_TYPES}__mode`]: ({ organizationId }) => `/integration-service/service/teExam/integration/${organizationId}/object/types`,
  [`${EXAM_CONFIGURATION_FIELD_MODE.RESERVATION_MODE}__mode`]: ({ organizationId }) => `/integration-service/service/teExam/integration/${organizationId}/reservation/situations`,
  [`${EXAM_CONFIGURATION_FIELD_MODE.OBJECT_TYPES}__field`]: ({ organizationId, value }) => `/integration-service/service/teExam/integration/${organizationId}/object/types/${value}/fields`,
  [`${EXAM_CONFIGURATION_FIELD_MODE.RESERVATION_MODE}__field`]: ({ organizationId, value }) => `/integration-service/service/teExam/integration/${organizationId}/reservation/fields/${value}`
}