import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { WithToken } from 'te-auth-app-lib';

import { login } from 'providers/authentication/authentication.actions';

// COMPONENTS
import { Input, FormGroup } from 'reactstrap';
import { Form, Button } from 'te-antd';

import TELogo from 'resources/images/te_icon_gradient@1x.png';
import './LoginPage.scss';

const mapStateToProps = state => ({
  loginStatus: state.authentication.user && state.authentication.user.id,
  signingIn: state.authentication.signingIn,
});

const mapActionToProps = {
  login,
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      errorEmail: '',
      errorPassword: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { loginStatus } = this.props;
    if (nextProps.loginStatus && !loginStatus) {
      nextProps.history.push('/');
    }
  }

  redirectAuthUrl = () => (
    <WithToken.Consumer>
      {/* eslint-disable-next-line consistent-return */}
      {({ authClient }) => {
        const loginUrl = authClient.loginUrl({
          callbackUrl: window.location.origin,
        });
        if (loginUrl) {
          window.location.replace(loginUrl);
          return null;
        }
      }}
    </WithToken.Consumer>
  );

  render() {
    const { errorEmail, errorPassword, email, password } = this.state;
    const { login, signingIn, ssoAuth } = this.props;

    if (ssoAuth) {
      return this.redirectAuthUrl();
    }

    return (
      <div className="Login-container flex-column flex--center-center auth-form">
        <div>
          <FormGroup className="text-center">
            <img
              src={TELogo}
              width="64"
              height="64"
              className="te-logo-img"
              alt="TimeEdit logo"
            />
          </FormGroup>
          <Form
            className="flex-column flex--align-center"
            onSubmit={e => {
              e.preventDefault();
              if (errorEmail || errorPassword) return;
              login({
                userName: email,
                password,
              });
            }}
          >
            <FormGroup>
              <Input
                type="email"
                placeholder="Email"
                className="Login-FormInput"
                value={email}
                onChange={e => this.setState({ email: e.target.value })}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="password"
                placeholder="Password"
                className="Login-FormInput"
                value={password}
                onChange={e => this.setState({ password: e.target.value })}
              />
            </FormGroup>
            <Button
              block
              type="primary"
              className="btn btn-confirm btn-full-width Login-SubmitButton"
              disabled={!!(errorEmail || errorPassword || !email || !password)}
              loading={signingIn}
              htmlType="submit"
            >
              Sign in
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}
Login.propTypes = {
  history: PropTypes.object.isRequired,
  loginStatus: PropTypes.string,
  login: PropTypes.func.isRequired,
  signingIn: PropTypes.bool,
  ssoAuth: PropTypes.bool,
};
Login.defaultProps = {
  loginStatus: null,
  signingIn: false,
  ssoAuth: true,
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(Login);
