import * as types from './templateForms.actionTypes';
import { initialState } from './templateForms.initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TEMPLATE_FORMS_REQUEST:
      return {
        ...state,
        listLoading: true,
      };

    case types.FETCH_TEMPLATE_FORMS_SUCCESS:
      return {
        ...state,
        listLoading: false,
        list: action.payload.data.templateForms,
      };

    case types.FETCH_TEMPLATE_FORMS_FAILURE:
      return {
        ...state,
        listLoading: false,
        list: [],
      };

    case types.GET_TEMPLATE_FORM_DETAILS_REQUEST:
      return {
        ...state,
        single: {},
        singleLoading: true,
      };

    case types.GET_TEMPLATE_FORM_DETAILS_SUCCESS:
      return {
        ...state,
        single: action.payload.data.templateForm,
        singleLoading: false,
      };

    case types.GET_TEMPLATE_FORM_DETAILS_FAILURE:
      return {
        ...state,
        single: {},
        singleLoading: false,
      };

    case types.SAVE_TEMPLATE_FORM_DETAILS_REQUEST:
      return {
        ...state,
        singleSaving: true,
      };

    case types.SAVE_TEMPLATE_FORM_DETAILS_SUCCESS:
      return {
        ...state,
        singleSaving: false,
      };

    case types.SAVE_TEMPLATE_FORM_DETAILS_FAILURE:
      return {
        ...state,
        singleSaving: false,
      };

    default:
      return {
        ...state,
      };
  }
};
