import _ from 'lodash';
import { asyncAction } from 'providers/utils/actionHelpers';
import { api } from 'providers/api';
import { alertOnError } from 'providers/api/api.model';
import {
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_FAILURE,
  SEARCH_ORGANIZATIONS,
  DEACTIVATE_ORGANIZATIONS,
  DEACTIVATE_ORGANIZATIONS_SUCCESS,
  DEACTIVATE_ORGANIZATIONS_FAILURE,
  ACTIVATE_ORGANIZATIONS,
  ACTIVATE_ORGANIZATIONS_SUCCESS,
  ACTIVATE_ORGANIZATIONS_FAILURE,
  FETCH_ORGANIZATION_DETAIL_CONNECTION,
  FETCH_ORGANIZATION_DETAIL_CONNECTION_SUCCESS,
  FETCH_ORGANIZATION_DETAIL_CONNECTION_FAILURE,
  COPY_ORGANIZATION,
  COPY_ORGANIZATION_SUCCESS,
  COPY_ORGANIZATION_FAILURE,
  FLUSH_COPY_ORGANIZATION,
  SELECT_COPY_ORGANIZATION,
  FETCH_APP_SETTING_COPY,
  FETCH_APP_SETTING_COPY_SUCCESS,
  FETCH_APP_SETTING_COPY_FAILURE,
  ADD_REGION_TO_ORGANIZATION,
} from './organizations.actionTypes';

// /////////////////////////////////////////////////////////////
// GET ORGANIZATIONS
// @funcs:
// fetchOrganizations
// /////////////////////////////////////////////////////////////

export const fetchOrganizations = asyncAction(
  {
    type: FETCH_ORGANIZATIONS,
    success: FETCH_ORGANIZATIONS_SUCCESS,
    failure: FETCH_ORGANIZATIONS_FAILURE,
    callback: alertOnError,
  },
  async () => {
    // eslint-disable-next-line no-return-await
    return await api.get('/organizations');
  }
);

export const searchOrganization = ({ searchText, filterKey }) => dispatch => dispatch({ type: SEARCH_ORGANIZATIONS, searchText, filterKey })

export const deactivateOrganization = asyncAction(
  {
    type: DEACTIVATE_ORGANIZATIONS,
    success: DEACTIVATE_ORGANIZATIONS_SUCCESS,
    failure: DEACTIVATE_ORGANIZATIONS_FAILURE,
    callback: alertOnError,
  },
  async ({ organizationId }) => {
    // eslint-disable-next-line no-return-await
    return await api.put(`/organizations/${organizationId}/deactivate`);
  }
);

export const activateOrganization = asyncAction(
  {
    type: ACTIVATE_ORGANIZATIONS,
    success: ACTIVATE_ORGANIZATIONS_SUCCESS,
    failure: ACTIVATE_ORGANIZATIONS_FAILURE,
    callback: alertOnError,
  },
  async ({ organizationId }) => {
    // eslint-disable-next-line no-return-await
    return await api.put(`/organizations/${organizationId}/activate`);
  }
);

export const clearCopyOrganization = () => dispatch => dispatch({ type: FLUSH_COPY_ORGANIZATION });

export const fetchOrganizationDetail = asyncAction(
  {
    type: FETCH_ORGANIZATION_DETAIL_CONNECTION,
    success: FETCH_ORGANIZATION_DETAIL_CONNECTION_SUCCESS,
    failure: FETCH_ORGANIZATION_DETAIL_CONNECTION_FAILURE,
  },
  async ({ organizationId }) => {
    // eslint-disable-next-line no-return-await
    return await api.get(`/integration-service/connection-setting/${organizationId}?decrypt=true`);
  }
);

export const fetchAppSetting = asyncAction(
  {
    type: FETCH_APP_SETTING_COPY,
    success: FETCH_APP_SETTING_COPY_SUCCESS,
    failure: FETCH_APP_SETTING_COPY_FAILURE,
  },
  async ({ organizationId }) => {
    // eslint-disable-next-line no-return-await
    return await api.get(`/organizations/${organizationId}/app-settings`);
  }
);

export const selectCopyOrganization = ({ organizationId }) => (dispatch, getState) => {
  const isExist = _.get(getState(), ['organizations', 'connectionSettingData', organizationId])
  dispatch({ type: SELECT_COPY_ORGANIZATION, payload: { organizationId } })
  if (!isExist) {
    dispatch(fetchOrganizationDetail({ organizationId }))
  }
};

export const copyOrganization = asyncAction(
  {
    type: COPY_ORGANIZATION,
    success: COPY_ORGANIZATION_SUCCESS,
    failure: COPY_ORGANIZATION_FAILURE,
  },
  async ({ organizationId, ...payload }) => {
    // eslint-disable-next-line no-return-await
    return await api.post(`/organizations/${organizationId}/copy`, {...payload});
  }
);

export const addRegionToOrganization = (region) => dispatch => {
  dispatch({type: ADD_REGION_TO_ORGANIZATION, payload: {region}})
}