import React from 'react';

import { ConnectionSettings } from 'te-integration-lib';

// CSS

const IntegrationSettings = () => (
  <div>
    <h1>Integration settings</h1>
    <ConnectionSettings />
  </div>
);

export default IntegrationSettings;
