export const FETCH_BACKUP = 'FETCH_BACKUP';
export const FETCH_BACKUP_SUCCESS = 'FETCH_BACKUP_SUCCESS';
export const FETCH_BACKUP_FAILURE = 'FETCH_BACKUP_FAILURE';

export const FETCH_RESTORED_ITEMS = 'FETCH_RESTORED_ITEMS_REQUEST';
export const FETCH_RESTORED_ITEMS_SUCCESS = 'FETCH_RESTORED_ITEMS_SUCCESS';
export const FETCH_RESTORED_ITEMS_FAILURE = 'FETCH_RESTORED_ITEMS_FAILURE';

export const GENERATE_BACKUP = 'GENERATE_BACKUP';
export const GENERATE_BACKUP_SUCCESS = 'GENERATE_BACKUP_SUCCESS';
export const GENERATE_BACKUP_FAILURE = 'GENERATE_BACKUP_FAILURE';

export const RESTORE_BACKUP = 'RESTORE_BACKUP';
export const RESTORE_BACKUP_SUCCESS = 'RESTORE_BACKUP_SUCCESS';
export const RESTORE_BACKUP_FAILURE = 'RESTORE_BACKUP_FAILURE';
