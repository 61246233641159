/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map } from 'lodash';
import UserRole from 'models/UserRole.model';

// COMPONENTS
// import { Tag } from 'te-antd';
import { Tag, Menu, Popconfirm, Dropdown, Icon, Modal } from 'te-antd';
import { SectionHeader } from 'components/PageLayout/PageLayout';
import NewUser from 'pages/PageUsers/components/NewUser';
import EditableTable from 'components/Tables/EditableTable/EditableTable';

// ACTIONS
import {
  createAdmin,
  updateAdmin,
  resendInvitationCodeAdmin,
  updateAdminStatus,
} from 'providers/users/users.actions';

import UserStatus from '../../components/OrganizationDetails/Components/UserStatus/UserStatus';
import UserInvitationDetails from '../../components/OrganizationDetails/Components/UserInvitationDetails';

const PageUsers = ({
  users,
  loading,
  saving,
  resendInvitationCodeAdmin,
  adminRole,
  createAdmin,
  updateAdmin,
  updateAdminStatus,
}) => {
  const createNewUserForm = useRef();
  const [reinvitationSelected, selectUserInvitation] = useState();

  const onUserAdd = newUser => {
    createAdmin({
      user: {
        ...newUser,
        organizationGroups: [
          new UserRole({
            app: adminRole.app,
            organizationGroup: 'root',
            permissionGroup: 'teAdmin:TE_Admin', // TODO: please update that logic
            isActive: true,
          }),
        ],
      },
      cb: () => {
        if (createNewUserForm) {
          createNewUserForm.current.resetFields();
        }
      },
    });
  };

  const updateUser = user => {
    updateAdmin({
      user: {
        ...user,
        organizationGroups: [
          new UserRole({
            app: adminRole.app,
            organizationGroup: 'root',
            permissionGroup: 'teAdmin:TE_Admin', // TODO: please update that logic
            isActive: true,
          }),
        ],
      },
    });
  };

  const renderTableFooter = () => (
    <NewUser
      onSubmit={onUserAdd}
      ref={createNewUserForm}
      emails={map(users, 'email')}
    />
  );

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      width: '15%',
      editable: true,
      inputType: 'text',
      inputRules: [
        {
          required: true,
          message: 'Please Input First Name!',
        },
      ],
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      width: '15%',
      editable: true,
      inputType: 'text',
      inputRules: [
        {
          required: true,
          message: 'Please Input Last Name!',
        },
      ],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '15%',
      editable: false,
      inputType: 'text',
      inputRules: [
        {
          type: 'email',
          message: 'The input is not valid E-mail!',
        },
        {
          required: true,
          message: 'Please Input Email!',
        },
      ],
    },
    {
      title: 'Role',
      render: () => <Tag>Admin</Tag>,
    },
    {
      title: 'Status',
      render: user => (
        <UserStatus
          user={user}
          onResend={() => resendInvitationCodeAdmin({ userId: user._id })}
        />
      ),
    },
    {
      title: 'Actions',
      editableButtons: {
        editButton: { onClick: updateUser },
        customActions: (user, editButtonElement) => {
          const menuItems = [<Menu.Item>{editButtonElement}</Menu.Item>];
          if (!user.password) {
            menuItems.push(
              <Menu.Item onClick={() => selectUserInvitation(user)}>
                View invitation code
              </Menu.Item>
            );
            menuItems.push(
              <Menu.Item
                onClick={() => resendInvitationCodeAdmin({ userId: user._id })}
              >
                Resend invitation code
              </Menu.Item>
            );
          } else if (user.isActive) {
            const itemDeactive = (
              <div>
                <b>Deactive</b>
                <br />
                <span>
                  Are you sure that you want to deactivate {user.email}?
                </span>
                <br />
                <span>The user will not be able to login to TE apps.</span>
              </div>
            );
            menuItems.push(
              <li className="ant-dropdown-menu-item">
                <Popconfirm
                  overlayStyle={{ zIndex: 2010 }}
                  title={itemDeactive}
                  placement="topLeft"
                  trigger="click"
                  onConfirm={() =>
                    updateAdminStatus({
                      userId: user._id,
                      isActive: !user.isActive,
                    })
                  }
                >
                  Deactivate
                </Popconfirm>
              </li>
            );
          } else {
            menuItems.push(
              <Menu.Item
                onClick={() =>
                  updateAdminStatus({
                    userId: user._id,
                    isActive: !user.isActive,
                  })
                }
              >
                Activate
              </Menu.Item>
            );
          }
          const menu = <Menu>{menuItems}</Menu>;

          return (
            <Dropdown
              placement="bottomRight"
              overlay={menu}
              trigger={['click']}
            >
              <Icon style={{ fontSize: '22px' }} type="ellipsis" />
            </Dropdown>
          );
        },
      },
    },
  ];

  return (
    <div>
      <SectionHeader sectionTitle="Users" />
      <div>
        <EditableTable
          columns={columns}
          dataSource={users}
          footer={renderTableFooter}
          loading={loading || saving}
        />
      </div>
      <Modal
        title="Invitation code"
        width={750}
        visible={!!reinvitationSelected}
        onCancel={() => selectUserInvitation()}
        onOk={() => {
          resendInvitationCodeAdmin({ userId: reinvitationSelected._id });
          selectUserInvitation();
        }}
        okText="Resend intvite"
        cancelText="Close"
        destroyOnClose
      >
        <UserInvitationDetails user={reinvitationSelected} />
      </Modal>
    </div>
  );
};

// class PageUsers1 extends React.Component {
//   createNewUserForm = null;

//   constructor(props) {
//     super(props);

//     this.state = {
//       actionDropdownOpened: null,
//     };
//   }

//   componentWillMount() {
//     this.columns = [
//       {
//         title: 'First Name',
//         dataIndex: 'firstName',
//         width: '15%',
//         editable: true,
//         inputType: 'text',
//         inputRules: [
//           {
//             required: true,
//             message: 'Please Input First Name!',
//           },
//         ],
//       },
//       {
//         title: 'Last Name',
//         dataIndex: 'lastName',
//         width: '15%',
//         editable: true,
//         inputType: 'text',
//         inputRules: [
//           {
//             required: true,
//             message: 'Please Input Last Name!',
//           },
//         ],
//       },
//       {
//         title: 'Email',
//         dataIndex: 'email',
//         width: '15%',
//         editable: false,
//         inputType: 'text',
//         inputRules: [
//           {
//             type: 'email',
//             message: 'The input is not valid E-mail!',
//           },
//           {
//             required: true,
//             message: 'Please Input Email!',
//           },
//         ],
//       },
//       {
//         title: 'Role',
//         render: () => <Tag>Admin</Tag>,
//       },
//       {
//         title: 'Status',
//         render: user =>
//           user.isActive ? (
//             <Tag color="green">Active</Tag>
//           ) : (
//             <Tag color="red">Inactive</Tag>
//           ),
//       },
//       {
//         title: 'Actions',
//         render: user => {
//           const { actionDropdownOpened } = this.state;
//           const { resendInvitationCode } = this.props;
//           const menuItems = [
//             <Menu.Item onClick={this.updateUser}>Edit</Menu.Item>,
//           ];
//           if (!user.password) {
//             menuItems.push(
//               <Menu.Item onClick={() => this.onInvitationModalOpen(user)}>
//                 View invitation code
//               </Menu.Item>
//             );
//             menuItems.push(
//               <Menu.Item
//                 onClick={() => resendInvitationCode({ userId: user._id })}
//               >
//                 Resend invitation code
//               </Menu.Item>
//             );
//           } else if (user.isActive) {
//             const itemDeactive = (
//               <div>
//                 <b>Deactive</b>
//                 <br />
//                 <span>
//                   Are you sure that you want to deactivate {user.email}?
//                 </span>
//                 <br />
//                 <span>The user will not be able to login to TE apps.</span>
//               </div>
//             );
//             menuItems.push(
//               <li className="ant-dropdown-menu-item">
//                 <Popconfirm
//                   overlayStyle={{ zIndex: 2010 }}
//                   title={itemDeactive}
//                   placement="topLeft"
//                   trigger="click"
//                   onConfirm={() => this.onUserStatusToggle(user)}
//                 >
//                   Deactivate
//                 </Popconfirm>
//               </li>
//             );
//           } else {
//             menuItems.push(
//               <Menu.Item onClick={() => this.onUserStatusToggle(user)}>
//                 Activate
//               </Menu.Item>
//             );
//           }
//           const menu = <Menu>{menuItems}</Menu>;
//           return (
//             <Dropdown
//               onVisibleChange={e => this.onVisibleChange(e)}
//               placement="bottomRight"
//               trigger="click"
//               overlay={menu}
//               visible={actionDropdownOpened === user._id}
//             >
//               <Icon
//                 style={{ fontSize: '22px' }}
//                 type="ellipsis"
//                 onClick={() =>
//                   this.setState(() => ({ actionDropdownOpened: user._id }))
//                 }
//               />
//             </Dropdown>
//           );
//         },
//       },
//     ];
//   }

//   onUserStatusToggle = user => () => {
//     this.updateUser({ ...user, isActive: !user.isActive });
//   };

//   onUserAdd = newUser => {
//     const { adminRole, createAdmin } = this.props;
//     createAdmin({
//       user: {
//         ...newUser,
//         organizationGroups: [
//           new UserRole({
//             app: adminRole.app,
//             organizationGroup: 'root',
//             permissionGroup: adminRole.title,
//             isActive: true,
//           }),
//         ],
//       },
//       cb: () => {
//         if (this.createNewUserForm) {
//           this.createNewUserForm.resetFields();
//         }
//       },
//     });
//   };

//   updateUser = user => {
//     const { updateAdmin, adminRole } = this.props;
//     updateAdmin({
//       user: {
//         ...user,
//         organizationGroups: [
//           new UserRole({
//             app: adminRole.app,
//             organizationGroup: 'root',
//             permissionGroup: adminRole.title,
//             isActive: true,
//           }),
//         ],
//       },
//     });
//   };

//   renderTableFooter = () => {
//     const { users } = this.props;
//     return (
//       <NewUser
//         onSubmit={this.onUserAdd}
//         ref={el => {
//           this.createNewUserForm = el;
//         }}
//         checkEmailExisted={this.checkEmailExisted}
//         emails={map(users, 'email')}
//       />
//     );
//   };

//   render() {
//     const { users, loading, saving } = this.props;
//     return (
//       <div>
//         <SectionHeader sectionTitle="Users" />
//         <div>
//           <EditableTable
//             columns={this.columns}
//             dataSource={users}
//             footer={this.renderTableFooter}
//             loading={loading || saving}
//           />
//         </div>
//       </div>
//     );
//   }
// }

const mapActionsToProps = {
  createAdmin,
  updateAdmin,
  resendInvitationCodeAdmin,
  updateAdminStatus,
};

const mapStateToProps = state => ({
  users: state.users.admins,
  loading: state.users.loading,
  saving: state.users.saving,
  adminRole: state.teApps.adminRole,
});

PageUsers.propTypes = {
  createAdmin: PropTypes.func.isRequired,
  updateAdmin: PropTypes.func.isRequired,
  users: PropTypes.array,
  loading: PropTypes.bool,
  saving: PropTypes.bool,
  adminRole: PropTypes.object,
  resendInvitationCodeAdmin: PropTypes.func.isRequired,
  updateAdminStatus: PropTypes.func.isRequired,
};
PageUsers.defaultProps = {
  users: [],
  loading: false,
  saving: false,
  adminRole: null,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(PageUsers);
