import React, { useState } from 'react';
import { Tabs } from 'te-antd';
import BackupList from './BackupList';
import RestoreLogs from './RestoreLogs';
import BackupModal from './BackupModal';

const { TabPane } = Tabs;

const Backup = () => {
  const [open, setOpen] = useState(false);
  const [backupSelected, setBackup] = useState();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="backup-page">
      <div className="d-flex align-items-center justify-content-between">
        <h1>Backup</h1>
      </div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="All Backups" key="1">
          <BackupList handleOpen={handleOpen} setBackup={setBackup} />
        </TabPane>
        <TabPane tab="Restored backups" key="2">
          <RestoreLogs />
        </TabPane>
      </Tabs>,
      <BackupModal
        open={open}
        handleClose={handleClose}
        backupDetail={backupSelected}
      />
    </div>
  );
};

export default Backup;
