import React from 'react';
import Dots from './Dots/Dots';
import Spinner from './Spinner/Spinner';

const AnimationLoading = props => {
  const { type } = props;
  switch (type) {
    case 'spinner':
      return <Spinner />;
    case 'dots':
      return <Dots />;
    default:
      return <Spinner />;
  }
};

export default AnimationLoading;
