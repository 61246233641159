import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createCatchMiddleware from './middlewares/catch';

import rootReducer from './rootReducer';

const catchMiddleware = createCatchMiddleware((error, getState, lastAction) => {
  /* eslint-disable */
  console.error('THE ERROR: ', error);
  console.debug('current state: ', getState());
  console.debug('last action was: ', lastAction);
  /* eslint-enable */
});

const configureStore = () => {
  const enhancers = [];
  const middleware = [thunk, catchMiddleware];

  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
  const store = createStore(rootReducer, composedEnhancers);

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./rootReducer', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }
  return store;
};

export default configureStore;
