import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// ACTIONS
import {
  initNewOrganization,
  startEditingOrganization,
  getOrganizationDetails,
  // fetchIntegrationSettings,
  fetchTEXObjectTypeMapping,
  fetchUsers,
  // fetchProductsSettings,
  fetchOrganizationProducts,
  fetchSharedUsers,
  fetchOrganizationTokens,
  fetchRestoreLogs,
  // fetchTERoomDataModel,
} from 'providers/newOrganization/newOrganization.actions';

import { fetchOrganizationGroups } from 'providers/organizationGroups/organizationGroups.actions';
import { fetchBackup } from 'providers/backup/backup.actions';
import { setBreadcrumbs } from 'providers/globalUI/globalUI.actions';

// COMPONENTS
import OrganizationDetails from 'components/OrganizationDetails/OrganizationDetails';

const mapStateToProps = (state) => {
  return {
    status: state.newOrganization.status || {},
    region: state.organizations.region,
  };
};

const mapActionsToProps = {
  setBreadcrumbs,
  initNewOrganization,
  startEditingOrganization,
  getOrganizationDetails,
  // fetchIntegrationSettings,
  fetchTEXObjectTypeMapping,
  fetchUsers,
  // fetchProductsSettings,
  fetchOrganizationProducts,
  fetchSharedUsers,
  fetchOrganizationGroups,
  fetchBackup,
  fetchOrganizationTokens,
  fetchRestoreLogs,
  // fetchTERoomDataModel,
};
class NewOrganizationPage extends React.Component {
  // //////////////////////////////////////////////////////////////////
  // LIFECYCLE
  // //////////////////////////////////////////////////////////////////
  componentWillMount() {
    const { region, setBreadcrumbs } = this.props;
    const tokenRegion = region || 'Undefined region';
    setBreadcrumbs([
      {
        path: './',
        label: `Organizations ${tokenRegion}`,
      },
      {
        path: '/organizations/new',
        label: 'Create new organization',
      },
    ]);
  }

  componentDidMount() {
    this.initOrganization();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      this.initOrganization();
    }
  }

  initOrganization = async () => {
    const { match, region } = this.props;
    const { params } = match;
    if (params) {
      const { organizationId } = params;
      if (organizationId) {
        const {
          getOrganizationDetails,
          // fetchIntegrationSettings,
          fetchUsers,
          // fetchProductsSettings,
          fetchOrganizationProducts,
          fetchSharedUsers,
          fetchOrganizationGroups,
          setBreadcrumbs,
          fetchBackup,
          fetchRestoreLogs,
          fetchOrganizationTokens,
        } = this.props;
        // fetchIntegrationSettings({ organizationId });
        fetchUsers({ organizationId });
        // fetchProductsSettings({ organizationId });
        fetchOrganizationProducts({ organizationId });
        fetchSharedUsers({ organizationId });
        fetchOrganizationGroups({ organizationId });
        fetchOrganizationTokens({ organizationId });
        fetchBackup();
        fetchRestoreLogs({ organizationId });
        getOrganizationDetails({
          organizationId,
          callback: (orgDetails) => {
            if (orgDetails) {
              setBreadcrumbs([
                {
                  path: './',
                  label: `Organizations ${region}`,
                },
                {
                  path: `/organizations/${organizationId}`,
                  label: orgDetails.name,
                },
              ]);
            }
          },
        });
        return;
      }
    }
    const { initNewOrganization } = this.props;
    initNewOrganization();
    this.forceUpdate();
  };

  // //////////////////////////////////////////////////////////////////
  // RENDERING
  // //////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="new-organization-page-wrapper no-padding d-flex flex-column">
        <OrganizationDetails />
      </div>
    );
  }
}
NewOrganizationPage.propTypes = {
  setBreadcrumbs: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  initNewOrganization: PropTypes.func.isRequired,
  getOrganizationDetails: PropTypes.func.isRequired,
  // fetchIntegrationSettings: PropTypes.func.isRequired,
  // fetchProductsSettings: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  fetchSharedUsers: PropTypes.func.isRequired,
  fetchBackup: PropTypes.func.isRequired,
  fetchOrganizationTokens: PropTypes.func.isRequired,
  fetchRestoreLogs: PropTypes.func.isRequired,
  region: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapActionsToProps)(NewOrganizationPage);
