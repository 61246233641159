export const FETCH_TEMPLATE_FORMS_REQUEST = 'FETCH_TEMPLATE_FORMS_REQUEST';
export const FETCH_TEMPLATE_FORMS_SUCCESS = 'FETCH_TEMPLATE_FORMS_SUCCESS';
export const FETCH_TEMPLATE_FORMS_FAILURE = 'FETCH_TEMPLATE_FORMS_FAILURE';

export const GET_TEMPLATE_FORM_DETAILS_REQUEST =
  'GET_TEMPLATE_FORM_DETAILS_REQUEST';
export const GET_TEMPLATE_FORM_DETAILS_SUCCESS =
  'GET_TEMPLATE_FORM_DETAILS_SUCCESS';
export const GET_TEMPLATE_FORM_DETAILS_FAILURE =
  'GET_TEMPLATE_FORM_DETAILS_FAILURE';

export const SAVE_TEMPLATE_FORM_DETAILS_REQUEST =
  'SAVE_TEMPLATE_FORM_DETAILS_REQUEST';
export const SAVE_TEMPLATE_FORM_DETAILS_SUCCESS =
  'SAVE_TEMPLATE_FORM_DETAILS_SUCCESS';
export const SAVE_TEMPLATE_FORM_DETAILS_FAILURE =
  'SAVE_TEMPLATE_FORM_DETAILS_FAILURE';
