import * as types from './teCourseEventObjectFields.actionTypes';

export default (state = [], action) => {
  switch (action.type) {
    case types.FETCH_TE_COURSE_EVENT_OBJECT_FIELDS_SUCCESS:
      return [...action.payload.data];

    default:
      return state;
  }
};
