import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Icon,
  Dropdown,
  Menu,
  Popconfirm,
} from 'te-antd';

import {
  deactivateOrganization,
  activateOrganization,
  selectCopyOrganization,
} from '../../providers/organizations/organizations.actions'

const OrganizationActions = ({ 
  organization,
  deactivateOrganizationAction,
  activateOrganizationAction,
  selectCopyOrganizationAction,
}) => {
  const overlay = useMemo(
    () => {
      const menuItems = [
        <Menu.Item key="EDIT">
          <Link to={`/organizations/${organization._id}`}>
            View &amp; Edit
          </Link>
        </Menu.Item>,
        <Menu.Item
          key="COPY_ORGANIZATION"
          onClick={() => { selectCopyOrganizationAction({ organizationId: organization._id })}}
        >
          Copy Organization
        </Menu.Item>,
      ];
      
      if (!organization.archived) {
        const itemDeactivate = (
          <div>
            <b>Deactivate</b>
            <br />
            <span>
              Are you sure that you want to deactivate
              {' '}
              {organization.name}
              ?
            </span>
            <br />
            <span>The organization will not be able to login to TE apps.</span>
          </div>
        );
        // use li tag of html to prevent menu close after click on
        menuItems.push(
          <li className="ant-dropdown-menu-item" key="DEACTIVATE">
            <Popconfirm
              overlayStyle={{ zIndex: 2010 }}
              title={itemDeactivate}
              placement="topLeft"
              trigger="click"
              onConfirm={() => deactivateOrganizationAction({ organizationId: organization._id})}
            >
              Deactivate
            </Popconfirm>
          </li>,
        )
      } else {
        menuItems.push(
          <Menu.Item
            key="ACTIVATE_ORGANIZATION"
            onClick={() => activateOrganizationAction({ organizationId: organization._id })}
          >
            Activate
          </Menu.Item>,
        )
      }

      return <Menu>{menuItems}</Menu>;
    },
    [organization],
  );
  return (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      overlay={overlay}
    >
      <Icon
        style={{ fontSize: '22px' }}
        type="ellipsis"
      />
    </Dropdown>
  );
}

OrganizationActions.propTypes = {
  organization: PropTypes.objectOf(PropTypes.any).isRequired,
  deactivateOrganizationAction: PropTypes.func.isRequired,
  activateOrganizationAction: PropTypes.func.isRequired,
  selectCopyOrganizationAction: PropTypes.func.isRequired
}

const mapActionsToProps = {
  deactivateOrganizationAction: deactivateOrganization,
  activateOrganizationAction: activateOrganization,
  selectCopyOrganizationAction: selectCopyOrganization,
}

export default connect(null, mapActionsToProps)(OrganizationActions);
