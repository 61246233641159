import React from 'react';
import './Spinner.scss';

const Spinner = () => (
  <div className="spinner">
    <div className="rolling">
      <div />
    </div>
  </div>
);
export default Spinner;
