import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ACCESS_TOKENS } from 'constants/organization.constants';

// COMPONENTS
import ManageProductToken from 'components/ManageProductToken/ManageProductToken';

// CONSTANTS
const mapStateToProps = state => ({
  products: state.teApps.apps.list || [],
  tokens: state.newOrganization[ACCESS_TOKENS].tokens,
  accessTokenApp: state.teApps.accessTokenApp.apps
});

const ManageProducts = ({ products, accessTokenApp, tokens }) => (
  <React.Fragment>
    <h1>Access Tokens</h1>
    <p>Tokens you generated that can be used to access the TimeEdit API</p>
    {/* TODO: Filter products by name, should use another field from schema */}
    {[...products, ...accessTokenApp]
      .filter(item => item.name !== 'teAdmin')
      .map(product => (
        <ManageProductToken
          product={product}
          token={tokens[product.name]}
        />
      ))}
  </React.Fragment>
);

ManageProducts.propTypes = {
  products: PropTypes.array,
  accessTokenApp: PropTypes.array,
  tokens: PropTypes.object,
};
ManageProducts.defaultProps = {
  products: [],
  accessTokenApp: [],
  tokens: {},
};

export default connect(
  mapStateToProps,
  null
)(ManageProducts);
